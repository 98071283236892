import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../../common/breakpoints';
import { lightGrey7, white } from '../../../../common/colors';
import {
  desktopLinkHeight,
  desktopLinkWidth,
  gap,
  mobileLinkHeight,
  mobileLinkWidth,
  spacingMd,
  spacingSm,
  spacingXs,
  tabletLinkWidth,
} from '../../../../common/popup.styles';

const vendorImageHeight = 48;

export const StyledTVPTheaterContainer = styled.div`
  padding-top: ${rem(10)};
  display: flex;
  align-items: center;
`;

export const StyledTVPImageContainer = styled.div`
  display: inline-block;
  flex-shrink: 0;
  vertical-align: top;
  width: ${rem(40)};
  height: ${rem(40)};
  box-sizing: border-box;
  margin-right: ${rem(4)};
  padding: ${rem(5)};
  border-radius: ${rem(40)};
  background-color: ${white};
  border: 1px solid ${lightGrey7};

  ${mediaQueryWidth({ min: 'mobile' })`
    width: ${rem(52)};
    height: ${rem(52)};
    margin-right: ${rem(8)};
  `}
`;

export const StyledTVPTheaterName = styled.div`
  display: inline-block;
  font-size: ${rem(14)};
  line-height: ${rem(23)};

  ${mediaQueryWidth({ min: 'mobile' })`
    font-size: ${rem(17)};
    line-height: ${rem(30)};
  `}

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(17)};
    line-height: ${rem(30)};
  `}

  ${mediaQueryWidth({ min: 'desktop' })`
    font-size: ${rem(20)};
  `}
`;

export const StyledTVPVendorLinksContainer = styled.div`
  padding: ${spacingMd}px;
  overflow-y: auto;
  box-sizing: border-box;

  ${mediaQueryWidth({ min: 'mobile', max: 'tablet' })`
    padding: ${spacingSm}px ${spacingMd}px 0 ${spacingXs}px;
  `}

  ${mediaQueryWidth({ min: 'tablet' })`
    padding: ${spacingMd}px ${spacingMd}px 0 ${spacingXs}px;
  `}
`;

export const StyledTVPDate = styled.div`
  font-size: ${rem(17)};
  font-weight: bold;
  line-height: ${rem(22)};

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(17)};
    line-height: ${rem(24)};
  `}

  ${mediaQueryWidth({ min: 'desktop' })`
    font-size: ${rem(20)};
  `}
`;

export const StyledTVPFormat = styled.div`
  margin-top: ${rem(10)};
  font-size: ${rem(14)};
  font-weight: bold;
`;

export const StyledTVPSelectText = styled.div`
  margin: ${rem(8)} 0 0 0;
`;

export const StyledTVPVendorLinkContainer = styled.div`
  margin: ${rem(8)} 0 0 0;
`;

export const StyledTVPVendorLinkInnerContainer = styled.div`
  line-height: 0;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fill, ${mobileLinkWidth}px);
    grid-gap: ${gap}px;

    ${mediaQueryWidth({ min: 'mobile', max: 'tablet' })`
      grid-template-columns: repeat(auto-fill, ${mobileLinkWidth}px);
    `}

    ${mediaQueryWidth({ min: 'tablet', max: 'desktop' })`
      grid-template-columns: repeat(auto-fill, ${tabletLinkWidth}px);
    `}

    ${mediaQueryWidth({ min: 'desktop' })`
      grid-template-columns: repeat(auto-fill, ${desktopLinkWidth}px);
    `}
  }
`;

export const StyledTVPVendorImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  min-height: ${vendorImageHeight}px;
  max-height: ${mobileLinkHeight}px;

  ${mediaQueryWidth({ min: 'mobile' })`
    max-height: ${desktopLinkHeight}px;
  `}
`;

export const StyledTVPVendorFallback = styled.span`
  width: 100%;
  padding: 0 ${rem(20)};
  box-sizing: border-box;
  font-size: ${rem(12)};
  font-weight: 800;
  line-height: ${rem(16)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
