import * as React from 'react';
import { MouseEvent } from 'react';
import { useTranslations } from '../../../common/translation';
import { CheckboxListDropdown } from '../../checkbox-list-dropdown/CheckboxListDropdown';
import { CheckboxListDropdownItemInterface } from '../../checkbox-list-dropdown/CheckboxListDropdown.props';
import { useTheme, useTicketingReducer } from '../contexts';
import { FilterChangeAction } from '../hooks/utils';
import translations from '../translations';
import { StyledTFContainer, StyledTFHeader, StyledTFWrapper } from './TicketingFilters.styles';

const onFilterChange = (e: MouseEvent, getter: any, setter: any, action?: FilterChangeAction): void => {
  e.preventDefault();
  e.stopPropagation();
  const newItems = [...getter];

  if (action === 'select-all') {
    newItems.forEach((item: CheckboxListDropdownItemInterface) => {
      item.checked = true;
    });
  } else if (action === 'deselect-all') {
    newItems.forEach((item: CheckboxListDropdownItemInterface) => {
      item.checked = false;
    });
  } else {
    const key = e.currentTarget.attributes['data-key']?.value;
    if (key) {
      newItems.forEach((item: CheckboxListDropdownItemInterface) => {
        if (item.label === key) {
          item.checked = !item.checked;
        }
      });
    }
  }

  setter(newItems);
};

export const TicketingFilters = () => {
  const { t } = useTranslations(translations);
  const {
    theme: { filters: theme },
  } = useTheme();

  const { state, dispatch } = useTicketingReducer();
  const { formatItems, timeItems } = state;

  const timeOfDayLabelId = 'tod-checkbox-label';
  const formatLabelId = 'format-checkbox-label';

  const setTimeItems = (items: CheckboxListDropdownItemInterface[]) => {
    dispatch({ type: 'setTimeItems', payload: items });
  };

  const setFormatItems = (items: CheckboxListDropdownItemInterface[]) => {
    dispatch({ type: 'setFormatItems', payload: items });
  };

  const onTimeChange = (e: MouseEvent, action?: FilterChangeAction): void => {
    onFilterChange(e, timeItems, setTimeItems, action);
  };

  const onFormatChange = (e: MouseEvent, action?: FilterChangeAction): void => {
    onFilterChange(e, formatItems, setFormatItems, action);
  };

  return (
    <StyledTFWrapper>
      <StyledTFContainer>
        <StyledTFHeader id={timeOfDayLabelId}>{t('filtersTimeHeaderText', 'Time Of Day')}</StyledTFHeader>
        <CheckboxListDropdown
          theme={theme}
          ariaLabelId={timeOfDayLabelId}
          enabled={timeItems.length > 0}
          items={timeItems}
          onChange={onTimeChange}
          placeholder={t('filtersTimePlaceholderText', 'Any Time')}
        />
      </StyledTFContainer>
      <StyledTFContainer>
        <StyledTFHeader id={formatLabelId}>{t('filtersFormatHeaderText', 'Format')}</StyledTFHeader>
        <CheckboxListDropdown
          theme={theme}
          ariaLabelId={formatLabelId}
          enabled={formatItems.length > 0}
          items={formatItems}
          onChange={onFormatChange}
          placeholder={t('filtersFormatPlaceholderText', 'Any Format')}
        />
      </StyledTFContainer>
    </StyledTFWrapper>
  );
};
