import * as React from 'react';
import { forwardRef, RefObject, useState } from 'react';
import { StyledPopupDialog } from '../../../../common/popup.styles';
import { useAnalytics, useTheme, useTicketingReducer } from '../../contexts';
import { ShowTime } from '../../hooks/useShowTimes';
import { getTheaterResultTheme } from '../../TicketingWidget.theme';
import { TheaterLocation } from '../theater-location/TheaterLocation';
import { TheaterShowtimes } from '../theater-showtimes/TheaterShowtimes';
import { TheaterVendorsPopup } from '../theater-vendors-popup/TheaterVendorsPopup';
import { TheaterResultProps } from './TheaterResult.props';
import { StyledTheaterResult } from './TheaterResult.styles';

export const TheaterResult = forwardRef(
  ({ hasFocus, onFocus, movie, shows, theater }: TheaterResultProps, ref: RefObject<HTMLDivElement>) => {
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [popupShowTime, setPopupShowTime] = useState<ShowTime | undefined>(undefined);

    const analytics = useAnalytics();
    const {
      state: { selectedDate },
    } = useTicketingReducer();
    const { theme: ticketingTheme } = useTheme();

    const theme = getTheaterResultTheme(ticketingTheme, hasFocus);
    const titleAriaLabel = 'ticketing-show-time-popup-title';

    const handleClose = () => {
      setPopupVisible(false);
    };

    const onSelectShowTime = (showTime: ShowTime) => {
      setPopupShowTime(showTime);
      setPopupVisible(true);
      analytics?.sendShowTime(showTime.id);
    };

    const showTimesProps = { hasFocus, onSelectShowTime, shows, theater };
    const popupProps = {
      movie,
      theater,
      date: selectedDate,
      onClose: handleClose,
      showTime: popupShowTime,
    };

    return (
      <>
        <StyledTheaterResult onClick={onFocus} ref={ref} theme={theme}>
          <TheaterLocation hasFocus={hasFocus} theater={theater} />
          <TheaterShowtimes {...showTimesProps} />
        </StyledTheaterResult>
        <StyledPopupDialog aria-labelledby={titleAriaLabel} onClose={handleClose} open={popupVisible}>
          <TheaterVendorsPopup {...popupProps} />
        </StyledPopupDialog>
      </>
    );
  },
);
