import GoogleMapReact from 'google-map-react';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useTranslations } from '../../../../common/translation';
import { GOOGLEAPI_MAPS_KEY } from '../../../../config/browser';
import { useAnalytics, useTheme, useTicketingReducer } from '../../contexts';
import { ShowsByTheater } from '../../hooks/useShowTimes';
import { Coordinate } from '../../hooks/utils';
import translations from '../../translations';
import { TheaterMapMarker } from './theater-map-marker/TheaterMapMarker';
import { TheaterMapProps } from './TheaterMap.props';
import { StyledMap, StyledReloadButton } from './TheaterMap.styles';

const adjustMap = (maps: any, map: any, theaters: ShowsByTheater[]) => {
  const sampleCount = 5;
  const bounds = new maps.LatLngBounds();

  for (let index = 0; index < sampleCount; index++) {
    const show = theaters[index];
    if (!show) {
      break;
    }

    const position = show.theater.coords;
    if (position) {
      bounds.extend(new maps.LatLng(position.lat, position.lng));
    }
  }

  maps.event.addDomListenerOnce(map, 'idle', () => {
    map.fitBounds(bounds);
  });
};

export const TheaterMap = ({
  focusedIndex,
  mapCenterCoords,
  maxHeight,
  onMapMarkerClick,
  theaters,
}: TheaterMapProps) => {
  const [isReloadButtonVisible, setIsReloadButtonVisible] = useState<boolean>(false);
  const {
    theme: { map: mapTheme, mapControls: mapControlsTheme },
  } = useTheme();
  const analytics = useAnalytics();
  const { t } = useTranslations(translations);
  const currentMapCenter = useRef(mapCenterCoords);
  const { dispatch } = useTicketingReducer();
  const mapRef = useRef<any>();
  const mapsRef = useRef<any>();

  const setMapPosition = (newValue: Coordinate) => {
    dispatch({ type: 'setMapPosition', payload: newValue });
  };

  const onReloadClick = () => {
    if (currentMapCenter.current) {
      setMapPosition(currentMapCenter.current);
      setIsReloadButtonVisible(false);
    }
  };

  const onDragEnd = (map: any) => {
    currentMapCenter.current = { lat: map.center.lat(), lng: map.center.lng() };
    setIsReloadButtonVisible(true);
  };

  const onGoogleApiLoaded = ({ map, maps }: any) => {
    adjustMap(maps, map, theaters);
    mapRef.current = map;
    mapsRef.current = maps;
  };

  const markers = theaters.map((shows: ShowsByTheater, index) => {
    const position = shows.theater.coords;
    const hasFocus = focusedIndex === index;
    const theme = hasFocus ? mapControlsTheme.markerFocused : mapControlsTheme.marker;

    const onClick = () => {
      analytics?.sendMapMarkerClick(shows.theater.id);
      onMapMarkerClick(index);
    };

    const markerProps = { hasFocus, onClick, theme, theater: shows.theater, ...position };
    return <TheaterMapMarker key={shows.theater.id} {...markerProps} />;
  });

  return (
    <StyledMap maxHeight={maxHeight}>
      {isReloadButtonVisible && (
        <StyledReloadButton onClick={onReloadClick} theme={mapControlsTheme.reloadButton}>
          {t('reloadText', 'Reload')}
        </StyledReloadButton>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLEAPI_MAPS_KEY }}
        center={mapCenterCoords}
        defaultZoom={12}
        onDragEnd={onDragEnd}
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={{ fullscreenControl: false, maxZoom: 11, styles: mapTheme }}
        shouldUnregisterMapOnUnmount={true}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {markers}
      </GoogleMapReact>
    </StyledMap>
  );
};
