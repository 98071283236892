import { getCountryOrMarketCode } from '../../../common/helpers/countrySelection';
import { useGet } from '../../../common/hooks/useGet';
import { TICKETING_SERVICE_BASEURL, TICKETING_USE_FIXTURES } from '../../../config/browser';
import { TicketingMovie } from '../TicketingWidget.props';
import { Coordinate } from './utils';

// tslint:disable-next-line:no-var-requires
const fixtureData = require('../../../../tests/fixtures/ticketing/getShowTimes.json').data;

const BASE_URL = `${TICKETING_SERVICE_BASEURL}/graphql?query=`;

export interface Theater {
  id: string;
  name: string;
  image: string;
  address: string;
  city: string;
  state: string;
  coords: Coordinate;
  distance: {
    value: string;
    unit: string;
  };
  timeZone: string;
  utcOffset: string;
}

export interface ShowTime {
  id: string;
  nextDay: boolean;
  screenType: string;
  time: string;
  timeRange: string;
  vendors: ShowTimeVendor[];
}

export interface ShowTimeVendor {
  title: string;
  image: string;
  url: string;
}

export interface ShowsByTheater {
  theater: Theater;
  shows: ShowTime[];
}

export interface ShowsByDay {
  date: string;
  showsByTheater: ShowsByTheater[];
}

export interface ShowTimesResults {
  timeRanges: string[];
  screenTypes: string[];
  showsByDay: ShowsByDay[];
}

export interface UseShowTimesResponse {
  data?: ShowTimesResults;
  error?: Error;
  loading: boolean;
}

const emptyResult: ShowTimesResults = {
  timeRanges: [],
  screenTypes: [],
  showsByDay: [],
};

const buildGraphQL = (movieId: string, coords: Coordinate): string => {
  const countryCode = getCountryOrMarketCode();
  return `{query:getShowTimes(id:"${movieId}", countryCode: "${countryCode}", lat:"${coords.lat}", lng:"${coords.lng}") { timeRanges, screenTypes, showsByDay { date, showsByTheater { theater { id, name, image, address, city, state, utcOffset, distance { value, unit }, coords { lat, lng } }, shows { id, nextDay, screenType, time, timeRange, vendors { title, image, url } } } } } }`;
};

export const useShowTimes = (
  movie: TicketingMovie,
  coords?: Coordinate,
  useFixture: boolean = TICKETING_USE_FIXTURES,
): UseShowTimesResponse => {
  const { sycamoreId } = movie;
  const url = coords && !useFixture ? encodeURI(`${BASE_URL}${buildGraphQL(sycamoreId, coords)}`) : '';
  const { data, error, loading } = useGet(url, { query: emptyResult }, false, false, 1);

  if (useFixture) {
    return { data: fixtureData, loading: false };
  }

  if (!coords) {
    return { data: undefined, loading: false };
  }

  return { error, loading, data: data.data?.query };
};
