import { Button as MaterialUiButton } from '@material-ui/core';
import { rem } from 'polished';
import styled from 'styled-components';
import { black, blue, hexToRgb, white } from '../../../common/colors';

const BUTTON_OUTER_HEIGHT = 46;
const BUTTON_TOP_MARGIN = 15;
const DROPDOWN_TOP_MARGIN = 6;

export const StyledDropdownWrapper = styled.div``;

export const StyledDropdownButton = styled(MaterialUiButton)`
  position: relative;
  z-index: 2;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-shadow: none;
  &.MuiButton-text {
    padding: 0;
  }
  &.MuiButton-root {
    background-color: ${blue};
    border: 2px solid ${blue};
    border-radius: 0;
    text-transform: none;
    &:hover {
      background-color: ${hexToRgb(blue, 0.85)};
    }
  }
  .MuiButton-label {
    box-sizing: border-box;
    color: ${white};
    font-size: ${rem(16)};
    font-weight: 500;
    height: ${rem(42)};
    line-height: ${rem(20)};
    padding: ${rem(12)} ${rem(24)};
  }
` as typeof MaterialUiButton;

export const StyledButtonText = styled.span`
  padding-right: ${rem(8)};
`;

export const StyledDropdownIcon = styled.div`
  margin-right: ${rem(12)};
  color: ${white};
  width: ${rem(16)};
  line-height: 0;
  color: ${white};
`;

export const StyledDropdownArrow = styled.div<{ isOpen?: boolean }>`
  margin-right: 0;
  margin-left: ${rem(12)};
  width: ${rem(14)};
  transform-origin: center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  color: ${white};
`;

export const StyledDropdownContents = styled.div<{ visible: boolean }>`
  position: absolute;
  top: ${rem(BUTTON_OUTER_HEIGHT + BUTTON_TOP_MARGIN + DROPDOWN_TOP_MARGIN)};
  z-index: 3;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  box-shadow: 0 ${rem(4)} ${rem(8)} 0 ${hexToRgb(black, 0.4)};
  background-color: ${white};
  color: ${black};
  height: auto;
  width: auto;
  box-sizing: border-box;
`;

export const StyledLink = styled.a`
  display: flex;
  color: ${black};
  height: ${rem(BUTTON_OUTER_HEIGHT)};
  width: 100%;
  padding: ${rem(8)} ${rem(24)};
  align-items: center;
  font-weight: 500;
  box-sizing: border-box;
  text-decoration: none;

  &:hover {
    background-color: ${hexToRgb(blue, 0.05)};
  }
`;

export const StyledText = styled.div`
  text-shadow: none;
`;

export const StyledImg = styled.img`
  display: inline-flex;
  height: ${rem(18)};
  margin-right: ${rem(12)};
`;
