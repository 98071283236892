import { rem } from 'polished';
import styled from 'styled-components';
import YTPlayRed from '../../../assets/images/icons/svg/yt-play-red.svg';
import YTPlay from '../../../assets/images/icons/svg/yt-play.svg';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { white } from '../../../common/colors';

export const StyledVideo = styled.section`
  ${mediaQueryWidth({ min: 'tablet' })`
    margin-right: 6px;
  `}
`;

export const StyledVideoPlayer = styled.div`
  width: 100%;
  height: 270px;

  .react-player__preview {
    ${mediaQueryWidth({ max: 'tablet' })`
      background-size: inherit !important;
      background-repeat: no-repeat !important;
    `}

    :hover .react-player__play-icon {
      background-image: url('data:image/svg+xml;charset=utf8,${encodeURIComponent(YTPlayRed)}');
    }
  }

  .react-player__shadow {
    height: 40px !important;
    width: 56px !important;

    .react-player__play-icon {
      width: 100%;
      height: 100%;
      margin-left: 0 !important;
      border: none !important;
      background-image: url('data:image/svg+xml;charset=utf8,${encodeURIComponent(YTPlay)}');
      background-repeat: no-repeat;
    }
  }
`;

export const StyledVideoTitle = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: ${rem(20)};
  font-size: ${rem(16)};
  font-weight: normal;
  line-height: 1.625em;
  text-align: left;
  text-transform: capitalize;
  white-space: pre-wrap;
  color: ${white};
`;
