import * as React from 'react';
import { useRouteData } from 'react-static';
import { TitleLayoutType } from '../../common/d7/common';
import DefaultTitleLayout from './subcategories/DefaultTitleLayout';
import MovieTitleLayout from './subcategories/MovieTitleLayout';
import VideoGameTitleLayout from './subcategories/VideoGameTitleLayout';
import { TitleLayoutProps } from './TitleLayout.props';

// noinspection JSUnusedGlobalSymbols
export default () => {
  const props = useRouteData<TitleLayoutProps>();
  const { layoutType } = props;

  switch (layoutType) {
    case TitleLayoutType.VIDEOGAME:
      return <VideoGameTitleLayout {...props} />;
    case TitleLayoutType.MOVIE:
      return <MovieTitleLayout {...props} />;
    default:
      return <DefaultTitleLayout {...props} />;
  }
};
