import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { darkGrey1, white } from '../../common/colors';
import { safeBGImageUrl } from '../../common/helpers/strings';
import { lazyLoadClassName } from '../../common/loading';

export const StyledBannerImg = styled.div<{ url?: string }>`
  width: 100%;
  background-image: ${({ url }) => safeBGImageUrl(url)};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50% 0 0;
  position: relative;

  ${mediaQueryWidth({ min: 'tablet' })`
    height: 30rem;
    width: 100%;
    max-width: none;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
 `};

  &.${lazyLoadClassName} {
    background-image: none;
  }

  &.collections-br {
    height: 0;
    padding-top: 25%;
  }

  button {
    border: none;
    position: absolute;
    background-color: transparent;
    left: 50%;
    top: 50%;
    width: 68px;
    height: 48px;
    margin-left: -34px;
    margin-top: -24px;
    -moz-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    z-index: 63;
    outline: 0;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: 1px dotted ${white};
      outline: 1px auto -webkit-focus-ring-color;
    }

    &:hover svg > path:first-child {
      fill: #ff0000;
      fill-opacity: 1;
    }
  }
`;

export const StyledFeaturedBanner = styled.div`
  position: relative;
  z-index: 4;
  color: ${white};
  background-color: ${darkGrey1};
`;

export const StyledFeaturedBannerBackground = styled.div`
  line-height: 0;
  text-align: center;
  position: relative;
`;

export const StyledVideoContainer = styled.div`
  & > div {
    height: 20rem;

    ${mediaQueryWidth({ min: 'tablet' })`
      height: 30rem;
    `}
  }
`;
