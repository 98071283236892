import { rem } from 'polished';
import styled from 'styled-components';
import { grey10, grey90 } from './colors';

export const lazyLoadClassName = 'lazy';
export const imageInViewOptions = { rootMargin: '256px 0px', threshold: 0, triggerOnce: true };
export const loadingInViewOptions = { rootMargin: '512px 0px', threshold: 0 };

export const StyledHiddenTrigger = styled.div`
  width: 0;
  height: 0;
  visibility: hidden;
`;

export const StyledLoader = styled.div<{ isVisible?: boolean }>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  width: ${rem(48)};
  height: ${rem(48)};
  box-sizing: border-box;
  margin: ${rem(48)} auto ${rem(16)};
  border: ${rem(8)} solid ${grey10};
  border-top: ${rem(8)} solid ${grey90};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
