import * as React from 'react';
import { createRef, RefObject, useEffect, useRef, useState } from 'react';
import LocationIcon from '../../../assets/images/icons/svg/location.svg';
import SearchIcon from '../../../assets/images/icons/svg/search.svg';
import { StyledDropdownContainer } from '../../../common/dropdown.styles';
import { isDownKey, isEnterKey, isRemoveFocusKey, isTabKey, isUpKey } from '../../../common/helpers/keyboard';
import { usePopup } from '../../../common/hooks/usePopup';
import { useTranslations } from '../../../common/translation';
import { useAnalytics, useTicketingReducer } from '../contexts';
import { useCitySearch } from '../hooks/useCitySearch';
import { City } from '../hooks/utils';
import translations from '../translations';
import {
  StyledLFBox,
  StyledLFCurrentLocation,
  StyledLFHeader,
  StyledLFInput,
  StyledLFLoading,
  StyledLFSearch,
  StyledLFWrapper,
} from './LocationForm.styles';
import { LocationFormAutocomplete } from './LocationFormAutocomplete';

export const LocationForm = () => {
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const inputRef: React.RefObject<HTMLDivElement> = useRef(null);
  const popupRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [buttonRefs, setButtonRefs] = useState<Array<RefObject<HTMLButtonElement>>>([]);
  const [searchFor, setSearchFor] = useState<string>('');

  const { t } = useTranslations(translations);
  const { onPopupToggle, onPopupOpen, onPopupClose, popupTop, popupLeft, popupWidth, popupVisible } = usePopup(
    containerRef,
    inputRef,
  );
  const { data, loading } = useCitySearch(searchFor);
  const {
    state: { allowGeolocation, geoPosition, locationName, utilizeGeoPosition },
    dispatch,
  } = useTicketingReducer();
  const analytics = useAnalytics();

  const cities = data || [];
  useEffect(() => {
    setButtonRefs([...Array(cities.length + 1)].map((_, i) => buttonRefs[i] || createRef()));
  }, [cities.length]);

  const usingGeoPosition: boolean = !!(allowGeolocation && utilizeGeoPosition && geoPosition);
  const locationInputLabelId = 'location-input-label';

  const setLocationName = (newValue: string) => {
    dispatch({ type: 'setLocationName', payload: newValue });
  };

  const setUtilizeGeoPosition = (newValue: boolean) => {
    dispatch({ type: 'setUtilizeGeoPosition', payload: newValue });
  };

  const onLocationNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setUtilizeGeoPosition(false);
    setLocationName(inputValue);
    setSearchFor(
      inputValue
        .replace(/,/g, ' ')
        .replace(/\s+/g, ' ')
        .trim(),
    );
  };

  const onLocationFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
    onPopupOpen();
  };

  const onSelectCity = (city: City) => {
    dispatch({ type: 'selectCity', payload: city });
    analytics?.sendLocationAutoComplete(city);
    onPopupClose();
  };

  const onUseCurrentLocation = () => {
    dispatch({ type: 'selectCurrentLocation' });
    onPopupClose();
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (isUpKey(e)) {
      e.preventDefault();
      buttonRefs[cities.length].current!.focus({ preventScroll: true });
    } else if (isDownKey(e)) {
      e.preventDefault();
      buttonRefs[0].current!.focus({ preventScroll: true });
    } else if (isTabKey(e)) {
      onPopupClose();
    }
  };

  const handleAutocompleteKeyDown = (index: number) => (e: React.KeyboardEvent) => {
    if (isEnterKey(e)) {
      index > 0 ? onSelectCity(cities[index + 1]) : onUseCurrentLocation();
    } else if (isUpKey(e)) {
      e.preventDefault();
      const updatedIndex = index > 0 ? index - 1 : cities.length;
      buttonRefs[updatedIndex].current!.focus({ preventScroll: true });
    } else if (isDownKey(e)) {
      e.preventDefault();
      const updatedIndex = index < cities.length ? index + 1 : 0;
      buttonRefs[updatedIndex].current!.focus({ preventScroll: true });
    } else if (isRemoveFocusKey(e)) {
      onPopupClose();
    }
  };

  const handleAutocompleteClick = (index: number) => () => {
    index > 0 ? onSelectCity(cities[index - 1]) : onUseCurrentLocation();
  };

  return (
    <StyledLFWrapper ref={containerRef}>
      <div>
        <StyledLFHeader id={locationInputLabelId}>{t('locationHeaderText', 'Enter Location')}</StyledLFHeader>
        <StyledLFBox ref={inputRef}>
          <StyledLFSearch onClick={onPopupToggle}>
            <SearchIcon />
          </StyledLFSearch>
          <StyledLFInput
            aria-labelledby={locationInputLabelId}
            onChange={onLocationNameChange}
            onFocus={onLocationFocus}
            onKeyDown={onKeyDown}
            tabIndex={0}
            value={locationName}
            placeholder={t('locationPlaceholderText', 'City, State / ZIP Code')}
          />
          <StyledLFCurrentLocation onClick={onUseCurrentLocation} usingGeoPosition={usingGeoPosition}>
            <LocationIcon />
          </StyledLFCurrentLocation>
        </StyledLFBox>
      </div>
      <StyledDropdownContainer
        ref={popupRef}
        top={popupTop}
        left={popupLeft}
        width={popupWidth}
        visible={loading || popupVisible}
      >
        {loading ? (
          <StyledLFLoading>{t('loadingText', 'Loading...')}</StyledLFLoading>
        ) : (
          <LocationFormAutocomplete
            buttonRefs={buttonRefs}
            cities={cities}
            handleClick={handleAutocompleteClick}
            handleKeyDown={handleAutocompleteKeyDown}
          />
        )}
      </StyledDropdownContainer>
    </StyledLFWrapper>
  );
};
