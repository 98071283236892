import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import MoonIcon from '../../../../assets/images/icons/svg/moon.svg';
import { useDateFormatTranslations } from '../../../../common/helpers/date';
import { useWindowResize } from '../../../../common/hooks/useWindowResize';
import { useTranslations } from '../../../../common/translation';
import { useAnalytics, useTheme, useTicketingReducer } from '../../contexts';
import { ShowTime } from '../../hooks/useShowTimes';
import { momentDateForShowTime } from '../../hooks/utils';
import { moonWrapperClassName } from '../../ticketing.styles';
import { getTheaterResultTheme } from '../../TicketingWidget.theme';
import translations from '../../translations';
import { TheaterShowtimesProps } from './TheaterShowtimes.props';
import {
  StyledTheaterShowtimes,
  StyledTSFormat,
  StyledTSMoonWrapper,
  StyledTSShowTime,
  StyledTSShowTimeContainer,
  StyledTSTimeOfDay,
} from './TheaterShowtimes.styles';

export const TheaterShowtimes = ({ hasFocus, onSelectShowTime, shows, theater }: TheaterShowtimesProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [initialShowCount, setInitialShowCount] = useState<number>(0);
  const [allShowsVisible, setAllShowsVisible] = useState<boolean>(false);
  const {
    state: { selectedDate },
  } = useTicketingReducer();
  const { t } = useTranslations(translations);
  const { t: dateFormatTranslation } = useDateFormatTranslations();
  const { theme: ticketingTheme } = useTheme();
  const { isMobile, windowWidth } = useWindowResize();
  const analytics = useAnalytics();

  useEffect(() => {
    if (ref.current) {
      const showTimesWidth = ref.current.clientWidth;
      const estShowTimeWidth = isMobile ? 110 : 140;
      const estToggleWidth = isMobile ? 60 : 80;
      setInitialShowCount(Math.floor((showTimesWidth - estToggleWidth) / estShowTimeWidth));
    }
  }, [windowWidth]);

  const theme = getTheaterResultTheme(ticketingTheme, hasFocus);
  const initialShows = shows.slice(0, initialShowCount);
  const showsToRender = allShowsVisible ? shows : initialShows;
  const displayToggleButton = !allShowsVisible && initialShows.length < shows.length;

  // Reset when losing focus
  useEffect(() => {
    if (!hasFocus) {
      setAllShowsVisible(false);
    }
  }, [hasFocus]);

  const buildShowTime = (showtime: ShowTime, tIndex: number) => {
    if (showtime.vendors.length < 1) {
      return null;
    }

    const handleSelectShowtime = (showTime: ShowTime) => () => {
      onSelectShowTime(showTime, theater);
    };

    const onDirectLinkClick = () => {
      const dateTime = momentDateForShowTime(selectedDate, showtime);

      analytics?.sendShowTime(showtime.id);
      analytics?.sendVendor(showtime.vendors[0].url, theater.name, dateTime.format('YYYY-MM-DD HH:mm'));
    };

    const formatShowTime = (date: string, showTime: ShowTime) =>
      momentDateForShowTime(date, showTime).format(dateFormatTranslation('timeOfDayFormat', 'hh:mm A'));

    const showtimeBehaviorProps: any =
      showtime.vendors.length > 1
        ? {
            onClick: handleSelectShowtime(showtime),
          }
        : {
            as: 'a',
            href: showtime.vendors[0].url,
            onClick: onDirectLinkClick,
            target: '_blank',
          };

    return (
      <StyledTSTimeOfDay
        key={`${theater.id}-${tIndex}`}
        theme={theme.showtimeButton}
        moonTheme={theme.showtimeButtonIcon}
        {...showtimeBehaviorProps}
      >
        <StyledTSShowTimeContainer>
          <StyledTSShowTime>{formatShowTime(selectedDate, showtime)}</StyledTSShowTime>
          <StyledTSFormat>{showtime.screenType}</StyledTSFormat>
        </StyledTSShowTimeContainer>
        {showtime.nextDay && (
          <StyledTSMoonWrapper className={moonWrapperClassName} theme={theme.showtimeButtonIcon}>
            <MoonIcon />
          </StyledTSMoonWrapper>
        )}
      </StyledTSTimeOfDay>
    );
  };

  const toggleAllShows = () => {
    setAllShowsVisible(true);
  };

  const toggleButton = (
    <StyledTSTimeOfDay moonTheme={theme.showtimeButtonIcon} onClick={toggleAllShows} theme={theme.showtimeButton}>
      <StyledTSShowTimeContainer>
        <StyledTSShowTime>+{shows.length - initialShows.length}</StyledTSShowTime>
        <StyledTSFormat>{t('moreText', 'More')}</StyledTSFormat>
      </StyledTSShowTimeContainer>
    </StyledTSTimeOfDay>
  );

  return (
    <StyledTheaterShowtimes ref={ref}>
      {showsToRender.map((showtime: ShowTime, tIndex: number) => buildShowTime(showtime, tIndex))}
      {displayToggleButton && toggleButton}
    </StyledTheaterShowtimes>
  );
};
