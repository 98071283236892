import * as React from 'react';
import { createRef, RefObject, useEffect, useRef, useState } from 'react';
import DownArrowBoldIcon from '../../assets/images/icons/svg/down-arrow-bold.svg';
import { StyledDropdownContainer } from '../../common/dropdown.styles';
import { isDownKey, isEscapeKey, isTabBackwardKey, isTabForwardKey, isUpKey } from '../../common/helpers/keyboard';
import { useDebug } from '../../common/hooks/useDebug';
import { usePopup } from '../../common/hooks/usePopup';
import { CheckboxListDropdownItem } from './checkbox-list-dropdown-item/CheckboxListDropdownItem';
import { CheckboxListDropdownItemInterface, CheckboxListDropdownProps } from './CheckboxListDropdown.props';
import { StyledCLDArrow, StyledCLDButton, StyledCLDInput, StyledDivider } from './CheckboxListDropdown.styles';

const selectedItemsText = (items: CheckboxListDropdownItemInterface[]) => {
  return items
    .filter((item) => item.checked)
    .map((item) => item.label)
    .join(', ');
};

export const CheckboxListDropdown = (props: CheckboxListDropdownProps) => {
  useDebug('app:cmp:CheckboxListDropdown', { props });

  const { ariaLabelId, enabled, items, onChange, placeholder } = props;
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const buttonRef: React.RefObject<HTMLButtonElement> = useRef(null);
  const popupRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [itemRefs, setItemRefs] = useState<Array<RefObject<HTMLElement>>>([]);

  const [anyChecked, setAnyChecked] = useState<boolean>(true);
  const { onPopupClose, onPopupToggle, popupTop, popupLeft, popupWidth, popupVisible } = usePopup(
    containerRef,
    buttonRef,
  );
  const selectedText = selectedItemsText(items);

  // If no filters are selected, then mark the 'any' checkbox as selected
  useEffect(() => {
    const someFiltersSelected = items.some((item: CheckboxListDropdownItemInterface) => item.checked);
    if (!someFiltersSelected) {
      setAnyChecked(true);
    }

    setItemRefs([...Array(items.length + 1)].map((_, i) => itemRefs[i] || createRef()));
  }, [items]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (isEscapeKey(e)) {
        onPopupClose();
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const onAnyChange = (e: any) => {
    setAnyChecked(!anyChecked);
    const action = anyChecked ? 'select-all' : 'deselect-all';
    onChange(e, action);
  };

  const onFilterChange = (e: any) => {
    setAnyChecked(false);
    onChange(e);
  };

  const onContainerClick = enabled ? () => onPopupToggle() : undefined;

  const onContainerKeyDown = enabled
    ? (e: React.KeyboardEvent) => {
        if (isEscapeKey(e) || isTabBackwardKey(e)) {
          onPopupClose();
        } else if (isUpKey(e)) {
          e.preventDefault();
          itemRefs[items.length].current!.focus({ preventScroll: true });
        } else if (isDownKey(e)) {
          e.preventDefault();
          itemRefs[0].current!.focus({ preventScroll: true });
        }
      }
    : undefined;

  const onInputClick = () => {
    buttonRef.current!.focus();
  };

  const handleItemKeyDown = (index: number) => (e: React.KeyboardEvent) => {
    if (isEscapeKey(e)) {
      onPopupClose();
      buttonRef.current!.focus();
    } else if (isUpKey(e) || isTabBackwardKey(e)) {
      e.preventDefault();
      const updatedIndex = index > 0 ? index - 1 : items.length;
      itemRefs[updatedIndex].current!.focus({ preventScroll: true });
    } else if (isDownKey(e) || isTabForwardKey(e)) {
      e.preventDefault();
      const updatedIndex = index < items.length ? index + 1 : 0;
      itemRefs[updatedIndex].current!.focus({ preventScroll: true });
    }
  };

  return (
    <div ref={containerRef}>
      <StyledCLDButton onClick={onContainerClick} onKeyDown={onContainerKeyDown} ref={buttonRef} tabIndex={0}>
        <StyledCLDInput
          aria-labelledby={ariaLabelId}
          onClick={onInputClick}
          readOnly={true}
          placeholder={placeholder}
          tabIndex={-1}
          value={selectedText}
        />
        <StyledCLDArrow>
          <DownArrowBoldIcon />
        </StyledCLDArrow>
      </StyledCLDButton>
      <StyledDropdownContainer ref={popupRef} top={popupTop} left={popupLeft} width={popupWidth} visible={popupVisible}>
        <CheckboxListDropdownItem
          checked={anyChecked}
          enabled={anyChecked}
          italicize={true}
          label={placeholder}
          onChange={onAnyChange}
          onKeyDown={handleItemKeyDown(0)}
          ref={itemRefs[0]}
        />
        {items.length && <StyledDivider />}
        {items.map(({ checked, label }: CheckboxListDropdownItemInterface, index) => (
          <CheckboxListDropdownItem
            checked={checked}
            enabled={!anyChecked}
            key={label}
            label={label}
            onChange={onFilterChange}
            onKeyDown={handleItemKeyDown(index + 1)}
            ref={itemRefs[index + 1]}
          />
        ))}
      </StyledDropdownContainer>
    </div>
  );
};
