import * as React from 'react';
import { blue } from '../../../common/colors';
import { useTranslations } from '../../../common/translation';
import { cityName } from '../hooks/utils';
import translations from '../translations';
import { LocationFormAutocompleteProps } from './LocationFormAutocomplete.props';
import { StyledLFAPopupRow } from './LocationFormAutocomplete.styles';

export const LocationFormAutocomplete = ({
  buttonRefs,
  cities,
  handleClick,
  handleKeyDown,
}: LocationFormAutocompleteProps) => {
  const { t } = useTranslations(translations);

  return (
    <>
      <StyledLFAPopupRow
        color={blue}
        key='location'
        onClick={handleClick(0)}
        onKeyDown={handleKeyDown(0)}
        ref={buttonRefs[0]}
        tabIndex={-1}
      >
        <strong>{t('selectCurrentLocationText', 'Select Current Location')}</strong>
      </StyledLFAPopupRow>
      {cities.map((city, index) => {
        const fullCityName = cityName(city);
        return (
          <StyledLFAPopupRow
            key={fullCityName}
            onClick={handleClick(index + 1)}
            onKeyDown={handleKeyDown(index + 1)}
            ref={buttonRefs[index + 1]}
            tabIndex={-1}
          >
            {fullCityName}
          </StyledLFAPopupRow>
        );
      })}
    </>
  );
};
