import * as React from 'react';
import { createRef, Fragment, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import MapButtonPin from '../../../assets/images/icons/svg/map-button-pin.svg';
import MapButtonTab from '../../../assets/images/icons/svg/map-button-tab.svg';
import { momentDate } from '../../../common/helpers/date';
import { isEnterKey, isSpacebarKey } from '../../../common/helpers/keyboard';
import { useDebug } from '../../../common/hooks/useDebug';
import { useWindowResize } from '../../../common/hooks/useWindowResize';
import { useTranslations } from '../../../common/translation';
import { totalNavigationPxHeight } from '../../navigation/Navigation.styles';
import { subnavHeightPx } from '../../subnav/Subnav.styles';
import { getCurrentPositionCoords, useAnalytics, useTheme, useTicketingReducer } from '../contexts';
import { ShowsByDay, ShowsByTheater } from '../hooks/useShowTimes';
import translations from '../translations';
import { TheaterMap } from './theater-map/TheaterMap';
import { TheaterMapProps } from './theater-map/TheaterMap.props';
import { TheaterResult } from './theater-result/TheaterResult';
import { TheaterResultsProps } from './TheaterResults.props';
import {
  StyledTheaterMapButton,
  StyledTheaterMapButtonText,
  StyledTheaterMapButtonTextContainer,
  StyledTheaterMapWrapper,
  StyledTheaterResults,
  StyledTheaterResultsWrapper,
  StyledTRButton,
  StyledTRInner,
  StyledTRMessage,
} from './TheaterResults.styles';

declare var ResizeObserver: any;

// Prevent re-render loop due to dependency array
const emptyShowsByTheater: ShowsByTheater[] = [];

export const TheaterResults = ({ movie }: TheaterResultsProps) => {
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [resultRefs, setResultRefs] = useState<Array<RefObject<HTMLDivElement>>>([]);
  const [isMapOpen, setIsMapOpen] = useState<boolean>(false);
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
  const [mapButtonWidth, setMapButtonWidth] = useState<number>(0);
  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const innerResultsRef = useRef<HTMLDivElement>(null);
  const mapButtonTextRef = useRef<HTMLSpanElement>(null);

  const {
    theme: { results: theme },
  } = useTheme();
  const { state } = useTicketingReducer();
  const { filteredShows, selectedDate } = state;
  const analytics = useAnalytics();
  const { t } = useTranslations(translations);
  const { isDesktop, windowWidth } = useWindowResize();

  useDebug('app:cmp:TheaterResults', { props: { movie }, other: { selectedDate } });

  const defaultShowCount = 3;
  const showsForDate = (filteredShows || []).find(
    (shows: ShowsByDay) => momentDate(shows.date).format() === selectedDate,
  );
  const showsByTheater = showsForDate?.showsByTheater || emptyShowsByTheater;

  const onFocus = (index: number, theaterId: string) => () => {
    setFocusedIndex(index);
    analytics?.sendTheaterSelection(theaterId);
  };

  const onClickMoreLess = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAll((previousValue) => !previousValue);
  };

  const onKeyDownMoreLess = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (!isEnterKey(e) && !isSpacebarKey(e)) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const updatedShowAll = !showAll;
    setShowAll(updatedShowAll);

    if (updatedShowAll) {
      setTimeout(() => {
        const firstExpandedResult = resultRefs[defaultShowCount]?.current;
        if (firstExpandedResult) {
          const firstTabbable = Array.from(firstExpandedResult.querySelectorAll('a, button, [tabindex="0"]'))[0];
          (firstTabbable as HTMLElement).focus();
        }
      }, 0);
    } else {
      e.currentTarget.focus();
    }
  };

  const onInteractMoreLess = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    adjustMaxHeight();

    e.type === 'keydown'
      ? onKeyDownMoreLess(e as React.KeyboardEvent<HTMLButtonElement>)
      : onClickMoreLess(e as React.MouseEvent<HTMLButtonElement>);
  };

  const adjustMaxHeight = () => {
    if (showAll) {
      return;
    }

    const innerResultsContainer = innerResultsRef.current;
    if (innerResultsContainer && innerResultsContainer.clientHeight !== maxHeight) {
      setMaxHeight(innerResultsContainer.clientHeight);
    }
  };

  const onMapMarkerClick = (index: number) => {
    adjustMaxHeight();
    setShowAll(true);
    setFocusedIndex(index);

    setTimeout(() => {
      const resultsContainer = resultsContainerRef.current;
      if (resultsContainer) {
        window.scrollTo(0, resultsContainer.offsetTop - totalNavigationPxHeight - subnavHeightPx);
        resultsContainer.scrollTo(0, resultRefs[index].current!.offsetTop);
      }
    }, 0);
  };

  const onMapButtonClick = () => {
    if (!showAll) {
      setMaxHeight(0);
    }
    setIsMapLoaded(true);
    setIsMapOpen((prevState) => !prevState);
  };

  useEffect(() => {
    adjustMaxHeight();
  }, [windowWidth]);

  useEffect(() => {
    setMaxHeight(0);
    setShowAll(false);
    setFocusedIndex(0);
  }, [selectedDate]);

  useEffect(() => {
    if (!mapButtonTextRef.current) {
      return;
    }

    // IE11 fallback behavior in absence of polyfill
    if (typeof ResizeObserver === 'undefined') {
      setMapButtonWidth(mapButtonTextRef.current?.offsetWidth || 0);
      return;
    }

    const observer = new ResizeObserver(() => {
      setMapButtonWidth(mapButtonTextRef.current?.offsetWidth || 0);
    });

    observer.observe(mapButtonTextRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const buildTheaterResults = (sbt: ShowsByTheater[]) => {
    const refs: Array<RefObject<HTMLDivElement>> = [...Array(sbt.length)].map((_, i) => resultRefs[i] || createRef());
    setResultRefs(refs);

    return sbt.map((result, index) => (
      <Fragment key={selectedDate + result.theater.id}>
        <TheaterResult
          hasFocus={focusedIndex === index}
          movie={movie}
          onFocus={onFocus(index, result.theater.id)}
          ref={refs[index]}
          shows={result.shows}
          theater={result.theater}
        />
        {index < sbt.length - 1 && <hr />}
      </Fragment>
    ));
  };

  const theaterResults = useMemo(() => buildTheaterResults(showsByTheater), [
    focusedIndex,
    selectedDate,
    showsByTheater,
  ]);

  if (!showsByTheater.length) {
    return (
      <StyledTRMessage>
        <p>{t('noShowTimesForDateText', 'No showtimes available for this date.')}</p>
      </StyledTRMessage>
    );
  }

  const mapCenterCoords = showsByTheater[focusedIndex]?.theater.coords || getCurrentPositionCoords(state);
  const mapProps: TheaterMapProps = {
    focusedIndex,
    mapCenterCoords,
    maxHeight,
    onMapMarkerClick,
    theaters: showsByTheater,
  };
  const moreLessButtonText = showAll ? t('lessText', 'Less') : t('moreText', 'More');
  const mapButtonText = (isMapOpen
    ? t('mapButtonCloseText', 'Close Map')
    : t('mapButtonOpenText', 'Open Map')
  ).toUpperCase();

  return (
    <StyledTheaterResultsWrapper>
      <StyledTheaterResults allowScroll={showAll} maxHeight={maxHeight} ref={resultsContainerRef}>
        <StyledTRInner ref={innerResultsRef} theme={theme}>
          {theaterResults.slice(0, showAll ? undefined : defaultShowCount)}
          {showsByTheater.length > defaultShowCount && (
            <StyledTRButton onClick={onInteractMoreLess} onKeyDown={onInteractMoreLess} theme={theme.loadButton}>
              {moreLessButtonText}
            </StyledTRButton>
          )}
        </StyledTRInner>
      </StyledTheaterResults>
      {isDesktop && (
        <StyledTheaterMapWrapper isOpen={isMapOpen}>
          <StyledTheaterMapButton onClick={onMapButtonClick} theme={theme.mapButton} width={mapButtonWidth}>
            <MapButtonTab />
            <StyledTheaterMapButtonTextContainer>
              <MapButtonPin />
              <StyledTheaterMapButtonText ref={mapButtonTextRef}>{mapButtonText}</StyledTheaterMapButtonText>
            </StyledTheaterMapButtonTextContainer>
          </StyledTheaterMapButton>
          {isMapLoaded && <TheaterMap {...mapProps} />}
        </StyledTheaterMapWrapper>
      )}
    </StyledTheaterResultsWrapper>
  );
};
