import * as React from 'react';
import { useBaseTitleLayout } from '../hooks/useBaseTitleLayout';
import { TitleLayoutProps } from '../TitleLayout.props';

// noinspection JSUnusedGlobalSymbols
export default (props: TitleLayoutProps) => {
  const {
    BaseTitleLayout,
    HomeEntertainmentSection,
    ImageGallerySection,
    TitleAboutSection,
    VideoCarouselSection,
  } = useBaseTitleLayout(props, 'app:layout:DefaultTitleLayout');

  return (
    <BaseTitleLayout>
      <HomeEntertainmentSection />
      <TitleAboutSection />
      <VideoCarouselSection />
      <ImageGallerySection />
    </BaseTitleLayout>
  );
};
