import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../../common/breakpoints';
import { TheaterResultTheme } from '../../TicketingWidget.theme';
import { StyledTheaterLocation } from '../theater-location/TheaterLocation.styles';
import { StyledTheaterShowtimes } from '../theater-showtimes/TheaterShowtimes.styles';

export const StyledTheaterResult = styled.div<{ theme: TheaterResultTheme }>`
  display: flex;
  flex-direction: column;
  padding: ${rem(10)} ${rem(10)} ${rem(4)} ${rem(10)};
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.color};

  & > ${StyledTheaterLocation} {
    flex: 0 0 auto;
  }

  & > ${StyledTheaterShowtimes} {
    flex: 1;
  }

  ${mediaQueryWidth({ min: 'mobile' })`
    flex-direction: row;
    padding: ${rem(20)};

    & > * {
      display: inline-block;
      vertical-align: top;
    }
  `}
`;
