import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { black, white } from '../../common/colors';
import { CarouselArrowProps } from './CarouselArrow.props';

export const StyledCarouselArrow = styled.button<Pick<CarouselArrowProps, 'direction' | 'outside' | 'showAtEdge'>>`
  display: block;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(0, -50%);
  width: ${rem(32)};
  height: ${rem(32)};
  padding: 0;
  font-size: 0;
  line-height: 0;
  border: none;
  outline: none;
  background-color: ${black};
  color: ${white};
  opacity: 0.7;
  cursor: pointer;
  ${({ direction, outside }) =>
    direction === 'right'
      ? `right: 0; ${outside && 'transform: translate(100%, -50%);'}`
      : `left: 0; ${outside && 'transform: translate(-100%, -50%);'}`}

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: 1px dotted ${white};
    outline: 1px auto -webkit-focus-ring-color;
  }

  &::before {
    display: none;
  }

  &.slick-disabled {
    cursor: default;
    ${({ showAtEdge }) => (showAtEdge ? 'opacity: 0.25;' : 'display: none;')}

    &:focus {
      outline: none;
    }
  }

  & > svg {
    width: ${rem(8)};
  }

  ${mediaQueryWidth({ min: 'tablet' })`
    width: ${rem(40)};
    height: ${rem(40)};

    & > svg {
      width: ${rem(12)};
    }
  `}
`;
