import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { darkGrey3, grey90, white } from '../../common/colors';

export const StyledTitleAbout = styled.section`
  .rtecenter {
    text-align: center;
  }
  background-color: ${darkGrey3};
  color: ${white};

  & > div {
    padding: 2rem;

    &:last-of-type {
      background-color: ${grey90};
    }
  }

  ${mediaQueryWidth({ min: 'tablet' })`
    display: flex;
    flex-direction: row;

    & > div {
      &:first-of-type {
        flex: 1;
      }

      &:nth-of-type(2) {
        flex: 2;
      }

      &:last-of-type {
        flex: 1;
      }
    }
  `}
`;

export const StyledKeyart = styled.section`
  img {
    display: block;
    width: 16rem;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
`;

export const StyledSynopsis = styled.section`
  h2 {
    color: ${white};
    margin: 0;
    padding: 0 0 1rem 0;

    ${mediaQueryWidth({ min: 'tablet' })`
      font-size: ${rem(40)};
    `}
  }
`;

export const StyledSocialLinks = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: inline;
    padding-top: 4px;
    padding-right: 15px;

    ${mediaQueryWidth({ min: 'mobile' })`
      padding-right: 20px;
    `}
  }
`;

export const StyledMetadata = styled.section`
  color: #c6c6c6;

  h3 {
    margin: 0 0 0.5rem;
    font-size: ${rem(25)};
    color: ${white};
    text-transform: uppercase;
    font-weight: 800;
  }

  & > section {
    &:not(:last-of-type) {
      margin: 0 0 1rem 0;
    }

    p {
      margin: 0;
    }
  }
`;
