import * as React from 'react';
import { createContext, useContext } from 'react';
import { defaultTicketingTheme, TicketingTheme } from '../TicketingWidget.theme';

const TicketingThemeContext = createContext({
  theme: defaultTicketingTheme,
});
TicketingThemeContext.displayName = 'TicketingThemeContext';

export const TicketingThemeProvider = ({ theme, children }: { theme: TicketingTheme; children: React.ReactNode }) => (
  <TicketingThemeContext.Provider value={{ theme }}>{children}</TicketingThemeContext.Provider>
);

export const useTheme = (): { theme: TicketingTheme } => {
  const context = useContext(TicketingThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};
