import * as React from 'react';
import { useEffect, useState } from 'react';
import { useWindowResize } from './useWindowResize';

export const usePopup = (containerRef: React.RefObject<HTMLDivElement>, triggerRef: React.RefObject<HTMLElement>) => {
  const { windowWidth } = useWindowResize();
  const [popupTop, setPopupTop] = useState<number>(0);
  const [popupLeft, setPopupLeft] = useState<number>(0);
  const [popupWidth, setPopupWidth] = useState<number>(0);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const setPopupDimensions = () => {
    setPopupTop(triggerRef.current!.offsetTop + triggerRef.current!.offsetHeight);
    setPopupLeft(triggerRef.current!.offsetLeft);
    setPopupWidth(triggerRef.current!.offsetWidth);
  };

  const onPopupToggle = () => {
    setPopupVisible(!popupVisible);
  };

  const onPopupOpen = () => {
    setPopupVisible(true);
  };

  const onPopupClose = () => {
    setPopupVisible(false);
  };

  const onOutsideClick = (e: any) => {
    if (containerRef.current!.contains(e.target)) return;
    setPopupVisible(false);
  };

  useEffect(() => {
    setPopupDimensions();
  }, [windowWidth]);

  useEffect(() => {
    if (popupVisible) {
      document.addEventListener('mousedown', onOutsideClick);
    } else {
      document.removeEventListener('mousedown', onOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', onOutsideClick);
    };
  }, [popupVisible]);

  return { onPopupToggle, onPopupOpen, onPopupClose, popupTop, popupLeft, popupWidth, popupVisible };
};
