import { cloneDeep } from 'lodash';
import * as React from 'react';
import { createContext, useContext, useMemo, useReducer } from 'react';
import { momentDate } from '../../../common/helpers/date';
import { TranslationFn, useTranslations } from '../../../common/translation';
import { CheckboxListDropdownItemInterface } from '../../checkbox-list-dropdown/CheckboxListDropdown.props';
import { ShowsByDay, ShowsByTheater, ShowTime } from '../hooks/useShowTimes';
import { buildCoords, City, cityName, Coordinate, GeoPosition, MapPosition } from '../hooks/utils';
import { MovieDate } from '../movie-dates/MovieDates.props';
import translations from '../translations';

interface TicketingState {
  allowGeolocation: boolean;
  allShows: ShowsByDay[];
  currentCity: City | undefined;
  filteredShows: ShowsByDay[];
  formatItems: CheckboxListDropdownItemInterface[];
  geoPosition: GeoPosition | undefined;
  locationName: string;
  mapPosition: MapPosition | undefined;
  movieDates: MovieDate[];
  nearestCity: City | undefined;
  timeItems: CheckboxListDropdownItemInterface[];
  selectedDate: string;
  utilizeGeoPosition: boolean;
}

type TicketingActionType =
  | 'populate'
  | 'selectCity'
  | 'selectCurrentLocation'
  | 'selectNearestCity'
  | 'setFormatItems'
  | 'setGeoPosition'
  | 'setLocationName'
  | 'setMapPosition'
  | 'setMovieDates'
  | 'setNearestCity'
  | 'setTimeItems'
  | 'setUtilizeGeoPosition';

interface TicketingAction {
  type: TicketingActionType;
  payload: any;
}

const timeRanges = {
  MORNING: 'Morning',
  AFTERNOON: 'Afternoon',
  EVENING: 'Evening',
};

// @ts-ignore
const TicketingStateContext = createContext();
TicketingStateContext.displayName = 'TicketingStateContext';

const initialState: TicketingState = {
  allowGeolocation: false,
  allShows: [],
  currentCity: undefined,
  filteredShows: [],
  formatItems: [],
  geoPosition: undefined,
  locationName: '',
  mapPosition: undefined,
  movieDates: [],
  nearestCity: undefined,
  timeItems: [],
  selectedDate: '',
  utilizeGeoPosition: false,
};

// Maintaining for consistency with time range as well as potential changes
export const getScreenTypeEnum = (screenTypeEnum: string): string => screenTypeEnum;

export const getTimeRangeEnum = (timeRangeEnum: string): string => timeRanges[timeRangeEnum];

export const selectedDropDownIds = (items: CheckboxListDropdownItemInterface[] = []): string[] =>
  items
    .filter((ti: CheckboxListDropdownItemInterface) => ti.checked)
    .map((ti: CheckboxListDropdownItemInterface) => ti.id);

const applyRemoveEmptyFilter = (showsByDay: ShowsByDay[]): ShowsByDay[] => {
  showsByDay.forEach((sbd: ShowsByDay) => {
    sbd.showsByTheater = sbd.showsByTheater.filter((sbt: ShowsByTheater) => sbt.shows.length > 0);
  });

  return showsByDay.filter((sbd: ShowsByDay) => sbd.showsByTheater.length > 0);
};

// Remove shows that do not match the 'time' filter
export const applyTimeFilter = (
  timeItems: CheckboxListDropdownItemInterface[],
  showsByDay: ShowsByDay[],
): ShowsByDay[] => {
  const selectedTimes = selectedDropDownIds(timeItems);
  if (selectedTimes.length > 0) {
    showsByDay.forEach((sbd: ShowsByDay) => {
      sbd.showsByTheater.forEach((sbt: ShowsByTheater) => {
        sbt.shows = sbt.shows.filter((st: ShowTime) => selectedTimes.includes(getTimeRangeEnum(st.timeRange)));
      });
    });
  }

  return applyRemoveEmptyFilter(showsByDay);
};

// Remove shows that do not match the 'format' filter
export const applyFormatFilter = (
  formatItems: CheckboxListDropdownItemInterface[],
  showsByDay: ShowsByDay[],
): ShowsByDay[] => {
  const selectedFormats = selectedDropDownIds(formatItems);
  if (selectedFormats.length > 0) {
    showsByDay.forEach((sbd: ShowsByDay) => {
      sbd.showsByTheater.forEach((sbt: ShowsByTheater) => {
        sbt.shows = sbt.shows.filter((st: ShowTime) => selectedFormats.includes(getScreenTypeEnum(st.screenType)));
      });
    });
  }

  return applyRemoveEmptyFilter(showsByDay);
};

const buildDropDownItems = (filters: string[] = [], t: TranslationFn) =>
  filters.map((filter: string) => ({ checked: false, id: filter, label: t(filter.toLowerCase(), filter) }));

const applyFilters = (
  allShows: ShowsByDay[],
  timeItems: CheckboxListDropdownItemInterface[],
  formatItems: CheckboxListDropdownItemInterface[],
): ShowsByDay[] => {
  const clonedShows = cloneDeep(allShows);
  return applyTimeFilter(timeItems, applyFormatFilter(formatItems, clonedShows));
};

/*
 * Build all date objects for this result set, even the ones that we're not current displaying.
 */
const buildMovieDates = (showsByDay: ShowsByDay[] = []): MovieDate[] => {
  if (showsByDay.length === 0) return [];

  const dates: MovieDate[] = [];
  const startDateString = showsByDay[0].date;
  const endDateString = showsByDay[showsByDay.length - 1].date;
  const startMoment = momentDate(startDateString).startOf('day');
  const endMoment = momentDate(endDateString).startOf('day');
  if (startMoment > endMoment) return [];

  // Build the days that we want to display
  dates.push({ date: startMoment, dateString: startMoment.format(), enabled: true, current: true });
  let dayCounter = 1;
  while (true) {
    const nextDay = startMoment.clone().add(dayCounter, 'days');
    if (nextDay > endMoment) break;

    dates.push({ date: nextDay, dateString: nextDay.format(), enabled: false, current: false });
    dayCounter += 1;
  }

  // See if we have any dates (that have shows) that match
  for (const show of showsByDay) {
    const itemMoment = momentDate(show.date).startOf('day');
    const matchingDate = dates.find((movieDate) => movieDate.date.isSame(itemMoment, 'day'));
    if (matchingDate) {
      matchingDate.enabled = true;
    }
  }

  return dates;
};

const refreshLocationName = (state: TicketingState, t: TranslationFn): TicketingState => {
  const { allowGeolocation, geoPosition, mapPosition, utilizeGeoPosition } = state;

  if (allowGeolocation && utilizeGeoPosition) {
    return {
      ...state,
      locationName: geoPosition ? t('currentLocationText', 'Current Location') : t('locatingText', 'Locating...'),
    };
  }

  if (mapPosition) {
    return { ...state, locationName: `${mapPosition.lat}, ${mapPosition.lng}` };
  }

  return state;
};

const selectCity = (state: TicketingState, city: City): TicketingState => {
  return {
    ...state,
    allowGeolocation: false,
    currentCity: city,
    locationName: cityName(city),
    mapPosition: undefined,
    utilizeGeoPosition: false,
  };
};

const clearLocation = (state: TicketingState): TicketingState => {
  return {
    ...state,
    allowGeolocation: false,
    locationName: '',
    mapPosition: undefined,
    utilizeGeoPosition: false,
  };
};

const selectNearestCity = (state: TicketingState): TicketingState => {
  const city = state.nearestCity;
  if (city) {
    return selectCity(state, city);
  }

  return clearLocation(state);
};

const updateStateFilters = (state: TicketingState): TicketingState => {
  const { allShows, timeItems, formatItems } = state;
  let selectedDate = state.selectedDate.slice();

  const filteredShows = applyFilters(allShows, timeItems, formatItems);
  const movieDates = buildMovieDates(filteredShows);

  if (movieDates.length > 0 && movieDates[0].dateString !== selectedDate) {
    selectedDate = movieDates[0].dateString;
  }

  return {
    ...state,
    filteredShows,
    movieDates,
    selectedDate,
  };
};

const ticketingStateReducer = (t: TranslationFn) => (
  state: TicketingState,
  action: TicketingAction,
): TicketingState => {
  switch (action.type) {
    case 'populate': {
      const data = action.payload;
      const allShows = applyRemoveEmptyFilter(data.showsByDay || []);
      const times = (data.timeRanges || []).map((tr: string) => getTimeRangeEnum(tr));
      const screens = (data.screenTypes || []).map((st: string) => getScreenTypeEnum(st));

      return updateStateFilters({
        ...state,
        allShows,
        timeItems: buildDropDownItems(times, t),
        formatItems: buildDropDownItems(screens, t),
      });
    }
    case 'selectCity': {
      const city: City = action.payload;
      return selectCity(state, city);
    }
    case 'selectNearestCity': {
      return selectNearestCity(state);
    }
    case 'selectCurrentLocation': {
      return refreshLocationName(
        {
          ...state,
          allowGeolocation: true,
          mapPosition: undefined,
          utilizeGeoPosition: true,
        },
        t,
      );
    }
    case 'setFormatItems': {
      return updateStateFilters({
        ...state,
        formatItems: action.payload,
      });
    }
    case 'setGeoPosition': {
      return refreshLocationName(
        {
          ...state,
          geoPosition: action.payload,
        },
        t,
      );
    }
    case 'setLocationName': {
      return {
        ...state,
        locationName: action.payload,
      };
    }
    case 'setMapPosition': {
      return refreshLocationName(
        {
          ...state,
          mapPosition: action.payload,
        },
        t,
      );
    }
    case 'setMovieDates': {
      const movieDates = action.payload;
      const currentDate = movieDates.find((date: MovieDate) => date.current);
      const dateString = currentDate?.dateString;
      const selectedDateState = dateString && { selectedDate: dateString };
      return {
        ...state,
        ...selectedDateState,
        movieDates,
      };
    }
    case 'setNearestCity': {
      return selectNearestCity({
        ...state,
        nearestCity: action.payload,
      });
    }
    case 'setTimeItems': {
      return updateStateFilters({
        ...state,
        timeItems: action.payload,
      });
    }
    case 'setUtilizeGeoPosition': {
      return refreshLocationName(
        {
          ...state,
          utilizeGeoPosition: action.payload,
        },
        t,
      );
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const TicketingStateProvider = ({ children }: { children: React.ReactNode }) => {
  const { locale, t } = useTranslations(translations);
  const reducer = useMemo(() => ticketingStateReducer(t), [locale]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <TicketingStateContext.Provider value={value}>{children}</TicketingStateContext.Provider>;
};

export const useTicketingReducer = (): any => {
  const context = useContext(TicketingStateContext);
  if (context === undefined) {
    throw new Error('useTicketingReducer must be used within a TicketingStateProvider');
  }

  return context;
};

export const getCurrentPositionCoords = ({
  currentCity,
  geoPosition,
  mapPosition,
  utilizeGeoPosition,
}: TicketingState): Coordinate | undefined =>
  geoPosition || mapPosition || currentCity
    ? buildCoords(geoPosition, utilizeGeoPosition, mapPosition, currentCity)
    : undefined;
