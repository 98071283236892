import * as React from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { useTranslations } from '../../common/translation';
import { Img } from '../img/Img';
import { ScrollAnchor } from '../scroll-anchor/ScrollAnchor';
import { CardTitle } from './card-title/CardTitle';
import { CardProps } from './Card.props';
import {
  CardButton,
  CardButtonPositionContainer,
  CardButtonWrapper,
  CardContent,
  CardContentContainer,
  CardDetailsContainer,
  CardImageContainer,
  defaultCardHeight,
  StyledCard,
} from './Card.styles';
import translations from './translations';

export const Card = ({
  anchor,
  cardTitle,
  details,
  fixedContainerHeight,
  headingTag,
  image,
  initialIsContentExpanded = false,
  useDarkMode = false,
  useContentPadding = true,
}: CardProps) => {
  const contentContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const contentRef: React.RefObject<HTMLDivElement> = useRef(null);
  const titleRef: React.RefObject<HTMLElement> = useRef(null);
  const [isContentExpanded, setIsContentExpanded] = useState<boolean>(initialIsContentExpanded);
  const [showToggleButton, setShowToggleButton] = useState<boolean>(true);
  const { windowWidth } = useWindowResize();
  const { t } = useTranslations(translations);

  const hasImage: boolean = !!(image && image.src);

  const expandContent = () => {
    setIsContentExpanded(true);
  };

  const collapseContent = () => {
    setIsContentExpanded(false);
  };

  const checkToggleButtonVisibility = () => {
    const contentContainerHeight = (contentContainerRef.current && contentContainerRef.current.clientHeight) || 0;
    const titleHeight = (titleRef.current && titleRef.current.clientHeight) || 0;
    const contentHeight = (contentRef.current && contentRef.current.clientHeight) || 0;
    const cardHeight = contentHeight + titleHeight;
    const contentOverflow = cardHeight > contentContainerHeight;
    const collapsedHeight = fixedContainerHeight
      ? Math.min(fixedContainerHeight, defaultCardHeight)
      : defaultCardHeight;
    const buttonRequiredWhenExpanded = cardHeight > collapsedHeight;

    setShowToggleButton(contentOverflow || (isContentExpanded && buttonRequiredWhenExpanded));
  };

  const setTabIndexOnAnchors = () => {
    if (contentRef.current) {
      const tabIndex: number = isContentExpanded ? 0 : -1;
      Array.from(contentRef.current.querySelectorAll('a')).forEach((ele: HTMLElement) => (ele.tabIndex = tabIndex));
    }
  };

  const refreshButtons = () => {
    checkToggleButtonVisibility();
    setTabIndexOnAnchors();
  };

  const toggleButton = (
    <CardButtonWrapper isContentExpanded={isContentExpanded} useDarkMode={useDarkMode}>
      <CardButton className='card-button' onClick={isContentExpanded ? collapseContent : expandContent} tabIndex={0}>
        {isContentExpanded ? t('lessText', 'Less') : t('moreText', 'More')}
      </CardButton>
    </CardButtonWrapper>
  );

  useEffect(() => {
    setIsContentExpanded(initialIsContentExpanded);
  }, [initialIsContentExpanded]);

  useLayoutEffect(() => {
    if (windowWidth !== 0) {
      collapseContent();
      refreshButtons();
    }
  }, [windowWidth]);

  useLayoutEffect(() => {
    refreshButtons();
  }, [fixedContainerHeight, isContentExpanded]);

  return (
    <StyledCard>
      {anchor && <ScrollAnchor id={anchor} />}
      {hasImage && (
        <CardImageContainer>
          <Img alt={image!.alt} src={image!.src} />
        </CardImageContainer>
      )}
      {details && (
        <CardDetailsContainer hasImage={hasImage} useContentPadding={useContentPadding}>
          <CardButtonPositionContainer>
            <CardContentContainer
              className={isContentExpanded ? 'card-expanded' : 'card-collapsed'}
              fixedContainerHeight={fixedContainerHeight}
              isContentExpanded={isContentExpanded}
              ref={contentContainerRef}
            >
              {cardTitle && cardTitle.title && (
                <CardTitle
                  headingTag={headingTag}
                  ref={titleRef}
                  subtitle={cardTitle.subtitle}
                  title={cardTitle.title}
                />
              )}
              <CardContent>
                <div dangerouslySetInnerHTML={{ __html: details }} ref={contentRef} />
              </CardContent>
            </CardContentContainer>
            {showToggleButton && toggleButton}
          </CardButtonPositionContainer>
        </CardDetailsContainer>
      )}
    </StyledCard>
  );
};
