import { rem } from 'polished';
import styled from 'styled-components';

export const StyledRating = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  img {
    max-width: ${rem(40)};
    margin: 0.4rem 0.5rem 0 0;
  }
`;

export const StyledRatingsList = styled.div`
  display: flex;
  flex-wrap: wrap;

  img {
    max-height: ${rem(45)};
    margin-right: ${rem(20)};
  }

  span {
    margin-right: ${rem(20)};
  }
`;
