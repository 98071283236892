import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { darkGrey1 } from '../../common/colors';
import { StyledSliderContainer } from '../../common/slider.styles';

export const StyledBannerCarousel = styled(StyledSliderContainer)<{ addTopMargin?: boolean }>`
  background-color: ${darkGrey1};

  ${({ addTopMargin }) =>
    addTopMargin &&
    css`
      margin-top: ${rem(30)};

      ${mediaQueryWidth({ min: 'mobile' })`
        margin-top: ${rem(50)};
      `}
    `}
`;
