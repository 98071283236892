import * as React from 'react';
import CloseIcon from '../../assets/images/icons/svg/close.svg';
import { StyledCloseButton, StyledPopup } from '../../common/popup.styles';
import { PopupContainerProps } from './PopupContainer.props';

export const PopupContainer = ({ children, onClose, show }: PopupContainerProps) => {
  return (
    <StyledPopup show={show} aria-modal={true}>
      <StyledCloseButton onClick={onClose}>
        <CloseIcon />
      </StyledCloseButton>
      {children}
    </StyledPopup>
  );
};
