import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { grey90, white } from '../../common/colors';
import { StyledSliderContainer } from '../../common/slider.styles';

export const StyledVideoCarousel = styled(StyledSliderContainer)`
  background-color: ${grey90};
  color: ${white};

  .slick-slider {
    padding-bottom: ${rem(36)};
  }

  ${mediaQueryWidth({ min: 'mobile' })`
    .slick-slider {
      padding-bottom: 0;
    }
  `}
`;
