import { rem } from 'polished';
import styled from 'styled-components';
import { lightBlue } from './colors';

export const StyledSliderContainer = styled.div`
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;

    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list,
  .slick-slider .slick-slide {
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);
    perspective: 1000px;
    backface-visibility: hidden;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: 0;
    margin-right: auto;
    line-height: 0;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-dots {
    position: absolute;
    bottom: ${rem(12)};
    left: 50%;
    z-index: 1;
    transform: translate(-50%, 0);
    width: auto;
    margin: 0;
    padding: 0;
    line-height: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      cursor: auto;

      &.slick-active button {
        background-color: ${lightBlue};
      }
    }
  }
`;
