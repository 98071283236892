import { rem } from 'polished';
import styled from 'styled-components';
import { navigationPxHeight } from '../navigation/Navigation.styles';
import { subnavHeightPx } from '../subnav/Subnav.styles';

export const StyledRelativeContainer = styled.div`
  position: relative;
`;

export const StyledScrollAnchor = styled.span<{ hasSubnav?: boolean }>`
  position: absolute;
  top: -${({ hasSubnav = true }) => rem(hasSubnav ? navigationPxHeight + subnavHeightPx : navigationPxHeight)};
  left: 0;
  visibility: hidden;
`;
