import { rem } from 'polished';
import styled from 'styled-components';
import { black, white } from '../../../../../common/colors';
import { MapMarker } from '../../../TicketingWidget.theme';

const theaterImageTopOffsetPx = 9;
const theaterImageWidthPx = 32;
const theaterImagePaddingPx = 2;
const markerSvgWidthPx = 56;

export const StyledTheaterMapMarker = styled.div<{ hasFocus: boolean; theme: MapMarker }>`
  position: relative;
  z-index: ${({ hasFocus }) => (hasFocus ? 1 : 0)};
  width: ${markerSvgWidthPx}px;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.borderColor};
  }

  // Bounce animation inspired by https://css-tricks.com/making-css-animations-feel-natural
  &:hover {
    animation-name: bounce;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  }

  @keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(-50%);
    }
    10% {
      transform: scale(1.05, 0.95) translateY(-50%);
    }
    30% {
      transform: scale(0.95, 1.05) translateY(calc(-50% - 10px));
    }
    50% {
      transform: scale(1.025, 0.975) translateY(-50%);
    }
    57% {
      transform: scale(1, 1) translateY(calc(-50% - 7px));
    }
    64% {
      transform: scale(1, 1) translateY(-50%);
    }
    100% {
      transform: scale(1, 1) translateY(-50%);
    }
  }
`;

export const StyledImageInMarker = styled.div`
  position: absolute;
  top: ${theaterImageTopOffsetPx}px;
  left: 50%;
  transform: translateX(-50%);
  width: ${theaterImageWidthPx}px;
  height: ${theaterImageWidthPx}px;
  box-sizing: border-box;
  border-radius: 50%;
  padding: ${theaterImagePaddingPx}px;
  background-color: ${white};
  line-height: 0;

  img {
    width: 100%;
  }
`;

export const StyledMapMarkerFallback = styled.div`
  font-size: ${rem(16)};
  font-weight: 800;
  line-height: ${theaterImageWidthPx - theaterImagePaddingPx}px;
  color: ${black};
  text-align: center;
`;
