import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../../common/breakpoints';
import { darkGrey2, white } from '../../../../common/colors';
import { buttonStateStyles } from '../../ticketing.styles';
import { TheaterButtonTheme } from '../../TicketingWidget.theme';

export const StyledTheaterLocation = styled.div``;

export const StyledTLImageContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: ${rem(40)};
  height: ${rem(40)};
  box-sizing: border-box;
  margin-right: ${rem(10)};
  padding: ${rem(5)};
  border-radius: ${rem(40)};
  background-color: ${white};

  ${mediaQueryWidth({ min: 'mobile' })`
    width: ${rem(60)};
    height: ${rem(60)};
    margin-right: ${rem(20)};
  `}
`;

export const StyledTheaterImg = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// @ts-ignore
export const StyledTheaterImgFallback = styled(StyledTheaterImg)`
  height: 50%;
  width: 50%;
`;

export const StyledTheaterInitial = styled.div`
  font-size: ${rem(17)};
  font-weight: 800;
  color: ${darkGrey2};

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(30)};
  `}
`;

export const StyledTLAddressDistance = styled.div`
  display: inline-flex;
  vertical-align: top;
  width: calc(100% - ${rem(50)});
  align-items: flex-start;
  justify-content: space-between;

  ${mediaQueryWidth({ min: 'mobile' })`
    display: inline-block;
    width: ${rem(216)};
  `}
`;

export const StyledTLAddress = styled.div`
  display: inline-block;
  max-width: calc(100% - ${rem(80)});

  ${mediaQueryWidth({ min: 'mobile' })`
    display: block;
    max-width: none;
  `}

  & > :first-child {
    font-size: ${rem(14)};
    font-weight: 800;
    line-height: ${rem(20)};

    ${mediaQueryWidth({ min: 'mobile' })`
      font-size: ${rem(16)};
      line-height: ${rem(24)};
    `}
  }

  & > :nth-child(2) {
    font-size: ${rem(10)};
    line-height: ${rem(16)};

    ${mediaQueryWidth({ min: 'mobile' })`
      margin-top: ${rem(4)};
      font-size: ${rem(14)};
      line-height: ${rem(22)};
    `}
  }
`;

export const StyledTLDistance = styled.a<{ theme: TheaterButtonTheme }>`
  display: inline-block;
  padding: ${rem(6)} ${rem(10)};
  font-size: ${rem(10)};
  font-weight: 700;
  line-height: ${rem(10)};
  color: ${({ theme }) => theme.color};
  background-color: ${({ theme }) => theme.backgroundColor};
  border-color: ${({ theme }) => theme.borderColor};
  border-style: solid;
  border-width: ${rem(2)};
  box-sizing: border-box;
  cursor: pointer;
  text-transform: lowercase;

  ${buttonStateStyles}

  & > svg {
    display: block;
    line-height: 0;
    text-align: center;
  }

  & > :last-child {
    padding-top: ${rem(4)};
    display: inline-block;
  }

  svg {
    height: ${rem(16)};
    width: ${rem(16)};
  }

  ${mediaQueryWidth({ min: 'mobile' })`
    margin-top: ${rem(10)};
    padding: ${rem(8)} ${rem(16)};
    font-size: ${rem(12)};
    line-height: ${rem(12)};

    & > svg {
      display: inline-block;
      line-height: 0;
      margin: 0 ${rem(10)} 0 0;
    }

    & > * {
      vertical-align: middle;
    }
  `}
`;
