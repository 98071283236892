import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from './breakpoints';
import { black, white } from './colors';

export const StyledDropdownContainer = styled.div<{ top: number; left: number; width: number; visible: boolean }>`
  display: ${({ visible }) => (visible ? 'inline' : 'none')};
  position: absolute;
  z-index: 2;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: ${({ width }) => width}px;
  box-sizing: border-box;
  margin-top: ${rem(4)};
  border-bottom: 1px solid ${black};
  box-shadow: 0 ${rem(2)} ${rem(4)} 0 rgba(0, 0, 0, 0.4);
  background-color: ${white};
  color: ${black};

  ${mediaQueryWidth({ min: 'mobile' })`
    margin-top: ${rem(10)};
  `}
`;
