import * as React from 'react';
import { HeadingTag } from '../../common/interfaces';
import { getSocialLinks } from '../../common/social-icons';
import { SuperLink } from '../super-link/SuperLink';
import { AdditionalLinksProps, FeaturedTitleProps } from './FeaturedTitle.props';
import {
  DisplaySubtitle,
  DisplayTitle,
  MarketingMessage,
  StyledButton,
  StyledFeaturedTitle,
} from './FeaturedTitle.styles';
import { GetTicketsDropdown } from './get-tickets-dropdown/GetTicketsDropdown';

const AdditionalLinks = (additionalLinks: AdditionalLinksProps[]) =>
  additionalLinks &&
  additionalLinks
    .slice(0, 3)
    .map((link: AdditionalLinksProps) => {
      if (link.url && link.displayText) {
        return (
          <li key={link.url}>
            <StyledButton disableCaps={true} href={link.url} invertColors={true} size='md' target='_blank'>
              {link.displayText}
            </StyledButton>
          </li>
        );
      }
    })
    .filter(Boolean);

export const FeaturedTitle = ({
  addlTitleData,
  headingTag = 'h2',
  hideTitle,
  inTheaters,
  subTitle,
  tickets,
  title,
  titleURL,
}: FeaturedTitleProps) => {
  if (!title) {
    return null;
  }

  const hasTickets = inTheaters && (tickets || []).length > 0;
  const hasLinks = addlTitleData && (addlTitleData.additionalLinks || []).length > 0;
  const titleHeadingTag = headingTag;
  const subtitleHeadingTag = `h${+headingTag.split('')[1] + 1}` as HeadingTag;

  return (
    <StyledFeaturedTitle hide={hideTitle}>
      <header>
        {titleURL ? (
          <DisplayTitle as={titleHeadingTag} className='displayTitleLink'>
            <SuperLink to={titleURL}>{title}</SuperLink>
          </DisplayTitle>
        ) : (
          <DisplayTitle as={titleHeadingTag} className='displayTitle' dangerouslySetInnerHTML={{ __html: title }} />
        )}
        {subTitle && <DisplaySubtitle as={subtitleHeadingTag} dangerouslySetInnerHTML={{ __html: subTitle }} />}
        {addlTitleData && addlTitleData.marketingMessage && (
          <MarketingMessage
            className='marketing-message'
            dangerouslySetInnerHTML={{ __html: addlTitleData.marketingMessage }}
          />
        )}
        {addlTitleData && (addlTitleData.socialIcons || []).length > 0 && (
          <ul>{getSocialLinks(addlTitleData.socialIcons!)}</ul>
        )}
        {(hasLinks || hasTickets) && (
          <ul className='additional-links'>
            {hasTickets && (
              <li>
                <GetTicketsDropdown tickets={tickets!} />
              </li>
            )}
            {hasLinks && AdditionalLinks(addlTitleData?.additionalLinks!)}
          </ul>
        )}
      </header>
    </StyledFeaturedTitle>
  );
};
