import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { black } from '../../../common/colors';
import { StyledSliderContainer } from '../../../common/slider.styles';

const arrowSvgWidth = 12;
const arrowPadding = 15;
const arrowWidth = arrowSvgWidth + arrowPadding * 2;
export const combinedArrowWidth = arrowWidth * 2;

export const StyledMDContainer = styled(StyledSliderContainer)<{ backgroundColor?: string }>`
  z-index: 1;
  background-color: ${({ backgroundColor }) => backgroundColor || black};
  margin: ${rem(32)} ${rem(32)} 0;

  ${mediaQueryWidth({ min: 'tablet' })`
    margin: ${rem(32)} ${rem(40)} 0;
  `}

  & *:focus {
    outline-offset: -2px;
  }
`;
