import { List, Paper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import * as React from 'react';
import DownArrowIcon from '../../../assets/images/icons/svg/down-arrow.svg';
import RightArrowIcon from '../../../assets/images/icons/svg/right-arrow.svg';
import { SubnavOptions } from '../subnav-options/SubnavOptions';
import { PopperStyled, StyledSubnavDropdown } from '../Subnav.styles';
import { SubnavDropdownProps } from './SubnavDropdown.props';

export class SubnavDropdown extends React.Component<SubnavDropdownProps, any> {
  constructor(props: SubnavDropdownProps) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  public handleClick = (event: any) => {
    event.preventDefault();
    const { currentTarget } = event;
    if (currentTarget) {
      this.setState((state: any) => ({
        anchorEl: currentTarget,
        open: !state.open,
      }));
    }
  };

  public handleClickAway = (e: any) => {
    e.preventDefault();
    if (!e.target.parentNode.className || !e.target.parentNode.className.match(/(\bsubnavDropdown|\bpopperTrigger)/g)) {
      this.closePopper();
    }
  };

  public closePopper = () => {
    this.setState({ open: false });
  };

  public renderPopper = () => {
    const { title, data, hash, mountedDropdown, currentPage } = this.props;
    const { anchorEl, open } = this.state;
    return (
      <PopperStyled
        id='subnavPopper'
        open={open}
        anchorEl={anchorEl}
        disablePortal={false}
        transition={true}
        placement='bottom'
        keepMounted={mountedDropdown || false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
          },
        }}
      >
        {({ TransitionProps }: any) => (
          <ClickAwayListener onClickAway={this.handleClickAway} touchEvent={false}>
            <Paper className='popperContainer'>
              <Collapse {...TransitionProps} timeout={600}>
                <StyledSubnavDropdown>
                  <List component='nav' className='subnavList'>
                    {data &&
                      data.map((option) => (
                        <SubnavOptions
                          key={option.anchor}
                          {...option}
                          currentTitle={title}
                          currentPage={currentPage}
                          param={hash}
                          handlePopper={this.closePopper}
                          mountedDropdown={mountedDropdown}
                        />
                      ))}
                  </List>
                </StyledSubnavDropdown>
              </Collapse>
            </Paper>
          </ClickAwayListener>
        )}
      </PopperStyled>
    );
  };

  public render() {
    const { placeHolder } = this.props;
    const { open } = this.state;
    return (
      <div className='subnavDropdown'>
        <button onClick={this.handleClick} className='popperTrigger'>
          <span className='executiveText'>{placeHolder}</span>
          {open ? <DownArrowIcon /> : <RightArrowIcon />}
        </button>
        {this.renderPopper()}
      </div>
    );
  }
}
