import * as React from 'react';
import { HeadingTag } from '../../../common/interfaces';
import { CardTitleProps } from './CardTitle.props';
import { StyledCardSubtitle, StyledCardTitle, StyledCardTitleHeader } from './CardTitle.styles';

export const CardTitle = React.forwardRef(({ headingTag = 'h2', subtitle, title }: CardTitleProps, ref: any) => {
  const titleHeadingTag = headingTag;
  const subtitleHeadingTag = `h${+headingTag.split('')[1] + 1}` as HeadingTag;

  return (
    <StyledCardTitleHeader ref={ref}>
      <StyledCardTitle as={titleHeadingTag}>{title}</StyledCardTitle>
      {subtitle && <StyledCardSubtitle as={subtitleHeadingTag}>{subtitle}</StyledCardSubtitle>}
    </StyledCardTitleHeader>
  );
});
