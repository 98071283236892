import { useGet } from '../../../common/hooks/useGet';
import { TICKETING_SERVICE_BASEURL, TICKETING_USE_FIXTURES } from '../../../config/browser';

// tslint:disable-next-line:no-var-requires
const fixtureData = require('../../../../tests/fixtures/ticketing/useNearestCity.json').data;

const BASE_URL = `${TICKETING_SERVICE_BASEURL}/graphql?query=`;

export const useNearestCity = (useFixture: boolean = TICKETING_USE_FIXTURES) => {
  const graphql = `
    {
      query: getNearestCity {
        name
        state
        coords {
          lat
          lng
        }
        postalCode
        countryCode
      }
    }
  `;
  const url = !useFixture ? encodeURI(`${BASE_URL}${graphql}`) : '';
  const { data, error, loading } = useGet(url, { query: null }, false, false, 1);

  if (useFixture) {
    return { data: fixtureData, loading: false };
  }

  return { error, data: data.data?.query || null, loading: !!url && loading };
};
