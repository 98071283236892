import browser from 'browser-detect';
import { BrowserDetectInfo } from 'browser-detect/dist/types/browser-detect.interface';
import { useEffect, useState } from 'react';

export const useBrowserDetails = () => {
  const [browserDetails, setBrowserDetails] = useState<BrowserDetectInfo>({});

  useEffect(() => {
    setBrowserDetails((browser && browser()) || {});
  }, []);

  return { browserDetails };
};
