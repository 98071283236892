import * as React from 'react';
import { useEffect, useRef } from 'react';
import Slider, { ResponsiveObject } from 'react-slick';
import {
  adjustButtonPosition,
  adjustSlideAttributes,
  defaultSliderSettings,
  getSlidesToShow,
} from '../../common/helpers/carousel';
import { useDebug } from '../../common/hooks/useDebug';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { useTranslations } from '../../common/translation';
import { youtubeEmbedStore } from '../../stores/YoutubeEmbedStore';
import { routerButtonValue } from '../button/super-button/SuperButton';
import { CarouselArrow } from '../carousel-arrow/CarouselArrow';
import { SliderButton } from '../slider-button/SliderButton';
import translations from './translations';
import { Video } from './video/Video';
import { VideoProps } from './video/Video.props';
import { VideoCarouselProps } from './VideoCarousel.props';
import { StyledVideoCarousel } from './VideoCarousel.styles';

const responsive: ResponsiveObject[] = [
  {
    breakpoint: 990,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 1350,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 1903,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 2325,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 2958,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 3591,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 4013,
    settings: {
      slidesToShow: 7,
      slidesToScroll: 1,
    },
  },
];

const maxSlidesToShow = 8;

export const VideoCarousel = ({ headingTag, videos }: VideoCarouselProps) => {
  const indexRef = useRef<number>(0);
  const sliderRef = useRef<Slider>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const { isMobile, windowWidth } = useWindowResize();
  const { t } = useTranslations(translations);

  useEffect(() => {
    const carouselEl = carouselRef.current;
    const slider = sliderRef.current;
    if (carouselEl && slider) {
      slider.slickGoTo(0);
      adjustButtonPosition(carouselEl);
    }
  }, [windowWidth]);

  useDebug('app:cmp:VideoCarousel', { props: { videos } });

  if (!(videos || []).length) {
    return null;
  }

  const sliderSettings = {
    ...defaultSliderSettings,
    responsive,
    arrows: true,
    cssEase: 'ease',
    dots: isMobile,
    focusOnSelect: false,
    nextArrow: <CarouselArrow ariaPrefix={t('ariaVideoButton', 'Video')} direction='right' />,
    prevArrow: <CarouselArrow ariaPrefix={t('ariaVideoButton', 'Video')} direction='left' />,
    slidesToScroll: 1,
    slidesToShow: 3,
    speed: 500,
    swipeToSlide: false,
    beforeChange: (_: number, newIndex: number) => {
      indexRef.current = newIndex;
      youtubeEmbedStore.pause();
    },
    customPaging: (buttonIndex: number) => {
      const ariaLabel = `${t('ariaVideoButton', 'Video')} ${buttonIndex + 1}`;
      return <SliderButton activeIndex={indexRef.current} ariaLabel={ariaLabel} buttonIndex={buttonIndex} />;
    },
    onReInit: () => {
      const carouselEl = carouselRef.current;
      const slider = sliderRef.current;
      if (carouselEl && slider) {
        const slidesToShow = getSlidesToShow(slider, responsive, maxSlidesToShow);
        adjustSlideAttributes(
          carouselEl,
          slidesToShow,
          `iframe, a, button:not([data-type="${routerButtonValue}"]), [role="button"]`,
        );
      }
    },
  };

  return (
    <StyledVideoCarousel ref={carouselRef}>
      <Slider {...sliderSettings} ref={sliderRef}>
        {videos.map((video: VideoProps) => (
          <Video {...video} headingTag={headingTag} key={video.url} />
        ))}
      </Slider>
    </StyledVideoCarousel>
  );
};
