import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { darkGrey2, lightBlueWithAlpha } from '../../../common/colors';

export const StyledLFAPopupRow = styled.button<{ color?: string }>`
  display: block;
  width: 100%;
  margin: ${rem(4)} 0 0 0;
  padding: 0 0 0 ${rem(40)};
  border: none;
  font-size: ${rem(16)};
  font-family: inherit;
  font-weight: 500;
  line-height: ${rem(46)}
  text-align: left;
  background: transparent;
  color: ${({ color }) => color || darkGrey2};
  cursor: pointer;

  &:first-of-type {
    margin-top: ${rem(10)};
  }

  &:last-of-type {
    margin-bottom: ${rem(10)};
  }

  &:hover,
  &:focus {
    background-color: ${lightBlueWithAlpha};
  }

  ${mediaQueryWidth({ min: 'mobile' })`
    padding: 0 0 0 ${rem(60)};
  `}
`;
