import { useEffect, useState } from 'react';
import { getCountryOrMarketCode } from '../../../common/helpers/countrySelection';
import { useGet } from '../../../common/hooks/useGet';
import { TICKETING_SERVICE_BASEURL, TICKETING_USE_FIXTURES } from '../../../config/browser';

// tslint:disable-next-line:no-var-requires
const fixtureData = require('../../../../tests/fixtures/ticketing/useCitySearch.json').data;

const BASE_URL = `${TICKETING_SERVICE_BASEURL}/graphql?query=`;

export const useCitySearch = (searchFor: string, useFixture: boolean = TICKETING_USE_FIXTURES): any => {
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    if (useFixture) {
      return;
    }

    const countryCode = getCountryOrMarketCode();
    const handler = setTimeout(() => {
      if (searchFor.trim().length < 3) {
        setUrl('');
        return;
      }

      const graphql = `
        {
          query:findCities(name: "${searchFor}", size: 5, countryCode: "${countryCode}") {
            name
            state
            coords {
              lat
              lng
            }
            postalCode
          }
        }`;

      setUrl(encodeURI(`${BASE_URL}${graphql}`));
    }, 500);

    return () => clearTimeout(handler);
  }, [searchFor]);

  const { data, error, loading } = useGet(url, { query: [] }, false, false, 1);

  if (useFixture) {
    return { data: fixtureData, loading: false };
  }

  return { error, loading, data: data.data?.query || null };
};
