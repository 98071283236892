import { black, blue, grey40, grey80, lightBlue20, white } from '../../common/colors';

export interface TheaterButtonTheme {
  backgroundColor: string;
  borderColor: string;
  color: string;
  focus?: {
    color: string;
    backgroundColor: string;
    borderColor: string;
  };
  hover?: {
    color: string;
    backgroundColor: string;
    borderColor: string;
  };
  active?: {
    backgroundColor: string;
    borderColor: string;
    color: string;
  };
}

export interface TheaterButtonIconTheme {
  backgroundColor: string;
  color: string;
  focus?: {
    color: string;
    backgroundColor: string;
  };
  hover?: {
    color: string;
    backgroundColor: string;
  };
  active?: {
    backgroundColor: string;
    color: string;
  };
}

export interface TheaterTextTheme {
  borderColor: string;
  color: string;
  hover?: {
    borderColor: string;
    color: string;
  };
}

export interface TheaterResultTheme {
  backgroundColor: string;
  color: string;
  showtimeButton: TheaterButtonTheme;
  directionsButton: TheaterButtonTheme;
  showtimeButtonIcon: TheaterButtonIconTheme;
}

export interface TheaterResultsTheme {
  backgroundColor: string;
  dividerColor: string;
  loadButton: TheaterButtonTheme;
  mapButton: TheaterButtonIconTheme;
  rowFocused: TheaterResultTheme;
  rowNeutral: TheaterResultTheme;
}

export interface MovieDatesTheme {
  backgroundColor: string;
  highlightColor: string;
}

export interface FiltersTheme {
  highlightColor: string;
}

export interface MapThemeStyler {
  color?: string;
  visibility?: string;
}

export interface MapTheme {
  elementType?: string;
  featureType?: string;
  stylers: MapThemeStyler[];
}

export interface MapMarker {
  backgroundColor: string;
  borderColor: string;
}

export interface MapControls {
  reloadButton: TheaterButtonTheme;
  marker: MapMarker;
  markerFocused: MapMarker;
}

export interface TicketingTheme {
  dates: MovieDatesTheme;
  filters?: FiltersTheme;
  results: TheaterResultsTheme;
  mapControls: MapControls;
  map: MapTheme[];
}

export const generateTheme = (primary: string, secondary: string): TicketingTheme => {
  return {
    dates: {
      backgroundColor: 'transparent',
      highlightColor: primary,
    },
    filters: {
      highlightColor: secondary,
    },
    results: {
      backgroundColor: primary,
      dividerColor: 'rgba(255, 255, 255, 0.4)',
      loadButton: {
        backgroundColor: 'transparent',
        borderColor: white,
        color: white,
        hover: {
          backgroundColor: white,
          borderColor: white,
          color: black,
        },
      },
      mapButton: {
        backgroundColor: white,
        color: primary,
        hover: {
          backgroundColor: primary,
          color: white,
        },
        active: {
          backgroundColor: primary,
          color: white,
        },
      },
      rowFocused: {
        backgroundColor: secondary,
        color: white,
        showtimeButton: {
          backgroundColor: 'transparent',
          borderColor: white,
          color: white,
          focus: {
            backgroundColor: 'transparent',
            borderColor: lightBlue20,
            color: white,
          },
          hover: {
            backgroundColor: white,
            borderColor: white,
            color: black,
          },
          active: {
            backgroundColor: black,
            borderColor: black,
            color: white,
          },
        },
        directionsButton: {
          backgroundColor: 'transparent',
          borderColor: white,
          color: white,
          focus: {
            backgroundColor: 'transparent',
            borderColor: lightBlue20,
            color: white,
          },
          hover: {
            backgroundColor: white,
            borderColor: white,
            color: black,
          },
          active: {
            backgroundColor: white,
            borderColor: white,
            color: black,
          },
        },
        showtimeButtonIcon: {
          backgroundColor: 'rgba(255, 255, 255, 0.20)',
          color: white,
          focus: {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            color: white,
          },
          hover: {
            backgroundColor: 'rgba(0, 0, 0, 0.20)',
            color: black,
          },
          active: {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            color: white,
          },
        },
      },
      rowNeutral: {
        backgroundColor: primary,
        color: white,
        showtimeButton: {
          backgroundColor: 'transparent',
          borderColor: white,
          color: white,
          focus: {
            backgroundColor: 'transparent',
            borderColor: lightBlue20,
            color: white,
          },
          hover: {
            backgroundColor: white,
            borderColor: white,
            color: black,
          },
          active: {
            backgroundColor: black,
            borderColor: black,
            color: white,
          },
        },
        directionsButton: {
          backgroundColor: 'transparent',
          borderColor: white,
          color: white,
          focus: {
            backgroundColor: 'transparent',
            borderColor: lightBlue20,
            color: white,
          },
          hover: {
            backgroundColor: white,
            borderColor: white,
            color: black,
          },
          active: {
            backgroundColor: white,
            borderColor: white,
            color: black,
          },
        },
        showtimeButtonIcon: {
          backgroundColor: 'rgba(255, 255, 255, 0.20)',
          color: white,
          focus: {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
            color: white,
          },
          hover: {
            backgroundColor: 'rgba(0, 0, 0, 0.20)',
            color: white,
          },
          active: {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: white,
          },
        },
      },
    },
    mapControls: {
      reloadButton: {
        backgroundColor: secondary,
        borderColor: white,
        color: white,
      },
      marker: {
        backgroundColor: grey40,
        borderColor: grey40,
      },
      markerFocused: {
        backgroundColor: secondary,
        borderColor: white,
      },
    },
    map: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#212121',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#212121',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#181818',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#1b1b1b',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: primary,
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#8a8a8a',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
          {
            color: '#373737',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: secondary,
          },
        ],
      },
      {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [
          {
            color: '#4e4e4e',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#000000',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#3d3d3d',
          },
        ],
      },
    ],
  };
};

export const defaultTicketingTheme = generateTheme(grey80, blue);

export const getTheaterResultTheme = (theme: TicketingTheme, hasFocus?: boolean): TheaterResultTheme =>
  hasFocus ? theme.results.rowFocused : theme.results.rowNeutral;
