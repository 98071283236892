import { momentDate } from '../../../common/helpers/date';
import { IS_PRODUCTION, TICKETING_LAT, TICKETING_LNG } from '../../../config/browser';
import { ShowTime } from './useShowTimes';

export interface Coordinate {
  lat: number;
  lng: number;
}

export interface City {
  name: string;
  state: string;
  coords: {
    lat: number;
    lng: number;
  };
  postalCode: string;
  countryCode: string;
}

export interface GeoPosition {
  coords: {
    latitude: number;
    longitude: number;
  };
}

export interface MapPosition {
  lat: number;
  lng: number;
}

export declare type FilterChangeAction = 'select-all' | 'deselect-all';

export const buildCoords = (
  position?: GeoPosition,
  useGeoPosition: boolean = false,
  mapPosition?: MapPosition,
  city?: City,
): Coordinate | undefined => {
  if (!IS_PRODUCTION && TICKETING_LAT && TICKETING_LNG) {
    return { lat: Number(TICKETING_LAT), lng: Number(TICKETING_LNG) };
  }

  if (position && useGeoPosition) {
    return { lat: Number(position?.coords?.latitude.toFixed(2)), lng: Number(position?.coords?.longitude.toFixed(2)) };
  }

  if (mapPosition) {
    return mapPosition;
  }

  if (city) {
    return { lat: Number(city?.coords?.lat.toFixed(2)), lng: Number(city?.coords?.lng.toFixed(2)) };
  }

  return undefined;
};

export const cityName = (city: City): string => {
  if (!city) return '';

  return [city.name, city.state]
    .filter(Boolean)
    .join(', ')
    .trim();
};

const imagePath = (filename: string): string => `https://d2zqulwfed01s4.cloudfront.net/external/black/${filename}`;

export const getVendorImageSrc = (fileName: string): string => imagePath(fileName);

export const getTheaterImageSrc = (fileName: string): string => imagePath(fileName);

export const momentDateForShowTime = (date: string, showTime: ShowTime) =>
  momentDate(`${date} ${showTime.time}`, undefined, undefined, 'YYYY-MM-DD HH:mm');
