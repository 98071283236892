import * as React from 'react';
import { useRef } from 'react';
import DownArrowBoldIcon from '../../../assets/images/icons/svg/down-arrow-bold.svg';
import GetTicketsIcon from '../../../assets/images/icons/svg/get-tickets.svg';
import { usePopup } from '../../../common/hooks/usePopup';
import { useTranslations } from '../../../common/translation';
import translations from '../translations';
import { GetTicketsDropdownProps, TicketProps } from './GetTicketsDropdown.props';
import {
  StyledButtonText,
  StyledDropdownArrow,
  StyledDropdownButton,
  StyledDropdownContents,
  StyledDropdownIcon,
  StyledDropdownWrapper,
  StyledImg,
  StyledLink,
  StyledText,
} from './GetTicketsDropdown.styles';

export const GetTicketsDropdown = ({ tickets }: GetTicketsDropdownProps) => {
  const { t } = useTranslations(translations);
  const getTicketsText = t('getTicketsText', 'Get Tickets');

  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const inputRef: React.RefObject<HTMLDivElement> = useRef(null);

  const { onPopupToggle, popupVisible } = usePopup(containerRef, inputRef);
  const hasDropdown = tickets.length > 1;
  const renderDropdown = () => {
    return (
      <>
        <StyledDropdownButton onClick={onPopupToggle} innerRef={inputRef}>
          <StyledDropdownIcon>
            <GetTicketsIcon />
          </StyledDropdownIcon>
          <span>{getTicketsText}</span>
          <StyledDropdownArrow isOpen={popupVisible}>
            <DownArrowBoldIcon />
          </StyledDropdownArrow>
        </StyledDropdownButton>
        <StyledDropdownContents visible={popupVisible}>
          {tickets.map(({ image, url }: TicketProps) => (
            <StyledLink key={url} href={url} target='_blank'>
              <StyledImg alt={image.alt} src={image.src} />
              <StyledText>{image.alt}</StyledText>
            </StyledLink>
          ))}
        </StyledDropdownContents>
      </>
    );
  };
  const renderLink = () => {
    return (
      <StyledDropdownButton href={tickets[0].url} target='_blank' innerRef={inputRef}>
        <StyledDropdownIcon>
          <GetTicketsIcon />
        </StyledDropdownIcon>
        <StyledButtonText>{getTicketsText}</StyledButtonText>
      </StyledDropdownButton>
    );
  };
  if (!tickets.length) {
    return null;
  }
  return (
    <StyledDropdownWrapper ref={containerRef}>{hasDropdown ? renderDropdown() : renderLink()}</StyledDropdownWrapper>
  );
};
