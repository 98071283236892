import Popper from '@material-ui/core/Popper';
import { rem } from 'polished';
import styled from 'styled-components';
import { hoverMediaQuery, mediaQueryWidth, noHoverMediaQuery } from '../../common/breakpoints';
import { blue, darkGrey2, grey1, grey2, lightBlue, white } from '../../common/colors';
import { totalNavigationPxHeight } from '../navigation/Navigation.styles';

const shadowColor = 'rgba(0, 0, 0, 0.2)';
const shadowOffset = '2';
const shadowBlur = '3px';

export const subnavHeightPx = 40;
const arrowPointWidthPx = 15;
const breadcrumbLeftPaddingPx = 20;
const breadcrumbGapPx = 5;

export const PopperStyled = styled(Popper)`
  max-width: 300px;
  z-index: 2;
  top: 0;
  background: transparent;

  ${mediaQueryWidth({ min: 'tablet' })`
    width: 300px;
  `}

  .popperContainer {
    background: transparent;
    border: none;
    box-shadow: -${shadowOffset}px -${shadowOffset}px ${shadowBlur} ${shadowColor},
      ${shadowOffset}px -${shadowOffset}px ${shadowBlur} ${shadowColor},
      -${shadowOffset}px ${shadowOffset}px ${shadowBlur} ${shadowColor},
      ${shadowOffset}px ${shadowOffset}px ${shadowBlur} ${shadowColor};
  }
`;

export const StyledBreadcrumbs = styled.div`
  display: flex;
  min-width: 0;

  & > * {
    min-width: 0;
  }

  & > :not(:last-child) {
    flex-shrink: 0;
  }
`;

export const StyledSubnavLeft = styled.div<{ count?: number; hasLink?: boolean; index?: number }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: ${arrowPointWidthPx + breadcrumbGapPx}px;
  font-weight: 800;
  background-color: ${white};
  color: ${({ hasLink }) => (hasLink ? blue : grey1)};

  :after {
    content: '';
    display: block;
    position: relative;
    height: 0;
    padding: 0;
    left: ${arrowPointWidthPx}px;
    border-top: ${subnavHeightPx / 2}px solid transparent;
    border-bottom: ${subnavHeightPx / 2}px solid transparent;
    border-left: ${arrowPointWidthPx}px solid ${white};
  }

  ${({ count = 0, index = 0 }) => {
    const zIndexStyle = count > 0 ? `z-index: ${count - index};` : '';
    const shapeStyle =
      index > 0
        ? `
          padding: 0 0 0 ${arrowPointWidthPx + breadcrumbLeftPaddingPx}px;
          margin-left: ${-1 * arrowPointWidthPx}px;

          :before {
            content: '';
            border-top: ${subnavHeightPx / 2}px solid transparent;
            border-bottom: ${subnavHeightPx / 2}px solid transparent;
            border-left: ${arrowPointWidthPx}px solid ${grey1};
            top: 0;
            left: 0;
            position: absolute;
          }
        `
        : `padding: 0 0 0 ${breadcrumbLeftPaddingPx}px;`;
    return zIndexStyle + shapeStyle;
  }}

  ${({ hasLink }) =>
    hasLink &&
    `
    ${hoverMediaQuery} {
      &:hover {
        background-color: ${blue};
        color: ${white};

        :after {
          border-left-color: ${blue};
        }
      }
    }

    ${noHoverMediaQuery} {
      &:active {
        background-color: ${blue};
        color: ${white};

        :after {
          border-left-color: ${blue};
        }
      }
    }
  `}

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledSubnavDropdown = styled.section`
  .subnavList {
    background-color: ${white};
    box-shadow: none;
    color: ${grey2};
    padding-top: 2px;
    padding-bottom: 2px;

    div.subnavOption {
      padding-right: 3px;

      ul {
        padding: 0;
        overflow: auto;
        max-height: 268px;

        ${mediaQueryWidth({ min: 'tablet' })`
          max-height: 278px;
        `}

        ::-webkit-scrollbar {
          width: 5px;
          background-color: ${white};
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -moz-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
          -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
          box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
          background-color: ${grey2};
        }

        .subnavNestedOption {
          padding: 5px 0 5px 5px;
          max-width: 285px;

          :hover {
            background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
          }

          .subnavOptionLink {
            display: block;
            width: 100%;

            .subnavNestedText {
              padding-left: 20px !important;
              padding-right: 0 !important;

              & span {
                font-family: Montserrat, Arial, Helvetica, sans-serif;
                line-height: normal;
              }
            }
          }
        }
      }

      .subnavOptionLi {
        padding: 7px;

        :hover {
          background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
        }

        .subnavText {
          padding-left: 5px;
        }
      }

      .selected,
      & .selected span {
        color: ${lightBlue};
      }

      svg {
        height: 0.7rem;
        width: 0.7rem;
      }

      .subnavOptionLink {
        width: 100%;
      }
    }

    span {
      color: ${grey2};
      font-size: 14px;
      font-family: Montserrat, Arial, Helvetica, sans-serif;
      line-height: normal;

      ${mediaQueryWidth({ min: 'tablet' })`
        font-size: 15px;
      `}
    }
  }
`;

export const StyledSubnavContainer = styled.div<{ fixed?: boolean }>`
  position: sticky;
  ${({ fixed }) =>
    fixed &&
    `
      position: fixed;
      left: 0;
      right: 0;
    `}
  z-index: 3;
  top: ${rem(totalNavigationPxHeight)};
  height: ${subnavHeightPx}px;
`;

export const StyledSubnav = styled.nav`
  height: ${subnavHeightPx}px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${darkGrey2};
  font-size: 1rem;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  line-height: normal;
  background-color: ${darkGrey2};

  .subnavRight {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    justify-content: flex-end;
    background: ${darkGrey2};
    color: ${white};
    line-height: normal;
    margin-right: 5px;

    ${mediaQueryWidth({ min: 'tablet' })`
      margin-right: 40px;
      margin-left: ${subnavHeightPx / 2}px
    `}

    .subnavDropdown {
      height: 100%;

      button.popperTrigger {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        padding: 0.657rem;
        font-size: 1rem;
        max-width: 300px;
        border: none;
        background: transparent;
        color: ${white};

        ${mediaQueryWidth({ min: 'tablet' })`
          cursor: pointer;
          width: 300px;
          max-width: 300px;
        `}

        :active, :focus {
          border: none;
        }

        span.executiveText {
          text-transform: capitalize;

          ${mediaQueryWidth({ min: 'tablet' })`
            font-weight: bold;
          `}
        }

        svg {
          height: 0.7rem;
          width: 0.7rem;
          padding-left: 5px;

          & .selected {
            fill: ${lightBlue};
          }
        }
      }
    }

    ul.SubnavVertical {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;

      li a {
        padding: 10.5px;
        font-weight: 800;
        text-decoration: none;
        color: ${white};
      }
    }
  }
`;
