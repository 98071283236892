import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { darkGrey2, greyWithAlpha } from '../../../common/colors';

export const StyledCardTitleHeader = styled.header`
  margin: 0 0 ${rem(28)} 0;
  padding: 0 0 0 ${rem(22)};
  border-left: 0.1rem solid ${greyWithAlpha};
  color: ${darkGrey2};
`;

export const StyledCardTitle = styled.div`
  margin: 0;
  font-size: ${rem(26)};
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(35)};
  `}
`;

export const StyledCardSubtitle = styled.div`
  margin: ${rem(16)} 0 0 0;
  font-size: ${rem(16)};

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(20)};
  `}
`;
