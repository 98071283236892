import axios from 'axios';
import { useEffect, useState } from 'react';
import { logToSentry } from '../../components/catch/Catch';
import { getLastBuildTimeStamp } from '../d7/common';

export const useGet = (
  url: string,
  initialValue: any,
  includeTimestamp: boolean = false,
  shouldThrowError: boolean = true,
  retries: number = 0,
) => {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState(initialValue || null);
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  let unmounted = false;

  const doGet = () => {
    (async () => {
      try {
        setLoading(true);
        let requestUrl;
        if (includeTimestamp) {
          const timestamp = await getLastBuildTimeStamp();
          const uriSymbol = url.includes('?') ? '&' : '?';
          requestUrl = `${url}${uriSymbol}timestamp=${timestamp}`;
        } else {
          requestUrl = url;
        }
        const response = await axios.get(requestUrl);
        if (!unmounted) {
          setData(await response.data);
          setLoading(false);
        }
      } catch (e) {
        if (retryCount < retries) {
          setTimeout(() => {
            setRetryCount(retryCount + 1); // This will trigger doGet via useEffect hook below
          }, 1000);
        } else {
          setError(e);
          setLoading(false);
        }
      }
    })();
  };

  useEffect(() => {
    if (url) {
      doGet();
    } else {
      setLoading(false);
      setData(initialValue || null);
    }

    return () => {
      unmounted = true;
    };
  }, [retryCount, url]); // Re-run the useEffect when URL changes, or when we decide to retry.

  if (error) {
    if (shouldThrowError) {
      throw error;
    } else {
      logToSentry(error);
    }
  }

  return { data, error, loading };
};
