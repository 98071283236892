import * as React from 'react';
import { useState } from 'react';
import DistanceSignIcon from '../../../../assets/images/icons/svg/distance-sign.svg';
import { truncate } from '../../../../common/helpers/strings';
import { logToSentry } from '../../../catch/Catch';
import { ImgWithFallback } from '../../../img-with-fallback/ImgWithFallback';
import { useAnalytics, useTheme } from '../../contexts';
import { getTheaterImageSrc } from '../../hooks/utils';
import { getTheaterResultTheme } from '../../TicketingWidget.theme';
import { TheaterLocationProps } from './TheaterLocation.props';
import {
  StyledTheaterImg,
  StyledTheaterImgFallback,
  StyledTheaterInitial,
  StyledTheaterLocation,
  StyledTLAddress,
  StyledTLAddressDistance,
  StyledTLDistance,
  StyledTLImageContainer,
} from './TheaterLocation.styles';

const toDistanceString = (distance: { unit: string; value: string }) => {
  const distanceValue = distance.value || '?';
  const distanceUnit = distance.unit?.toLowerCase() === 'mile' ? 'mi' : 'km';

  return `${distanceValue} ${distanceUnit}`;
};

const theaterInitial = (title: string): string => {
  return title.charAt(0);
};

export const TheaterLocation = ({
  hasFocus,
  theater: { address, city, distance, id, image, name, state },
}: TheaterLocationProps) => {
  const analytics = useAnalytics();
  const [showVendorImage, setShowVendorImage] = useState(true);
  const { theme: ticketingTheme } = useTheme();
  const theme = getTheaterResultTheme(ticketingTheme, hasFocus);
  const destination = [name, address, city, state].filter(Boolean).join(', ');
  const googleUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(destination)}`;

  const displayName = truncate(name, 80);
  const addressAndCity = truncate([address, city, state].filter(Boolean).join(', '), 100);
  const theaterImageSrc = getTheaterImageSrc(image);

  const logVendorImageError = () => {
    setShowVendorImage(false);
    logToSentry(new Error(`Failed to load vendor ticketing image: ${theaterImageSrc}`));
  };

  const handleDirectionsClick = () => {
    analytics?.sendDirectionsButtonClick(id);
  };

  return (
    <StyledTheaterLocation>
      <StyledTLImageContainer>
        {showVendorImage ? (
          <StyledTheaterImg>
            <ImgWithFallback alt={name} onError={logVendorImageError} src={theaterImageSrc} />
          </StyledTheaterImg>
        ) : (
          <StyledTheaterImgFallback>
            <StyledTheaterInitial>{theaterInitial(name)}</StyledTheaterInitial>
          </StyledTheaterImgFallback>
        )}
      </StyledTLImageContainer>
      <StyledTLAddressDistance>
        <StyledTLAddress>
          <div>{displayName}</div>
          <div>{addressAndCity}</div>
        </StyledTLAddress>
        <StyledTLDistance
          href={googleUrl}
          onClick={handleDirectionsClick}
          theme={theme.directionsButton}
          target='_blank'
        >
          <DistanceSignIcon />
          <span>{toDistanceString(distance)}</span>
        </StyledTLDistance>
      </StyledTLAddressDistance>
    </StyledTheaterLocation>
  );
};
