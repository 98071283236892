import styled from 'styled-components';
import { grey60, grey90, lightBlue20 } from '../../common/colors';
import { blurredBackgroundImageCss } from '../../common/helpers/image';
import { StyledSliderContainer } from '../../common/slider.styles';
import { Img } from '../img/Img';

export const StyledImageCarousel = styled.div`
  background-color: ${grey90};
`;

export const StyledBackgroundImage = styled.div<{ add3dTransform?: boolean; image: Image }>`
  display: none;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  background-position: center center;
  overflow: hidden;
  ${({ add3dTransform, image }) => blurredBackgroundImageCss(image, add3dTransform)}
`;

export const StyledFullImage = styled(Img)`
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  margin: auto;
  max-height: 450px;

  @media (max-height: 575px) {
    max-height: 250px;
  }
`;

export const StyledFullImageSliderContainer = styled(StyledSliderContainer)`
  border-bottom: 1px solid ${grey60};

  .slick-track {
    display: flex;
    align-items: center;
    cursor: move; // Fallback for IE and some mobile browsers
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    .slick-slide {
      float: none;

      &.slick-active ${StyledBackgroundImage} {
        display: block;
      }

      *:focus {
        outline: none;
      }
    }
  }
`;

export const StyledThumbnailImage = styled(Img)`
  max-height: 156px;
  height: auto;
  width: auto;
  padding: 3px;
  cursor: pointer;
  transition: transform 0.4s ease;

  &:hover {
    transform: scale(1.08);
  }

  @media (min-height: 725px) and (max-height: 900px) {
    max-height: 106px;
  }

  @media (min-height: 575px) and (max-height: 725px) {
    max-height: 86px;
  }

  @media (max-height: 575px) {
    max-height: 56px;
  }
`;

export const StyledThumbnailImageContainer = styled.div`
  overflow: hidden;
`;

export const StyledThumbnailSliderContainer = styled(StyledSliderContainer)`
  .slick-track {
    display: flex;
    align-items: center;
    max-height: 210px;

    .slick-slide {
      overflow: hidden;

      *:focus {
        outline: none;
      }

      & > div {
        margin: 8px 8px 8px 0;
        border: 4px solid transparent;
      }

      &.slick-current > div {
        border-color: ${lightBlue20};
      }
    }
  }
`;
