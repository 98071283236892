import { rem } from 'polished';
import styled from 'styled-components';
import { hoverMediaQuery, noHoverMediaQuery } from '../../../common/breakpoints';
import { grey80, white } from '../../../common/colors';
import { Button } from '../../button/Button';
import { buttonStateStyles } from '../ticketing.styles';
import { TheaterButtonIconTheme, TheaterButtonTheme, TheaterResultsTheme } from '../TicketingWidget.theme';

const estMinTheaterResultHeightPx = 238;

export const StyledTRMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${rem(estMinTheaterResultHeightPx)};
  background-color: ${grey80};
  font-weight: 700;
  text-transform: uppercase;
  color: ${white};

  p {
    margin: 0;
    text-align: center;
  }
`;

export const StyledTheaterResultsWrapper = styled.div`
  display: flex;
`;

export const StyledTheaterResults = styled.div<{ allowScroll?: boolean; maxHeight?: number }>`
  position: relative;
  flex: 1;
  ${({ maxHeight }) => typeof maxHeight !== 'undefined' && maxHeight > 0 && `max-height: ${rem(maxHeight)};`}
  ${({ allowScroll }) => allowScroll && 'overflow-y: scroll;'}
`;

export const StyledTheaterMapButton = styled.button<{ theme: TheaterButtonIconTheme; width?: number }>`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
  border: none;
  padding: 0;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: 100%;
  max-height: ${rem(234)};
  background-color: transparent;
  color: ${({ theme }) => theme.color || 'inherit'};
  cursor: pointer;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.backgroundColor || 'inherit'};
  }

  ${({ theme }) =>
    theme.hover &&
    `
      ${hoverMediaQuery} {
        &:hover {
          color: ${theme.hover.color || 'inherit'};

          & > svg {
            color: ${theme.hover.backgroundColor || 'inherit'};
          }
        }
      }
    `}

  ${({ theme }) =>
    theme.active &&
    `
      ${noHoverMediaQuery} {
        &:active {
          color: ${theme.active.color || 'inherit'};

          & > svg {
            color: ${theme.active.backgroundColor || 'inherit'};
          }
        }
      }
    `}
`;

export const StyledTheaterMapButtonTextContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: calc(100% - ${rem(32)});
  color: inherit;

  & > svg {
    flex-shrink: 0;
    color: inherit;
  }
`;

export const StyledTheaterMapButtonText = styled.span`
  transform: rotate(-180deg);
  margin-top: ${rem(8)};
  padding: 0 ${rem(10)};
  font-size: ${rem(14)};
  font-weight: 700;
  line-height: ${rem(20)};
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl; // Deprecated, for IE use
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTheaterMapWrapper = styled.div<{ isOpen?: boolean }>`
  position: relative;
  z-index: 1;
  width: ${({ isOpen }) => (isOpen ? '30%' : '0')};
  transition: width 0.5s ease;
`;

export const StyledTRInner = styled.div<{ theme: TheaterResultsTheme }>`
  padding: ${rem(10)};
  background-color: ${({ theme }) => theme.backgroundColor};

  & > hr {
    margin: 0;
    border-top: 0;
    border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  }
`;

export const StyledTRButton = styled(Button)<{ theme: TheaterButtonTheme }>`
  /* Increase specificity to override inherited styles */
  &&& {
    display: flex;
    margin: ${rem(20)} auto ${rem(20)} auto;
    color: ${({ theme }) => theme.color};
    background-color: ${({ theme }) => theme.backgroundColor};
    border-color: ${({ theme }) => theme.borderColor};

    ${buttonStateStyles}
  }
`;
