import { rem } from 'polished';
import styled from 'styled-components';
import { black, grey80, lightGrey1 } from '../../../../common/colors';

export const StyledMDDate = styled.button<{
  backgroundColor?: string;
  current?: boolean;
  enabled?: boolean;
  highlightColor?: string;
  onClick?: any;
  tabIndex?: any;
}>`
  display: block;
  width: 100%;
  font-size: ${rem(16)};
  margin: 0;
  padding: ${rem(14)} ${rem(10)} ${rem(11)};
  border: none;
  background-color: ${({ backgroundColor, current, highlightColor }) =>
    current ? highlightColor || grey80 : backgroundColor || black};
  color: ${({ enabled }) => (enabled ? 'inherit' : lightGrey1)};
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${({ highlightColor }) => highlightColor || grey80};
  }

  &:active {
    outline: none;
  }
`;

export const StyledMDDayName = styled.div`
  font-weight: bold;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  white-space: nowrap;
`;

export const StyledMDDayOfMonth = styled.div`
  font-size: ${rem(12)};
  line-height: ${rem(15)};
  white-space: nowrap;
`;
