import * as React from 'react';

type GenericKeyboardEvent = React.KeyboardEvent | KeyboardEvent;

export const isEnterKey = (e: GenericKeyboardEvent) => e.key === 'Enter';

export const isUpKey = (e: GenericKeyboardEvent) => ['ArrowUp', 'Up'].includes(e.key);

export const isDownKey = (e: GenericKeyboardEvent) => ['ArrowDown', 'Down'].includes(e.key);

export const isTabForwardKey = (e: GenericKeyboardEvent) => e.key === 'Tab' && !e.shiftKey;

export const isTabBackwardKey = (e: GenericKeyboardEvent) => e.key === 'Tab' && e.shiftKey;

export const isTabKey = (e: GenericKeyboardEvent) => e.key === 'Tab';

export const isEscapeKey = (e: GenericKeyboardEvent) => e.key === 'Escape';

export const isRemoveFocusKey = (e: GenericKeyboardEvent) => ['Tab', 'Escape'].includes(e.key);

export const isSpacebarKey = (e: GenericKeyboardEvent) => e.key === ' ';
