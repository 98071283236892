import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../../common/breakpoints';
import { white } from '../../../../common/colors';
import { buttonStateStyles } from '../../ticketing.styles';
import { TheaterButtonIconTheme, TheaterButtonTheme } from '../../TicketingWidget.theme';

export const StyledTheaterShowtimes = styled.div`
  margin: ${rem(14)} 0 0 0;

  ${mediaQueryWidth({ min: 'mobile' })`
    margin: 0 0 0 ${rem(20)};
  `}
`;

export const StyledTSFormat = styled.div`
  margin: ${rem(2)} 0 0 0;
  padding: 0;
  font-size: ${rem(10)};
  line-height: ${rem(10)};
  opacity: 0.7;

  ${mediaQueryWidth({ min: 'mobile' })`
    font-size: ${rem(12)};
    line-height: ${rem(12)};
  `};
`;

export const StyledTSShowTimeContainer = styled.div`
  flex: 1 1 auto;
  padding: ${rem(10)};
  cursor: pointer;

  ${mediaQueryWidth({ min: 'mobile' })`
    padding: ${rem(10)} ${rem(20)};
  `}
`;

export const StyledTSShowTime = styled.div`
  line-height: ${rem(16)};
  font-size: ${rem(12)};

  ${mediaQueryWidth({ min: 'mobile' })`
    font-size: ${rem(16)};
    line-height: ${rem(20)};
  `}
`;

export const StyledTSMoonWrapper = styled.div<{ theme: TheaterButtonTheme }>`
  flex: 1 1 auto;
  padding: ${rem(14)} ${rem(10)};
  cursor: pointer;
  color: ${({ theme }) => theme.color};
  background-color: ${({ theme }) => theme.backgroundColor};

  ${mediaQueryWidth({ min: 'mobile' })`
    font-size: ${rem(16)};
    padding: ${rem(18)} ${rem(18)};
  `}

  & > svg {
    display: inline-block;
    width: ${rem(16)};
    height: ${rem(19)};
  }
`;

export const StyledTSTimeOfDay = styled.button<{ theme: TheaterButtonTheme; moonTheme: TheaterButtonIconTheme }>`
  display: inline-flex;
  align-items: stretch;
  margin-right: ${rem(10)};
  margin-bottom: ${rem(10)};
  padding: 0;
  border: ${rem(1)} solid ${({ theme }) => theme.borderColor};
  font-size: ${rem(14)};
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.color};
  background-color: ${({ theme }) => theme.backgroundColor};
  cursor: pointer;

  ${buttonStateStyles}
`;

export const StyledTSVendorLinkContainer = styled.div`
  margin-top: ${rem(10)};

  ${mediaQueryWidth({ min: 'mobile' })`
    margin-top: ${rem(20)};
  `}
`;

export const StyledTSVendorLinkMessage = styled.div`
  ${mediaQueryWidth({ min: 'mobile' })`
    margin-bottom: ${rem(10)};
  `}
`;

export const StyledTSVendorLinkInnerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${rem(96)}, 1fr));
  gap: ${rem(10)} ${rem(10)};
  width: 100%;

  ${mediaQueryWidth({ min: 'mobile' })`
    grid-template-columns: repeat(auto-fill, minmax(${rem(160)}, 1fr));
    gap: ${rem(20)} ${rem(20)};
  `}
`;

export const StyledTSVendorLink = styled.a`
  display: inline-block;
  height: ${rem(42)};
  overflow: hidden;
  line-height: 0;
  background-color: ${white};

  img {
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    max-width: none;
    max-height: 100%;
  }

  ${mediaQueryWidth({ min: 'mobile' })`
    height: ${rem(72)};
  `}
`;
