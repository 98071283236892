import { rem } from 'polished';
import styled from 'styled-components';
import { hoverMediaQuery, mediaQueryWidth, noHoverMediaQuery } from '../../common/breakpoints';
import { black, hexToRgb, white } from '../../common/colors';
import { sidePaddingStyles } from '../../common/padding.styles';
import { Button } from '../button/Button';

const shadowColor = 'rgba(0, 0, 0, 0.5)';
const shadowOffset = '1';
const shadowBlur = '3px';

export const DisplayTitle = styled.div`
  -webkit-margin-before: unset;
  -webkit-margin-after: unset;
  font-size: ${rem(30)};
  font-weight: 800;
  text-transform: uppercase;
  color: ${white};
  line-height: 1;
  margin: 0;

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(40)};
  `}

  & > a {
    color: ${white};
    cursor: pointer;

    ${noHoverMediaQuery} {
      &:active {
        color: dodgerblue;
      }
    }

    ${hoverMediaQuery} {
      &:hover {
        color: dodgerblue;
      }
    }
  }
`;

export const DisplaySubtitle = styled.div``;

export const MarketingMessage = styled.div`
  margin: 0;
  padding: 10px 0 0 0;
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  font-weight: 400;

  p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const StyledFeaturedTitle = styled.section<{ hide?: boolean }>`
  ${sidePaddingStyles}
  padding-top: ${rem(36)};
  padding-bottom: ${rem(36)};
  color: ${white};
  ${({ hide }) => hide && 'opacity: 0; pointer-events: none;'}

  ${mediaQueryWidth({ min: 'tablet' })`
    padding-bottom: ${rem(48)};
    text-shadow:
      -${shadowOffset}px -${shadowOffset}px ${shadowBlur} ${shadowColor},
      ${shadowOffset}px -${shadowOffset}px ${shadowBlur} ${shadowColor},
      -${shadowOffset}px ${shadowOffset}px ${shadowBlur} ${shadowColor},
      ${shadowOffset}px ${shadowOffset}px ${shadowBlur} ${shadowColor};
    background: transparent linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.8)
    );

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    z-index: 2;
  `}

  header {
    margin: 0;
    padding-left: 22px;
    border-left: 0.1rem;
    border-left-color: rgba(255, 255, 255, 0.4);
    border-left-style: solid;

    ${mediaQueryWidth({ min: 'tablet' })`
      max-width: 800px;
      padding-left: ${rem(44)};
    `}

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      list-style: none;
      position: relative;
      padding: 0;
      margin: 0;

      li {
        padding-top: 15px;
        padding-right: 15px;

        ${mediaQueryWidth({ min: 'mobile' })`
          padding-right: 20px;
        `}

        &.follow:after {
          content: ' :';
        }
      }
    }
  }
`;

export const StyledButton = styled(Button as any)`
  &.MuiButton-root,
  & > .MuiButton-root {
    color: ${black};
    background-color: ${white};
    font-weight: 500;
    font-size: ${rem(16)};
    &:hover,
    &:active {
      background-color: ${hexToRgb(white, 0.85)};
    }
  }
  .MuiButton-label {
    z-index: 1;
  }
  .MuiTouchRipple-root span {
    background-color: ${hexToRgb(white, 0.85)};
  }
` as typeof Button;
