import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { ImgWithFallbackProps } from './ImgWithFallback.props';
import { StyledImg } from './ImgWithFallback.styles';

export const ImgWithFallback = ({ alt, onError, fallback = '', src = '' }: ImgWithFallbackProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [url, setUrl] = useState<string>(src);
  const imgRef = useRef<HTMLImageElement>(null);
  const loadingRef = useRef<number | undefined>();
  const mountedRef = useRef<boolean>(false);

  const imageRendered = () => {
    if (mountedRef.current) {
      setLoaded(true);
    }
  };

  const startRender = () => {
    loadingRef.current = requestAnimationFrame(imageRendered);
  };

  const imageLoaded = () => {
    loadingRef.current = requestAnimationFrame(startRender);
  };

  const handleError = () => {
    if (onError) {
      onError(url);
    }

    if (fallback) {
      setUrl(fallback);
    }
  };

  useEffect(() => {
    mountedRef.current = true;

    const image = imgRef.current;
    if (image && image.complete) {
      imageLoaded();
    }

    return () => {
      mountedRef.current = false;

      if (loadingRef.current) {
        cancelAnimationFrame(loadingRef.current);
      }
    };
  }, []);

  return <StyledImg alt={alt} loaded={loaded} onLoad={imageLoaded} onError={handleError} ref={imgRef} src={url} />;
};
