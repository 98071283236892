import classnames from 'classnames';
import * as _ from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import YouTube from 'react-youtube';
import PlayIcon from '../../assets/images/icons/svg/yt-play.svg';
import { buttonGuideValue } from '../../common/helpers/carousel';
import { determinePreset } from '../../common/helpers/image';
import { UiScreenType } from '../../common/helpers/window';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { imageInViewOptions, lazyLoadClassName } from '../../common/loading';
import { useTranslations } from '../../common/translation';
import { youtubeEmbedStore } from '../../stores/YoutubeEmbedStore';
import { FeaturedTitle } from '../featured-title/FeaturedTitle';
import { BannerVideoProps, FeaturedBannerProps } from './FeaturedBanner.props';
import {
  StyledBannerImg,
  StyledFeaturedBanner,
  StyledFeaturedBannerBackground,
  StyledVideoContainer,
} from './FeaturedBanner.styles';
import translations from './translations';

// tslint:disable-next-line:no-var-requires
const fitvids = require('fitvids');

const getVideoStatus = (video: BannerVideoProps | undefined) => {
  if (!(video && video.bannerVideoId)) {
    return 0;
  }

  return typeof video.bannerVideoStatus !== 'undefined' ? video.bannerVideoStatus : 1;
};

const videoContainerClassName = 'video-container';

export const FeaturedBanner = ({
  bannerClassName,
  headingTag,
  image,
  imageWithPresets,
  lazyLoad,
  titleProps,
  video,
}: FeaturedBannerProps) => {
  const videoStatus = getVideoStatus(video);
  const autoplayOn = videoStatus === 2;

  const [playing, setPlaying] = useState<boolean>(autoplayOn);
  const [videoState, setVideoState] = useState<number>(autoplayOn ? 1 : -1);
  const { uiScreenType } = useWindowResize();
  const { t } = useTranslations(translations);
  const [inViewRef, inView] = useInView(imageInViewOptions);

  const hasVideo = videoStatus > 0;
  const imageToRender = !_.isEmpty(imageWithPresets) ? determinePreset(imageWithPresets!, uiScreenType) : image;
  const youtubeOpts: any = {
    height: '100%',
    width: '100%',
    host: 'https://www.youtube-nocookie.com',
    playerVars: {
      autoplay: 1,
      color: 'red',
      enablejsapi: 1,
      modestbranding: 1,
      mute: autoplayOn,
      rel: 0,
      start: 0,
    },
  };

  const onPlay = (e: any) => {
    youtubeEmbedStore.pause(e.target || null);
    youtubeEmbedStore.setPlayer(e.target || null);
  };
  const onVideoStateChange = (e: any) => {
    if (e.data === 0) {
      setPlaying(false);
    }

    setVideoState(e.data);
  };
  const playVideo = () => setPlaying(true);

  useEffect(() => fitvids(`.${videoContainerClassName}`), []);

  const hideTitle = [UiScreenType.Desktop, UiScreenType.Hd].includes(uiScreenType) && (playing || videoState > 0);

  return (
    <StyledFeaturedBanner data-component='featured-banner' ref={inViewRef}>
      <StyledFeaturedBannerBackground data-carousel={buttonGuideValue}>
        {!playing && (
          <StyledBannerImg
            className={classnames({ [lazyLoadClassName]: lazyLoad && !inView }, bannerClassName)}
            url={imageToRender?.src}
          >
            {hasVideo && videoState <= 0 && (
              <button aria-label={t('ariaPlayButton', 'Play button')} onClick={playVideo}>
                <PlayIcon />
              </button>
            )}
          </StyledBannerImg>
        )}
        {hasVideo && playing && (
          <StyledVideoContainer className={videoContainerClassName}>
            <YouTube
              onPlay={onPlay}
              onStateChange={onVideoStateChange}
              opts={youtubeOpts}
              videoId={video!.bannerVideoId}
            />
          </StyledVideoContainer>
        )}
      </StyledFeaturedBannerBackground>
      <FeaturedTitle headingTag={headingTag} hideTitle={hideTitle} {...titleProps} />
    </StyledFeaturedBanner>
  );
};
