import { css } from 'styled-components';
import { hoverMediaQuery } from '../../common/breakpoints';
import { TheaterButtonIconTheme, TheaterButtonTheme } from './TicketingWidget.theme';

export const moonWrapperClassName = 'moon-wrapper';

export const buttonStateStyles = css<{ moonTheme: TheaterButtonIconTheme; theme: TheaterButtonTheme }>`
  ${({ theme }) =>
    theme?.focus &&
    `
      &:focus {
        outline: none;
        color: ${theme.focus.color};
        background-color: ${theme.focus.backgroundColor};
        border-color: ${theme.focus.borderColor};
      }
    `}

  ${({ theme }) =>
    theme?.hover &&
    `
      ${hoverMediaQuery} {
        &:hover {
          color: ${theme.hover.color};
          background-color: ${theme.hover.backgroundColor};
          border-color: ${theme.hover.borderColor};
        }
      }
    `}

  ${({ theme }) =>
    theme?.active &&
    `
      &:active {
        color: ${theme.active.color};
        background-color: ${theme.active.backgroundColor};
        border-color: ${theme.active.borderColor};
      }
    `}

  ${({ moonTheme }) =>
    moonTheme?.focus &&
    `
      &:focus {
        .${moonWrapperClassName} {
          color: ${moonTheme.focus.color};
          background-color: ${moonTheme.focus.backgroundColor};
        }
      }
    `}

  ${({ moonTheme }) =>
    moonTheme?.hover &&
    `
      ${hoverMediaQuery} {
        &:hover {
          .${moonWrapperClassName} {
            color: ${moonTheme.hover.color};
            background-color: ${moonTheme.hover.backgroundColor};
          }
        }
      }
    `}

  ${({ moonTheme }) =>
    moonTheme?.active &&
    `
      &:active {
        .${moonWrapperClassName} {
          color: ${moonTheme.active.color};
          background-color: ${moonTheme.active.backgroundColor};
        }
      }
    `}
`;
