import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { white } from '../../../common/colors';

const filterSpace = rem(30);

export const StyledTFWrapper = styled.div`
  display: flex;
  margin-top: ${rem(26)};

  ${mediaQueryWidth({ min: 'tablet' })`
    margin-top: 0;
    flex: 1;
    margin-left: ${filterSpace};
  `}
`;

export const StyledTFContainer = styled.div`
  margin-top: 0;
  flex: 1;
  margin-right: ${filterSpace};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const StyledTFHeader = styled.div`
  margin-bottom: ${rem(10)};
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  color: ${white};
`;
