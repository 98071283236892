import * as React from 'react';
import { PurchaseLinkMessageProps } from './PurchaseLinkMessage.props';
import { StyledPurchaseLinkMessage } from './PurchaseLinkMessage.styles';

export const PurchaseLinkMessage = ({ purchaseLinkMessage }: PurchaseLinkMessageProps) => {
  if (!purchaseLinkMessage) {
    return null;
  }

  return <StyledPurchaseLinkMessage dangerouslySetInnerHTML={{ __html: purchaseLinkMessage }} />;
};
