import * as React from 'react';
import { NoticeProps } from './Notice.props';
import { StyledNotice } from './Notice.styles';

export const NoticeMessage = ({ notice }: NoticeProps) => {
  if (!notice) {
    return null;
  }

  return <StyledNotice className='notice-msg' dangerouslySetInnerHTML={{ __html: notice }} />;
};
