import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { darkGrey2, darkGrey3, grey20, lightBlue, white } from '../../common/colors';
import { sidePaddingStyles } from '../../common/padding.styles';

export const defaultCardHeight = 385;

export const StyledCard = styled.section`
  width: 100%;
  height: 100%;
`;

export const CardImageContainer = styled.div`
  margin: 0 0 2.5rem 0;

  ${mediaQueryWidth({ min: 'tablet' })`
    display: inline-block;
    vertical-align: top;
    margin: 0;
    width: 30%;
    min-width: ${defaultCardHeight}px;
    box-sizing: border-box;
  `}

  img {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    width: ${defaultCardHeight}px;
  }
`;

export const CardDetailsContainer = styled.div<{ hasImage?: boolean; useContentPadding?: boolean }>`
  ${({ hasImage }) =>
    hasImage &&
    css`
      ${mediaQueryWidth({ min: 'tablet' })`
        display: inline-block;
        vertical-align: top;
        width: 65%;
        max-width: calc(100% - 385px);
        box-sizing: border-box;
      `}
    `}

  ${({ useContentPadding }) => useContentPadding && sidePaddingStyles}
`;

export const CardButtonPositionContainer = styled.div`
  position: relative;
`;

export const CardButtonWrapper = styled.div<{ isContentExpanded?: boolean; useDarkMode?: boolean }>`
  ${({ isContentExpanded, useDarkMode }) =>
    isContentExpanded
      ? `
        position: relative;
        margin: ${rem(18)} 0 0 0;
      `
      : `
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 4rem 0 0 0;
        background-image: ${
          useDarkMode
            ? `linear-gradient(to bottom, rgba(30, 30, 30, 0), ${darkGrey3})`
            : `linear-gradient(to bottom, rgba(255, 255, 255, 0), ${white})`
        };
      `}
`;

export const CardButton = styled.button`
  width: 100%;
  padding: ${rem(5)};
  border: 0;
  font-size: ${rem(12)};
  font-weight: 800;
  background-color: ${grey20};
  color: ${darkGrey2};
  text-transform: uppercase;
  cursor: pointer;
`;

export const CardContentContainer = styled.div<{ fixedContainerHeight?: number; isContentExpanded?: boolean }>`
  max-height: 18rem;
  overflow: hidden;

  ${({ fixedContainerHeight = 0, isContentExpanded }) => {
    if (isContentExpanded) {
      return 'max-height: none';
    }

    if (fixedContainerHeight > 0) {
      return `
        height: ${fixedContainerHeight}px;
        max-height: ${fixedContainerHeight}px;
      `;
    }

    return css`
      ${mediaQueryWidth({ min: 'tablet' })`
        max-height: ${defaultCardHeight}px;
    `}
    `;
  }}
`;

export const CardContent = styled.div`
  a {
    color: ${lightBlue};
  }

  p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: disc;
    margin-left: ${rem(20)};
  }

  iframe {
    max-width: 100%;
    height: 315px;
    width: 560px;
  }
`;
