import * as React from 'react';
import { useState } from 'react';
import { useDateFormatTranslations, useDateTranslations } from '../../../../common/helpers/date';
import { contentTypeToIcon } from '../../../../common/helpers/icons';
import { truncate } from '../../../../common/helpers/strings';
import { useDebug } from '../../../../common/hooks/useDebug';
import {
  StyledContentContainer,
  StyledDefaultSvgContainer,
  StyledImageContainer,
  StyledTitle,
  StyledTitleContainer,
  StyledVendorLink,
} from '../../../../common/popup.styles';
import { useTranslations } from '../../../../common/translation';
import { logToSentry } from '../../../catch/Catch';
import { ImgWithFallback } from '../../../img-with-fallback/ImgWithFallback';
import { Img } from '../../../img/Img';
import { PopupContainer } from '../../../popup-container/PopupContainer';
import { useAnalytics } from '../../contexts';
import { ShowTimeVendor } from '../../hooks/useShowTimes';
import { getTheaterImageSrc, getVendorImageSrc, momentDateForShowTime } from '../../hooks/utils';
import translations from '../../translations';
import { TheaterVendorsPopupProps } from './TheaterVendorsPopup.props';
import {
  StyledTVPDate,
  StyledTVPFormat,
  StyledTVPImageContainer,
  StyledTVPSelectText,
  StyledTVPTheaterContainer,
  StyledTVPTheaterName,
  StyledTVPVendorFallback,
  StyledTVPVendorImageContainer,
  StyledTVPVendorLinkContainer,
  StyledTVPVendorLinkInnerContainer,
  StyledTVPVendorLinksContainer,
} from './TheaterVendorsPopup.styles';

const contentType: ContentType = 'Movies';

const imageFallback = (): JSX.Element => {
  const ContentTypeIcon = contentTypeToIcon[contentType];

  return (
    <StyledDefaultSvgContainer>
      <ContentTypeIcon />
    </StyledDefaultSvgContainer>
  );
};

interface VendorImageProps {
  title: string;
  image: string;
}

const VendorImage = ({ title, image }: VendorImageProps) => {
  const [showTheaterIcon, setShowTheaterIcon] = useState(true);
  const vendorImageSrc = getVendorImageSrc(image);
  const onVendorImageError = () => {
    logToSentry(new Error(`Failed to load vendor ticketing image: ${vendorImageSrc}`));
    setShowTheaterIcon(false);
  };

  return (
    <StyledTVPVendorImageContainer>
      {showTheaterIcon ? (
        <ImgWithFallback alt={title} src={vendorImageSrc} onError={onVendorImageError} />
      ) : (
        <StyledTVPVendorFallback>{title}</StyledTVPVendorFallback>
      )}
    </StyledTVPVendorImageContainer>
  );
};

export const TheaterVendorsPopup = ({
  date,
  onClose,
  movie: { image, title },
  showTime,
  theater,
}: TheaterVendorsPopupProps) => {
  useDebug('app:cmp:TheaterVendorsPopup', { props: { showTime, theater } });
  const analytics = useAnalytics();
  const { t } = useTranslations(translations);
  const { t: dateTranslation } = useDateTranslations();
  const { t: dateFormatTranslation } = useDateFormatTranslations();

  if (!showTime) return null;

  const dateTime = momentDateForShowTime(date, showTime!);
  const mappedDay = dateTime.day() || 7;
  const dayOfWeek = dateTranslation(`day${mappedDay}Text`);
  const month = dateTranslation(`month${dateTime.month() + 1}ShortText`);
  const dayOfMonth = dateTime.date();
  const time = dateTime.format(dateFormatTranslation('timeOfDayFormat', 'hh:mm A'));
  const params = {
    dayOfMonth,
    month,
    dayOfWeek,
    time,
  };
  const formattedDateTime = dateFormatTranslation(
    'showTimeFormat',
    '%(dayOfWeek)s, %(month)s %(dayOfMonth)s at %(time)s',
    params,
  );

  const onVendorClick = (url: string) => () => {
    analytics?.sendVendor(url, theater.name, dateTime.format('YYYY-MM-DD HH:mm'));
  };

  const vendorLinks = (vendors: ShowTimeVendor[]) => (
    <StyledTVPVendorLinkContainer>
      <StyledTVPVendorLinkInnerContainer>
        {vendors.map((vendor) => (
          <StyledVendorLink onClick={onVendorClick(vendor.url)} key={vendor.url} href={vendor.url} target='_blank'>
            <VendorImage title={vendor.title} image={vendor.image} />
          </StyledVendorLink>
        ))}
      </StyledTVPVendorLinkInnerContainer>
    </StyledTVPVendorLinkContainer>
  );

  const displayName = truncate(theater.name, 80);

  return (
    <PopupContainer onClose={onClose} show={true}>
      <StyledImageContainer>{image ? <Img src={image.src} alt={image.alt} /> : imageFallback()}</StyledImageContainer>
      <StyledContentContainer>
        <StyledTitleContainer>
          <StyledTitle>{title}</StyledTitle>
          <StyledTVPTheaterContainer>
            <StyledTVPImageContainer>
              <Img src={getTheaterImageSrc(theater.image)} />
            </StyledTVPImageContainer>
            <StyledTVPTheaterName>{displayName}</StyledTVPTheaterName>
          </StyledTVPTheaterContainer>
        </StyledTitleContainer>
        <StyledTVPVendorLinksContainer>
          <StyledTVPDate>{formattedDateTime}</StyledTVPDate>
          <StyledTVPFormat>{showTime.screenType}</StyledTVPFormat>
          <StyledTVPSelectText>{t('selectTicketsText', 'Select where you want to buy tickets')}</StyledTVPSelectText>
          {vendorLinks(showTime.vendors)}
        </StyledTVPVendorLinksContainer>
      </StyledContentContainer>
    </PopupContainer>
  );
};
