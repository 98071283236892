import { rem } from 'polished';
import * as qs from 'query-string';
import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { black, white } from '../../../common/colors';
import { IS_SERVER } from '../../../common/constants';
import { sidePaddingStyles, StyledPaddingContainer } from '../../../common/padding.styles';
import { RowHeader } from '../../../components/row-header/RowHeader';
import { ScrollAnchor } from '../../../components/scroll-anchor/ScrollAnchor';
import { TicketingWidget } from '../../../components/ticketing-widget/TicketingWidget';
import { useBaseTitleLayout } from '../hooks/useBaseTitleLayout';
import { TitleLayoutProps } from '../TitleLayout.props';

const StyledTicketingWidgetContainer = styled(StyledPaddingContainer)`
  background-color: ${black};
  padding-bottom: ${rem(20)};
  ${sidePaddingStyles}

  ${mediaQueryWidth({ min: 'tablet' })`
    padding-bottom: ${rem(44)};
  `}
`;

// noinspection JSUnusedGlobalSymbols
export default (props: TitleLayoutProps) => {
  const {
    baseSubnavDataMap,
    t,
    BaseTitleLayout,
    HomeEntertainmentSection,
    ImageGallerySection,
    TitleAboutSection,
    VideoCarouselSection,
  } = useBaseTitleLayout(props, 'app:layout:MovieTitleLayout');

  const {
    locale,
    titleAboutProps: { title, keyart: image, ticketingDetails },
  } = props;

  const hasTickets = Boolean(ticketingDetails?.showTicketing);
  const sycamoreId = ticketingDetails?.ticketingId;
  const ticketingTheme = ticketingDetails?.ticketingThemeDetails;

  const parsedQuery = !IS_SERVER ? qs.parse(window.location.search) : {};

  const anchorText = t('anchorGetTicketsText', 'get-tickets');

  const subnavData = [
    { title: t('subnavGetTicketsText', 'Get Tickets'), anchor: `#${anchorText}`, display: hasTickets },
    baseSubnavDataMap.HomeEntertainmentSection,
    baseSubnavDataMap.TitleAboutSection,
    baseSubnavDataMap.VideoCarouselSection,
    baseSubnavDataMap.ImageGallerySection,
  ];

  const TicketingSection = useCallback(() => {
    if (hasTickets && sycamoreId) {
      const useFixture = parsedQuery.useFixture === 'true' || undefined;
      const movie = {
        image,
        sycamoreId,
        title,
      };

      const ticketingProps = { movie, useFixture, theme: ticketingTheme };

      return (
        <section>
          <ScrollAnchor id={anchorText} />
          <RowHeader background={black} color={white} title={t('getTicketsTitle', 'Get Tickets')} />
          <StyledTicketingWidgetContainer>
            <TicketingWidget {...ticketingProps} />
          </StyledTicketingWidgetContainer>
        </section>
      );
    }

    return null;
  }, [hasTickets, locale, sycamoreId, ticketingTheme, title]);

  return (
    <BaseTitleLayout subnavData={subnavData}>
      <TicketingSection />
      <HomeEntertainmentSection />
      <TitleAboutSection />
      <VideoCarouselSection />
      <ImageGallerySection />
    </BaseTitleLayout>
  );
};
