import { useGeolocationState, useTimeout } from 'beautiful-react-hooks';
import { useEffect } from 'react';
import { useTicketingReducer } from '../contexts';

/*
 * We're effectively wrapping the `useGeolocationState` hook in a component, since we can't conditionally call hooks
 * (but can a component).
 *
 * Beware THE RULES OF HOOKS! Captain Hook will hunt you down!
 */
export const Geolocation = () => {
  const { position } = useGeolocationState();
  const { dispatch } = useTicketingReducer();

  // After 3 seconds, if we have no position, default back to the nearest city
  const [isCleared, clearTimeoutRef] = useTimeout(() => {
    if (position && !isCleared) {
      clearTimeoutRef();
    } else {
      dispatch({ type: 'selectNearestCity' });
    }
  }, 3000);

  useEffect(() => {
    if (position) {
      dispatch({ type: 'setGeoPosition', payload: position });
    }
  }, [position]);

  return null;
};
