import { rem } from 'polished';
import styled from 'styled-components';
import { white } from '../../common/colors';

export const maxIndexDistance = 3;

const getButtonSize = (indexDistance: number): string => {
  const distanceToWidth = {
    0: rem(12),
    1: rem(10),
    2: rem(8),
    3: rem(6),
  };

  return distanceToWidth[indexDistance] || '0';
};

// noinspection CssOverwrittenProperties
export const StyledSliderButton = styled.button<{ indexDistance: number }>`
  display: block;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  outline: none;
  transition: 0.5s width, 0.5s height;
  cursor: pointer;
  ${({ indexDistance }) => {
    const size = getButtonSize(indexDistance);
    return `
      width: ${size};
      height: ${size};
    `;
  }}

  &:focus {
    outline: 1px dotted ${white};
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const StyledSliderButtonContainer = styled.div<{ addPadding?: boolean }>`
  ${({ addPadding }) => addPadding && `padding: 0 ${rem(10)};`}
`;
