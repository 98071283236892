import { Checkbox } from '@material-ui/core';
import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { black, blue, lightBlueWithAlpha, white } from '../../../common/colors';

export const StyledCLDIContainer = styled.button<{ checked: boolean; italicize?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin: ${rem(2)} 0;
  padding: 0 ${rem(10)};
  border: none;
  font-size: ${rem(16)};
  font-style: ${({ italicize }) => (italicize ? 'italic' : 'normal')};
  background-color: ${({ checked }) => (checked ? lightBlueWithAlpha : white)};
  text-align: left;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${lightBlueWithAlpha};
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(16)}
  `}
`;

export const StyledMaterialCheckbox = styled(Checkbox)<{ highlight?: string }>`
  && {
    padding: 0 ${rem(6)} 0 0;
    color: ${({ highlight }) => highlight || blue};

    &:hover {
      background-color: transparent;
    }
  }
`;

export const StyledCLDILabel = styled.div<{ enabled: boolean }>`
  font-size: ${rem(16)};
  font-weight: 600;
  line-height: ${rem(36)};
  color: ${black};
`;
