import * as React from 'react';
import { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { buttonGuideValue } from '../../../common/helpers/carousel';
import { useGet } from '../../../common/hooks/useGet';
import { useTranslations } from '../../../common/translation';
import { youtubeEmbedStore } from '../../../stores/YoutubeEmbedStore';
import translations from '../translations';
import { VideoProps } from './Video.props';
import { StyledVideo, StyledVideoPlayer, StyledVideoTitle } from './Video.styles';

const reactPlayerConfig = {
  youtube: {
    embedOptions: {
      host: 'https://www.youtube-nocookie.com',
    },
    playerVars: {
      controls: 1,
      enablejsapi: 1,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  },
};

const getYoutubeUrl = (url: string) => (url.includes('//youtu') ? url : `https://youtu.be/${url}`);
const getNoEmbedUrl = (url: string) => `https://noembed.com/embed?url=${url}`;
const getThumbnailUrl = (youtubeUrl: string): string => {
  const matched = youtubeUrl.match(/(?:youtu\.be\/|youtube\.com\/watch\?v=)([0-9a-z_\-]+)/i);
  if (matched) {
    return `https://img.youtube.com/vi/${matched[1]}/0.jpg`;
  }

  return '';
};

const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter' && e.currentTarget) {
    (e.currentTarget as HTMLElement).click();
  }
};

export const Video = ({ headingTag = 'h3', url }: VideoProps) => {
  const youtubeUrl = getYoutubeUrl(url);
  const noEmbedUrl = getNoEmbedUrl(youtubeUrl);
  const {
    data: { title },
  } = useGet(noEmbedUrl, { title: '' }, false, false);
  const playerRef = useRef<ReactPlayer>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslations(translations);

  useEffect(() => {
    const containerEl = containerRef.current;
    const player = playerRef.current;
    if (containerEl && player) {
      const playIcon = containerEl.querySelector('.react-player__play-icon');
      if (playIcon) {
        playIcon.setAttribute('aria-label', `${t('ariaPlayButton', 'Play')} ${noEmbedUrl}`);
        playIcon.setAttribute('role', 'button');
        playIcon.setAttribute('tabindex', '0');

        playIcon.addEventListener('keydown', onKeyDown);

        return () => {
          playIcon.removeEventListener('keydown', onKeyDown);
        };
      }
    }
  }, []);

  const onPlay = () => {
    const internalPlayer = playerRef.current?.getInternalPlayer();
    if (internalPlayer) {
      youtubeEmbedStore.pause(internalPlayer);
      youtubeEmbedStore.setPlayer(internalPlayer);
    }
  };

  const thumbnailUrl = getThumbnailUrl(youtubeUrl);

  return (
    <StyledVideo tabIndex={-1}>
      <StyledVideoPlayer data-carousel={buttonGuideValue} ref={containerRef}>
        <ReactPlayer
          config={reactPlayerConfig}
          height='100%'
          light={thumbnailUrl}
          onPlay={onPlay}
          playing={true}
          ref={playerRef}
          url={`${youtubeUrl}&rel=0`}
          width='100%'
        />
      </StyledVideoPlayer>
      <StyledVideoTitle as={headingTag}>{title}</StyledVideoTitle>
    </StyledVideo>
  );
};
