import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useRoutePath } from 'react-static';
import { darkGrey1 } from '../../../common/colors';
import { pushToDataLayer } from '../../../common/helpers/dataLayer';
import { useDebug } from '../../../common/hooks/useDebug';
import {
  getTextByType,
  localeAndTranslationObject,
  Translation,
  TranslationFn,
  useTranslations,
} from '../../../common/translation';
import { BannerCarousel } from '../../../components/banner-carousel/BannerCarousel';
import { FeaturedBanner } from '../../../components/featured-banner/FeaturedBanner';
import { HeadTitle } from '../../../components/head-title/HeadTitle';
import { HomeEntertainment } from '../../../components/home-entertainment/HomeEntertainment';
import { ImageCarousel } from '../../../components/image-carousel/ImageCarousel';
import { NoticeMessage } from '../../../components/notice/Notice';
import { PurchaseLinkMessage } from '../../../components/purchase-link-message/PurchaseLinkMessage';
import { RowHeader } from '../../../components/row-header/RowHeader';
import { ScrollAnchor } from '../../../components/scroll-anchor/ScrollAnchor';
import { Subnav } from '../../../components/subnav/Subnav';
import { SubnavData } from '../../../components/subnav/Subnav.props';
import { TitleAbout } from '../../../components/title-about/TitleAbout';
import { VideoCarousel } from '../../../components/video-carousel/VideoCarousel';
import { DefaultLayout } from '../../default/DefaultLayout';
import { DefaultLayoutProps } from '../../default/DefaultLayout.props';
import { TitleLayoutProps } from '../TitleLayout.props';
import translations from '../translations';

const getTitleTypeLabel = (locale: string | undefined, type: ContentType, isMobileGameOrApp: boolean = false) => {
  const { localeTranslation } = localeAndTranslationObject(translations, locale);
  const t = Translation(localeTranslation);

  switch (type) {
    case 'Games & Apps':
      return isMobileGameOrApp ? t('gamesAndAppsLabel', 'Games and Apps') : t('videoGamesLabel', 'Video Games');
    case 'Movies':
      return t('moviesLabel', 'Movies');
    case 'TV Seasons':
    case 'TV Series':
      return t('tvLabel', 'TV');
    default:
      return '';
  }
};

interface BaseTitleLayoutProps extends DefaultLayoutProps {
  subnavData?: SubnavData[];
}

type BaseSectionCategories =
  | 'HomeEntertainmentSection'
  | 'ImageGallerySection'
  | 'TitleAboutSection'
  | 'VideoCarouselSection';

type SubnavDataMap = {
  [key in BaseSectionCategories]: SubnavData;
};

type BaseTitleLayoutResponse = {
  baseSubnavDataMap: SubnavDataMap;
  t: TranslationFn;
  BaseTitleLayout: React.FC<BaseTitleLayoutProps>;
} & { [key in BaseSectionCategories]: React.FC };

export const useBaseTitleLayout = (
  {
    topBannerProps,
    homeEntProps,
    images,
    isMobileGameOrApp,
    landingPath,
    layoutType,
    locale,
    notice,
    purchaseLinkMessage,
    siteUrl,
    titleAboutProps,
    type,
    translatedPaths,
    videosProps,
    bannerTitle,
    bottomBannerProps,
  }: TitleLayoutProps,
  debugName?: string,
): BaseTitleLayoutResponse => {
  useDebug(debugName || 'app:layout:BaseTitleLayout', {
    props: {
      topBannerProps,
      homeEntProps,
      images,
      isMobileGameOrApp,
      landingPath,
      layoutType,
      locale,
      purchaseLinkMessage,
      siteUrl,
      titleAboutProps,
      translatedPaths,
      type,
      videosProps,
      bottomBannerProps,
    },
  });

  useEffect(() => {
    pushToDataLayer({
      contentType: type,
      eidrAbstract: titleAboutProps.eidrAbstract,
      genres: titleAboutProps.genres,
      mpm: titleAboutProps.mpmNumber,
      title: titleAboutProps.title,
    });
  }, []);

  const path = useRoutePath('');
  const { t } = useTranslations(translations, locale);

  const titleTypeLabel = getTitleTypeLabel(locale, type, isMobileGameOrApp);
  const title = `${titleAboutProps.seo?.title || titleAboutProps.title} | ${titleTypeLabel}`;

  const commonOpts = {
    type,
    isMobileGameOrApp,
    t,
  };
  const subnavAboutText: string = getTextByType({
    ...commonOpts,
    prefix: 'subnavAbout',
    defaultValue: 'About',
  });
  const subnavBuyText: string = getTextByType({
    ...commonOpts,
    prefix: 'subnavBuy',
    defaultValue: 'Own It',
  });
  const subnavVideosText: string = getTextByType({
    ...commonOpts,
    prefix: 'subnavVideos',
    defaultValue: 'Videos',
  });
  const subnavGalleryText: string = getTextByType({
    ...commonOpts,
    prefix: 'subnavGallery',
    defaultValue: 'Gallery',
  });
  const homeEntTitle: string = getTextByType({
    ...commonOpts,
    prefix: 'homeEntTitle',
    defaultValue: 'Own It',
  });
  const videosTitle: string = getTextByType({
    ...commonOpts,
    prefix: 'videosTitle',
    defaultValue: 'Videos',
  });
  const galleryTitle: string = getTextByType({
    ...commonOpts,
    prefix: 'imageCarouselTitle',
    defaultValue: 'Gallery',
  });

  const breadcrumbs = [
    { text: titleTypeLabel, url: landingPath || '' },
    { text: titleAboutProps.title, url: path || '' },
  ];

  // Subnav items display conditionally based on movies props availability
  const hasHomeEntBuyLinks =
    homeEntProps &&
    homeEntProps.streamLinks.length + homeEntProps.digitalLinks.length + homeEntProps.physicalLinks.length > 0;
  const hasVideos = videosProps && videosProps.videos.length > 0;
  const hasImages = images && images.length > 0;
  const anchorBuyText = getTextByType({ isMobileGameOrApp, t, type, defaultValue: 'buy', prefix: 'anchorBuy' });
  const anchorAboutText = t('anchorAboutText', 'about');
  const anchorVideosText = t('anchorVideosText', 'videos');
  const anchorGalleryText = t('anchorGalleryText', 'gallery');
  const baseSubnavDataMap: SubnavDataMap = {
    HomeEntertainmentSection: { title: subnavBuyText, anchor: `#${anchorBuyText}`, display: hasHomeEntBuyLinks },
    ImageGallerySection: { title: subnavGalleryText, anchor: `#${anchorGalleryText}`, display: hasImages },
    TitleAboutSection: { title: subnavAboutText, anchor: `#${anchorAboutText}`, display: true },
    VideoCarouselSection: { title: subnavVideosText, anchor: `#${anchorVideosText}`, display: hasVideos },
  };
  const baseSubnavData: SubnavData[] = [
    baseSubnavDataMap.HomeEntertainmentSection,
    baseSubnavDataMap.TitleAboutSection,
    baseSubnavDataMap.VideoCarouselSection,
    baseSubnavDataMap.ImageGallerySection,
  ];

  // Add site link to banner props
  if (siteUrl) {
    topBannerProps.titleProps.addlTitleData = {
      ...topBannerProps.titleProps.addlTitleData,
      additionalLinks: [
        {
          displayText: t('externalSiteLink', 'Visit the Official Site'),
          url: siteUrl,
        },
      ],
    };
  }

  const commonDeps = [locale, titleAboutProps.title, type];
  /*
    Cache the result so that we're not constantly re-rendering our `children`,
    causing problems for our TicketingWidget's state.
  */
  const BaseTitleLayout = useCallback(
    ({ children, subnavData, ...props }: BaseTitleLayoutProps) => (
      <DefaultLayout
        bgColor={darkGrey1}
        locale={locale}
        seo={titleAboutProps.seo}
        translatedPaths={translatedPaths}
        {...props}
      >
        <HeadTitle title={title} />
        <NoticeMessage notice={notice} />
        <FeaturedBanner headingTag='h1' {...topBannerProps} />
        <Subnav
          breadcrumbs={breadcrumbs}
          data={subnavData || baseSubnavData}
          dropDown={false}
          hideOnSmallScreen={true}
        />
        {children}
        <section>
          {bannerTitle && <RowHeader headingTag='h2' title={bannerTitle} />}
          <BannerCarousel {...bottomBannerProps} addTopMargin={!bannerTitle} lazyLoad={true} />
        </section>
      </DefaultLayout>
    ),
    commonDeps,
  );

  const HomeEntertainmentSection = useCallback(
    () =>
      hasHomeEntBuyLinks ? (
        <section>
          <ScrollAnchor id={anchorBuyText} />
          <RowHeader title={homeEntTitle} />
          <PurchaseLinkMessage purchaseLinkMessage={purchaseLinkMessage} />
          <HomeEntertainment {...homeEntProps!} />
        </section>
      ) : null,
    commonDeps,
  );

  const ImageGallerySection = useCallback(
    () =>
      hasImages ? (
        <section>
          <ScrollAnchor id={anchorGalleryText} />
          <RowHeader title={galleryTitle} />
          <ImageCarousel
            fallbackAlt={`${titleAboutProps.title} - ${t('imageAltFallback', 'Image')}`}
            images={images!}
            lazyLoad={true}
          />
        </section>
      ) : null,
    commonDeps,
  );

  const TitleAboutSection = useCallback(
    () => (
      <section>
        <ScrollAnchor id={anchorAboutText} />
        <TitleAbout {...titleAboutProps} />
      </section>
    ),
    commonDeps,
  );

  const VideoCarouselSection = useCallback(
    () =>
      hasVideos ? (
        <section>
          <ScrollAnchor id={anchorVideosText} />
          <RowHeader title={videosTitle} />
          <VideoCarousel {...videosProps!} />
        </section>
      ) : null,
    commonDeps,
  );

  return {
    baseSubnavDataMap,
    t,
    BaseTitleLayout,
    HomeEntertainmentSection,
    ImageGallerySection,
    TitleAboutSection,
    VideoCarouselSection,
  };
};
