import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { black, darkGrey2, white } from '../../common/colors';
import { sidePaddingStyles } from '../../common/padding.styles';

export const StyledRowHeader = styled.header<{ background?: string; color?: string }>`
  ${sidePaddingStyles}
  padding-top: ${rem(32)};
  padding-bottom: ${rem(32)};
  background: ${({ background }) => background || white};
  color: ${({ color }) => color || black};
`;

export const StyledHeading = styled.div`
  margin: 0;
  padding-left: ${rem(22)};
  border-left: 0.1rem solid rgba(142, 142, 142, 0.8);
  font-size: ${rem(30)};
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  color: ${({ color }) => color || darkGrey2};

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(40)};
  `};

  & > span {
    font-weight: 300;
  }
`;
