import * as React from 'react';
import { useTranslations } from '../../../common/translation';
import { Img } from '../../img/Img';
import { Rating } from '../TitleAbout.props';
import translations from '../translations';
import { RatingsProps } from './Ratings.props';
import { StyledRating, StyledRatingsList } from './Ratings.styles';

const gameRating = ({ descriptions, image }: Rating, onImageLoad: any) => (
  <StyledRating>
    {image && image.src && <Img alt={image.alt} onLoad={onImageLoad} src={image.src} />}
    {(descriptions || []).length > 0 && <p>{descriptions.join(', ')}</p>}
  </StyledRating>
);

/*
 *  When rendering the list of ratings, if we have an image then we only show the image,
 *  otherwise we show the description.
 */
const ratingsList = (ratings: Rating[], onImageLoad: any) => {
  const ratingsWithImages = ratings.filter((r: Rating) => r.image && r.image.src);
  const ratingsWithoutImages = ratings.filter((r: Rating) => !(r.image && r.image.src) && r.descriptions.length > 0);
  const descriptions = ratingsWithoutImages.map((r: Rating) => r.descriptions.join(', ')).join(', ');

  return (
    <StyledRatingsList>
      {ratingsWithImages.map(({ image }: Rating) => {
        if (image && image.src) {
          return <Img alt={image.alt} onLoad={onImageLoad} key={image.src} src={image.src} />;
        }
      })}
      {descriptions ? <span>{descriptions}</span> : null}
    </StyledRatingsList>
  );
};

export const Ratings = ({ onImageLoad, ratings, type }: RatingsProps) => {
  const { t } = useTranslations(translations);

  return (
    <section>
      <h3>{t('ratingText', 'Rating')}</h3>
      {ratings.length === 1 && type === 'Games & Apps'
        ? gameRating(ratings[0], onImageLoad)
        : ratingsList(ratings, onImageLoad)}
    </section>
  );
};
