import * as React from 'react';
import { createContext, useContext } from 'react';
import { TicketingAnalyticsService } from '../../../services/TicketingAnalytics.service';

const TicketingAnalyticsContext = createContext<TicketingAnalyticsService | undefined>(undefined);
TicketingAnalyticsContext.displayName = 'TicketingAnalyticsContext';

export const TicketingAnalyticsProvider = ({
  service,
  children,
}: {
  service: TicketingAnalyticsService;
  children: React.ReactNode;
}) => {
  return <TicketingAnalyticsContext.Provider value={service}>{children}</TicketingAnalyticsContext.Provider>;
};

export const useAnalytics = (): TicketingAnalyticsService => {
  const context = useContext(TicketingAnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};
