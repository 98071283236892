import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { black, grey10, white } from '../../common/colors';

export const StyledCLDButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  padding: ${rem(15)} ${rem(10)};
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: ${white};
  cursor: pointer;

  ${mediaQueryWidth({ min: 'mobile' })`
    padding: ${rem(20)};
  `}
`;

export const StyledCLDInput = styled.input`
  width: 100%;
  padding: 0;
  border: 0;
  font-size: ${rem(16)};
  font-weight: 600;
  font-family: inherit;
  line-height: ${rem(20)};
  background-color: inherit;
  color: ${black};
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${black};
    text-overflow: ellipsis;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${black};
    text-overflow: ellipsis;
    opacity: 1;
  }
`;

export const StyledDivider = styled.hr`
  display: block;
  height: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 1px solid ${grey10};
`;

export const StyledCLDArrow = styled.div`
  width: ${rem(18)};
  margin-left: ${rem(20)};
  line-height: 0;
  color: ${black};
  cursor: pointer;

  & > svg {
    display: inline-block;
    width: ${rem(18)};
    height: ${rem(11)};
  }
`;
