import { rem } from 'polished';
import styled from 'styled-components';
import { buttonStateStyles } from '../../ticketing.styles';
import { TheaterButtonTheme } from '../../TicketingWidget.theme';

export const StyledMap = styled.div<{ maxHeight?: number }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  // Hack to display zoom instructions over map markers
  .gm-style-pbc {
    z-index: 4 !important;
    pointer-events: none;
  }
`;

export const StyledReloadButton = styled.button<{ theme: TheaterButtonTheme }>`
  position: absolute;
  z-index: 1;
  top: ${rem(10)};
  left: 50%;
  transform: translate(-50%, 0);
  padding: ${rem(10)};
  border: 2px solid ${({ theme }) => theme.borderColor || theme.color};
  font-size: ${rem(16)};
  font-family: inherit;
  line-height: ${rem(26)};
  font-weight: 700;
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.color};
  cursor: pointer;

  ${buttonStateStyles}
`;
