import * as React from 'react';
import { SliderButtonProps } from './SliderButton.props';
import { maxIndexDistance, StyledSliderButton, StyledSliderButtonContainer } from './SliderButton.styles';

export const SliderButton = ({ ariaLabel, activeIndex, buttonIndex, onClick }: SliderButtonProps) => {
  const indexDistance = Math.abs(buttonIndex - activeIndex);
  const isWithinBounds = indexDistance <= maxIndexDistance;

  return (
    <StyledSliderButtonContainer addPadding={isWithinBounds}>
      <StyledSliderButton
        aria-label={ariaLabel}
        indexDistance={indexDistance}
        onClick={onClick}
        role='button'
        tabIndex={isWithinBounds ? 0 : -1}
      />
    </StyledSliderButtonContainer>
  );
};
