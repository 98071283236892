import * as Debug from 'debug';

const debug = Debug('app:store:YoutubeEmbedStore');

interface YoutubeEmbedStoreProps {
  playVideo?: any;
  pauseVideo?: any;
  stopVideo?: any;
  a?: any;
}

class YoutubeEmbedStore {
  public player: YoutubeEmbedStoreProps | null;

  constructor() {
    debug('constructor()');
    this.player = null;
  }

  public setPlayer = (player: YoutubeEmbedStoreProps) => {
    if (player) {
      this.player = player;
    }
  };

  public stop = () => {
    if (this.player) {
      this.player.stopVideo();
    }
  };

  public pause = (player?: YoutubeEmbedStoreProps) => {
    try {
      if (this.player && player !== this.player) {
        this.player.pauseVideo();
      }
    } catch (e) {
      debug('error', e.message);
    }
  };

  public play = () => {
    if (this.player) {
      this.player.playVideo();
    }
  };
}

export const youtubeEmbedStore = new YoutubeEmbedStore();
