import * as React from 'react';
import LeftArrowBold from '../../assets/images/icons/svg/left-arrow-bold.svg';
import RightArrowBold from '../../assets/images/icons/svg/right-arrow-bold.svg';
import { CarouselArrowProps } from './CarouselArrow.props';
import { StyledCarouselArrow } from './CarouselArrow.styles';

export const CarouselArrow = ({
  ariaPrefix = '',
  buttonLabel,
  className,
  currentSlide = 0,
  direction,
  onClick,
  outside,
  showAtEdge,
  tabIndex,
}: CarouselArrowProps) => {
  const currentSlideNumber = currentSlide + 1;
  const destSlideNumber = direction === 'left' ? currentSlideNumber - 1 : currentSlideNumber + 1;
  const ariaLabel = buttonLabel || `${ariaPrefix} ${destSlideNumber}`.trim();
  const ArrowIcon = direction === 'left' ? LeftArrowBold : RightArrowBold;

  return (
    <StyledCarouselArrow
      aria-label={ariaLabel}
      className={className}
      direction={direction}
      onClick={onClick}
      outside={outside}
      showAtEdge={showAtEdge}
      tabIndex={tabIndex}
    >
      <ArrowIcon />
    </StyledCarouselArrow>
  );
};
