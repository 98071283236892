import { BrowserDetectInfo } from 'browser-detect/dist/types/browser-detect.interface';
import * as React from 'react';
import { Fragment, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';
import {
  buttonGuideValue,
  defaultSliderSettings,
  removeAriaHidden,
  removeEventListeners,
} from '../../common/helpers/carousel';
import { determinePreset } from '../../common/helpers/image';
import { useBrowserDetails } from '../../common/hooks/useBrowserDetails';
import { useDebug } from '../../common/hooks/useDebug';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { imageInViewOptions } from '../../common/loading';
import { useTranslations } from '../../common/translation';
import { CarouselArrow } from '../carousel-arrow/CarouselArrow';
import { ImageCarouselProps } from './ImageCarousel.props';
import {
  StyledBackgroundImage,
  StyledFullImage,
  StyledFullImageSliderContainer,
  StyledImageCarousel,
  StyledThumbnailImage,
  StyledThumbnailImageContainer,
  StyledThumbnailSliderContainer,
} from './ImageCarousel.styles';
import translations from './translations';

const getFullImageCarouselItems = (images: Image[], browserDetails: BrowserDetectInfo) => {
  const add3dTransform = browserDetails.name === 'safari';

  return images.map((image: Image, index: number) => (
    <Fragment key={`${image.src}-${index}`}>
      <StyledBackgroundImage add3dTransform={add3dTransform} data-carousel={buttonGuideValue} image={image} />
      <StyledFullImage alt={image.alt} src={image.src} />
    </Fragment>
  ));
};

const getThumbnailCarouselItems = (images: Image[]) =>
  images.map((image: Image, index: number) => (
    <StyledThumbnailImageContainer data-carousel={buttonGuideValue} key={`${image.src}-${index}`}>
      <StyledThumbnailImage alt={image.alt} src={image.src} tabIndex={0} />
    </StyledThumbnailImageContainer>
  ));

const addFocusListeners = (imageElements: HTMLElement[], fullImageSlider: Slider, thumbnailSlider: Slider) => {
  const onFocus = (index: number) => () => {
    fullImageSlider.slickGoTo(index, true);
    thumbnailSlider.slickGoTo(index, true);
  };

  const listeners: Array<() => void> = [];
  imageElements.forEach((imageEl, index) => {
    const listener = onFocus(index);
    imageEl.addEventListener('focus', listener);
    listeners.push(listener);
  });

  return () => {
    removeEventListeners('focus', imageElements, listeners);
  };
};

export const ImageCarousel = ({ fallbackAlt = '', images, lazyLoad }: ImageCarouselProps) => {
  const fullImageSliderRef = useRef<Slider>(null);
  const thumbnailSliderRef = useRef<Slider>(null);
  const fullImageCarouselRef = useRef<HTMLDivElement>(null);
  const thumbnailCarouselRef = useRef<HTMLDivElement>(null);
  const { uiScreenType } = useWindowResize();
  const { t } = useTranslations(translations);
  const { browserDetails } = useBrowserDetails();
  const [inViewRef, inView] = useInView(imageInViewOptions);

  useEffect(() => {
    const fullImageSlider = fullImageSliderRef.current;
    const thumbnailSlider = thumbnailSliderRef.current;
    const carouselEl = thumbnailCarouselRef.current;
    if (fullImageSlider && thumbnailSlider && carouselEl) {
      const imageElements: HTMLElement[] = Array.from(carouselEl.querySelectorAll('.slick-slide img'));
      const removeFocusListeners = addFocusListeners(imageElements, fullImageSlider, thumbnailSlider);

      return () => {
        removeFocusListeners();
      };
    }
  }, [inView]);

  useDebug('app:cmp:ImageCarousel', { props: { fallbackAlt, images } });

  if (!(images || []).length) {
    return null;
  }

  const fullFallbackAlt = [fallbackAlt, t('imageAltFallback', 'Image')].filter(Boolean).join(' - ');
  const fullImageSliderSettings = {
    ...defaultSliderSettings,
    arrows: false,
    cssEase: 'ease',
    dots: false,
    focusOnSelect: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    swipeToSlide: true,
    beforeChange: (_: number, newIndex: number) => {
      thumbnailSliderRef.current?.slickGoTo(newIndex);
    },
  };
  const thumbnailSliderSettings = {
    ...defaultSliderSettings,
    arrows: true,
    cssEase: 'ease',
    dots: false,
    focusOnSelect: true,
    nextArrow: <CarouselArrow ariaPrefix={t('imageAltFallback', 'Image')} direction='right' />,
    prevArrow: <CarouselArrow ariaPrefix={t('imageAltFallback', 'Image')} direction='left' />,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    swipeToSlide: true,
    variableWidth: true,
    beforeChange: (_: number, newIndex: number) => {
      fullImageSliderRef.current?.slickGoTo(newIndex);
    },
    onReInit: () => {
      if (fullImageCarouselRef.current) {
        removeAriaHidden(fullImageCarouselRef.current);
      }

      if (thumbnailCarouselRef.current) {
        removeAriaHidden(thumbnailCarouselRef.current);
      }
    },
  };

  const carouselImages: Image[] = images.map((imageWithPresets: ImageWithPresets, index: number) => {
    const image: Image = determinePreset(imageWithPresets, uiScreenType);
    if (!image.alt) {
      image.alt = `${fullFallbackAlt} ${index + 1}`;
    }

    return image;
  });

  const showGallery = inView || !lazyLoad;

  return (
    <StyledImageCarousel ref={inViewRef}>
      {showGallery && (
        <>
          <StyledFullImageSliderContainer ref={fullImageCarouselRef}>
            <Slider {...fullImageSliderSettings} ref={fullImageSliderRef}>
              {getFullImageCarouselItems(carouselImages, browserDetails)}
            </Slider>
          </StyledFullImageSliderContainer>
          <StyledThumbnailSliderContainer ref={thumbnailCarouselRef}>
            <Slider {...thumbnailSliderSettings} ref={thumbnailSliderRef}>
              {getThumbnailCarouselItems(carouselImages)}
            </Slider>
          </StyledThumbnailSliderContainer>
        </>
      )}
    </StyledImageCarousel>
  );
};
