interface DataLayerData {
  contentType?: string;
  eidrAbstract?: string;
  genres?: string[];
  mpm?: string;
  title?: string;
}

declare const window: any;

export const pushToDataLayer = (data: DataLayerData) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        title: data.title || '',
        contentType: data.contentType || '',
        genres: (data.genres || []).join(','),
        mpm: data.mpm || '',
        eidrAbstract: data.eidrAbstract || '',
      },
    });
  }
};
