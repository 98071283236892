import * as React from 'react';
import { useState } from 'react';
import mapPinSelectedSvg from '../../../../../assets/images/icons/svg/map-pin-selected.svg';
import mapPinSvg from '../../../../../assets/images/icons/svg/map-pin.svg';
import { ImgWithFallback } from '../../../../img-with-fallback/ImgWithFallback';
import { getTheaterImageSrc } from '../../../hooks/utils';
import { TheaterMapMarkerProps } from './TheaterMapMarker.props';
import { StyledImageInMarker, StyledMapMarkerFallback, StyledTheaterMapMarker } from './TheaterMapMarker.styles';

export const TheaterMapMarker = ({ hasFocus, onClick, theme, theater: { image, name } }: TheaterMapMarkerProps) => {
  const [showImage, setShowImage] = useState<boolean>(true);
  const theaterImageSrc = getTheaterImageSrc(image);
  const markerProps = { hasFocus, onClick, theme };
  const MapPin = hasFocus ? mapPinSelectedSvg : mapPinSvg;
  const theaterInitial = name?.charAt(0) || '?';
  const onImgError = () => {
    setShowImage(false);
  };

  return (
    <StyledTheaterMapMarker {...markerProps}>
      <MapPin />
      <StyledImageInMarker>
        {showImage ? (
          <ImgWithFallback alt={name} onError={onImgError} src={theaterImageSrc} />
        ) : (
          <StyledMapMarkerFallback>{theaterInitial}</StyledMapMarkerFallback>
        )}
      </StyledImageInMarker>
    </StyledTheaterMapMarker>
  );
};
