import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { formattedDate } from '../../common/helpers/date';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { getSocialLinks } from '../../common/social-icons';
import { localeAndTranslationObject, Translation, useTranslations } from '../../common/translation';
import { Card } from '../card/Card';
import { Img } from '../img/Img';
import { Ratings } from './ratings/Ratings';
import { TitleAboutProps } from './TitleAbout.props';
import { StyledKeyart, StyledMetadata, StyledSocialLinks, StyledSynopsis, StyledTitleAbout } from './TitleAbout.styles';
import translations from './translations';

export const getAboutText = (locale: string | undefined, type: ContentType, isMobileGameOrApp: boolean = false) => {
  const { localeTranslation } = localeAndTranslationObject(translations, locale);
  const t = Translation(localeTranslation);

  let key;
  switch (type) {
    case 'Games & Apps':
      key = isMobileGameOrApp ? 'aboutMobileText' : 'aboutVideoGamesText';
      break;
    case 'Movies':
      key = 'aboutMoviesText';
      break;
    case 'TV Seasons':
      key = 'aboutTVSeasonsText';
      break;
    case 'TV Series':
      key = 'aboutTVSeriesText';
      break;
    default:
      key = 'aboutDefaultText';
  }

  return t(key, 'About');
};

export const TitleAbout = ({
  about,
  genres,
  inTheaters,
  isMobileGameOrApp,
  keyart,
  platforms,
  ratings,
  releaseDate,
  socialIcons,
  title,
  type,
}: TitleAboutProps) => {
  const [cardHeight, setCardHeight] = useState(-1);
  const keyartRef = useRef(null);
  const metadataRef = useRef(null);
  const titleRef = useRef(null);
  const { isSmallScreen, windowWidth } = useWindowResize();
  const { locale, t } = useTranslations(translations);

  const getRefHeight = (ref: React.RefObject<HTMLElement>) => (ref.current && ref.current!.clientHeight) || 0;

  const checkHeight = () => {
    if (isSmallScreen) {
      setCardHeight(0);
      return;
    }

    const keyartHeight = getRefHeight(keyartRef);
    const metadataHeight = getRefHeight(metadataRef);
    const titleHeight = getRefHeight(titleRef);
    const desiredSynopsisHeight = Math.max(metadataHeight, keyartHeight);
    const desiredCardHeight = desiredSynopsisHeight - titleHeight;
    if (desiredCardHeight > 0 && desiredCardHeight !== cardHeight) {
      setCardHeight(desiredCardHeight);
    }
  };

  const getReleaseDateLabel = () => {
    switch (type) {
      case 'Games & Apps':
        return t('videoGamesReleaseDateLabel', 'Release Date');
      case 'Movies':
        return inTheaters
          ? t('inTheatersReleaseDateLabel', 'Theatrical Release')
          : t('homeEntReleaseDateLabel', 'Original Theatrical Release');
      case 'TV Seasons':
      case 'TV Series':
        return t('tvReleaseDateLabel', 'Original Broadcast Date');
      default:
        return '';
    }
  };

  const componentKey = title.toLowerCase().replace(/[^0-9a-z]+/g, '-');

  useEffect(() => {
    checkHeight();
  }, [windowWidth]);

  return (
    <StyledTitleAbout key={componentKey}>
      <div>
        <StyledKeyart id='title-about-keyart' ref={keyartRef}>
          <Img
            alt={keyart.alt || `${title} - ${t('keyartAltFallback', 'Key Art')}`}
            onLoad={checkHeight}
            src={keyart.src || '/assets/images/keyart/default-wb.jpg'}
          />
        </StyledKeyart>
      </div>
      <div>
        <StyledSynopsis id='title-about-synopsis'>
          <h2 ref={titleRef}>{getAboutText(locale, type, isMobileGameOrApp)}</h2>
          {cardHeight > -1 && (
            <Card details={about} fixedContainerHeight={cardHeight} useDarkMode={true} useContentPadding={false} />
          )}
        </StyledSynopsis>
      </div>
      <div>
        <StyledMetadata id='title-about-metadata' ref={metadataRef}>
          {releaseDate && releaseDate.show && releaseDate.date && (
            <section>
              <h3>{getReleaseDateLabel()}</h3>
              <p>{formattedDate(releaseDate.date, locale)}</p>
            </section>
          )}
          {(ratings || []).length > 0 && <Ratings onImageLoad={checkHeight} ratings={ratings!} type={type} />}
          {(platforms || []).length > 0 && (
            <section>
              <h3>{t('platformText', 'Platforms')}</h3>
              <p>{platforms!.join(', ')}</p>
            </section>
          )}
          {(genres || []).length > 0 && (
            <section>
              <h3>{t('genreText', 'Genres')}</h3>
              <p>{genres!.join(', ')}</p>
            </section>
          )}

          {(socialIcons || []).length > 0 && (
            <section>
              <h3>{t('followText', 'Follow')}</h3>
              <StyledSocialLinks className='social-links'>{getSocialLinks(socialIcons!)}</StyledSocialLinks>
            </section>
          )}
        </StyledMetadata>
      </div>
    </StyledTitleAbout>
  );
};
