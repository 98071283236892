import * as React from 'react';
import { useDebug } from '../../../common/hooks/useDebug';
import { useTheme } from '../../ticketing-widget/contexts';
import { CheckboxListDropdownItemProps } from './CheckboxListDropdownItem.props';
import { StyledCLDIContainer, StyledCLDILabel, StyledMaterialCheckbox } from './CheckboxListDropdownItem.styles';

export const CheckboxListDropdownItem = React.forwardRef((props: CheckboxListDropdownItemProps, ref: any) => {
  useDebug('app:cmp:CheckboxListDropdown', { props });
  const {
    theme: { filters: theme },
  } = useTheme();

  const { checked, enabled, italicize, label, onChange, onKeyDown } = props;

  return (
    <StyledCLDIContainer
      data-key={label}
      checked={checked || false}
      italicize={italicize}
      onClick={onChange}
      onKeyDown={onKeyDown}
      ref={ref}
      tabIndex={0}
    >
      <StyledMaterialCheckbox
        aria-checked={checked}
        highlight={theme?.highlightColor}
        checked={checked || false}
        color='default'
        data-key={label}
        disableRipple={true}
        readOnly={true}
        size='small'
        tabIndex={-1}
      />
      <StyledCLDILabel enabled={enabled}>{label}</StyledCLDILabel>
    </StyledCLDIContainer>
  );
});
