import { Link } from '@reach/router';
import * as React from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isEdge, isIE11 } from '../../common/helpers/userAgents';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { useWindowScroll } from '../../common/hooks/useWindowScroll';
import { useTranslations } from '../../common/translation';
import { navigationPxHeight } from '../navigation/Navigation.styles';
import { SubnavDropdown } from './subnav-dropdown/SubnavDropdown';
import { SubnavLinks } from './subnav-links/SubnavLinks';
import { Breadcrumb, SubnavProps } from './Subnav.props';
import { StyledBreadcrumbs, StyledSubnav, StyledSubnavContainer, StyledSubnavLeft } from './Subnav.styles';
import translations from './translations';

const mappedBreadcrumbs = (breadcrumbs: Breadcrumb[]) =>
  breadcrumbs.map(({ text, url }, index) => {
    const key = `${text}-${url}-${index}`;
    const breadcrumbArrow = (
      <StyledSubnavLeft count={breadcrumbs.length} hasLink={!!url} key={key} index={index}>
        <span>{text}</span>
      </StyledSubnavLeft>
    );

    return url ? (
      <Link key={key} to={url}>
        {breadcrumbArrow}
      </Link>
    ) : (
      breadcrumbArrow
    );
  });

export const Subnav = (props: SubnavProps) => {
  const subnavRef = useRef<HTMLDivElement>(null);
  const [fixed, setFixed] = useState<boolean>(false);
  const [stickyViaScrollEvent, setStickyViaScrollEvent] = useState<boolean>(false);
  const [minScrollY, setMinScrollY] = useState<number>(0);
  const { scrollY } = useWindowScroll();
  const { isSmallScreen } = useWindowResize();
  const { t } = useTranslations(translations);

  useLayoutEffect(() => {
    if (stickyViaScrollEvent) {
      if (!fixed && scrollY > minScrollY) {
        setFixed(true);
      } else if (fixed && scrollY <= minScrollY) {
        setFixed(false);
      }
    }
  }, [scrollY]);

  useEffect(() => {
    setStickyViaScrollEvent(isIE11() || isEdge());
  }, []);

  useEffect(() => {
    if (subnavRef.current) {
      setMinScrollY(subnavRef.current.offsetTop - navigationPxHeight);
    }
  }, [isSmallScreen]);

  const { breadcrumbs = [], data, dropDown, dropdownPlaceholder, hideOnSmallScreen } = props;

  if (hideOnSmallScreen && isSmallScreen) {
    return null;
  }

  return (
    <StyledSubnavContainer fixed={fixed} ref={subnavRef}>
      <StyledSubnav aria-label={t('ariaSubnav', 'Sub navigation')} className='subnavComp' role='navigation'>
        <StyledBreadcrumbs>{mappedBreadcrumbs(breadcrumbs)}</StyledBreadcrumbs>
        <div className='subnavRight'>
          {dropDown ? <SubnavDropdown {...props} placeHolder={dropdownPlaceholder} /> : <SubnavLinks data={data} />}
        </div>
      </StyledSubnav>
    </StyledSubnavContainer>
  );
};
