import * as React from 'react';
import { useEffect, useRef } from 'react';
import Slider, { ResponsiveObject } from 'react-slick';
import { widthBreakpoints } from '../../../common/breakpoints';
import { adjustSlideAttributes, defaultSliderSettings, getSlidesToShow } from '../../../common/helpers/carousel';
import { useTranslations } from '../../../common/translation';
import { CarouselArrow } from '../../carousel-arrow/CarouselArrow';
import { useTheme, useTicketingReducer } from '../contexts';
import translations from '../translations';
import { MovieDateTab, movieDateTabDataValue } from './movie-date-tab/MovieDateTab';
import { MovieDate } from './MovieDates.props';
import { StyledMDContainer } from './MovieDates.styles';

const maxSlidesToShow = 7;
const responsive: ResponsiveObject[] = [
  {
    breakpoint: widthBreakpoints.tablet,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
];

export const MovieDates = () => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const slickRef = useRef<Slider>(null);
  const { t } = useTranslations(translations);
  const {
    theme: { dates: theme },
  } = useTheme();
  const {
    state: { movieDates, filteredShows },
    dispatch,
  } = useTicketingReducer();

  const sliderSettings = {
    ...defaultSliderSettings,
    responsive,
    arrows: true,
    cssEase: 'ease',
    dots: false,
    draggable: false,
    focusOnSelect: false,
    nextArrow: (
      <CarouselArrow ariaPrefix={t('ariaDatesButton', 'Dates')} direction='right' outside={true} showAtEdge={true} />
    ),
    prevArrow: (
      <CarouselArrow ariaPrefix={t('ariaDatesButton', 'Dates')} direction='left' outside={true} showAtEdge={true} />
    ),
    slidesToShow: maxSlidesToShow,
    slidesToScroll: maxSlidesToShow,
    speed: 500,
    swipeToSlide: false,
    beforeChange: (_: number, updatedIndex: number) => {
      const updatedDates = [...movieDates];
      updatedDates.forEach((date, index) => {
        date.current = index === updatedIndex;
      });
      setMovieDates(updatedDates);
    },
    onReInit: () => {
      const carouselEl = carouselRef.current;
      const slider = slickRef.current;
      if (carouselEl && slider) {
        const slidesToShow = getSlidesToShow(slider, responsive, maxSlidesToShow);
        adjustSlideAttributes(carouselEl, slidesToShow, `[data-component="${movieDateTabDataValue}"]`);
      }
    },
  };

  const setMovieDates = (newValue: MovieDate[]) => {
    dispatch({ type: 'setMovieDates', payload: newValue });
  };

  useEffect(() => {
    (slickRef.current as Slider)?.slickGoTo(0);
  }, [filteredShows]);

  const handleDateClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const key = e.currentTarget.attributes['data-key']?.value;
    if (key) {
      const updatedDates = [...movieDates];
      let someDatesUpdated = false;
      updatedDates.forEach((date: MovieDate) => {
        const current = date.current;
        const updated = date.dateString === key;
        if (current !== updated) {
          date.current = updated;
          someDatesUpdated = true;
        }
      });
      if (someDatesUpdated) {
        setMovieDates(updatedDates);
      }
    }
  };

  // TODO: Handle keyPress for a11y purposes, and possible styling for focus / outline across browsers
  const dateTabs = movieDates.map((movieDate: MovieDate) => (
    <MovieDateTab key={movieDate.dateString} movieDate={movieDate} onClick={handleDateClick} theme={theme} />
  ));

  return (
    <StyledMDContainer ref={carouselRef} backgroundColor={theme.backgroundColor}>
      <Slider {...sliderSettings} ref={slickRef}>
        {dateTabs}
      </Slider>
    </StyledMDContainer>
  );
};
