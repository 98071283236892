import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { black, grey50, lightBlue20, white } from '../../../common/colors';

export const StyledLFLoading = styled.div`
  padding: ${rem(40)};
  text-align: center;
  font-weight: 700;
`;

export const StyledLFWrapper = styled.div`
  ${mediaQueryWidth({ min: 'tablet' })`
    flex: 1;
  `}

  ${mediaQueryWidth({ min: 'hd' })`
    flex: 2;
  `}
`;

export const StyledLFHeader = styled.div`
  margin-bottom: ${rem(10)};
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  color: ${white};
`;

export const StyledLFBox = styled.div`
  display: flex;
  align-items: center;
  padding: ${rem(14)} ${rem(10)};
  background-color: ${white};

  ${mediaQueryWidth({ min: 'mobile' })`
    padding: ${rem(18)} ${rem(20)};
  `}
`;

export const StyledLFSearch = styled.div`
  margin-right: ${rem(10)};
  line-height: 0;
  color: ${black};
  overflow: hidden;
  cursor: pointer;

  & > svg {
    display: inline-block;
    width: ${rem(20)};
    height: ${rem(20)};
  }

  ${mediaQueryWidth({ min: 'mobile' })`
    margin-right: ${rem(20)};
  `}
`;

export const StyledLFInput = styled.input`
  flex: 1;
  padding: 0;
  border: 0;
  font-size: ${rem(16)};
  font-family: inherit;
  font-weight: 600;
  line-height: ${rem(22)};
  color: ${black};
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: ${grey50};
  }

  &:-ms-input-placeholder {
    opacity: 1;
    color: ${grey50};
  }

  ${mediaQueryWidth({ min: 'mobile' })`
    font-size: ${rem(20)};
    line-height: ${rem(24)};
  `}
`;

export const StyledLFCurrentLocation = styled.div<{ usingGeoPosition: boolean }>`
  margin-left: ${rem(20)};
  line-height: 0;
  overflow: hidden;
  cursor: pointer;
  color: ${({ usingGeoPosition }) => (usingGeoPosition ? lightBlue20 : black)}};

  &:hover {
    color: ${lightBlue20};
  }

  & > svg {
    display: inline-block;
    width: ${rem(20)};
    height: ${rem(20)};
  }
`;
