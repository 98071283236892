import * as React from 'react';
import { useDebug } from '../../common/hooks/useDebug';
import { getTextByType, useTranslations } from '../../common/translation';
import { Img } from '../img/Img';
import { HomeEntertainmentProps, PurchaseLink, PurchaseLinkCategory } from './HomeEntertainment.props';
import { StyledHomeEntertainment } from './HomeEntertainment.styles';
import translations from './translations';

const PurchaseLinkCategoryComp = ({ purchaseLinks, title }: PurchaseLinkCategory) => {
  if (!purchaseLinks.length) {
    return null;
  }

  return (
    <div className='category'>
      {title && <h3 className='category-title'>{title}</h3>}
      <div className='category-content'>
        {purchaseLinks.map((purchaseLink: PurchaseLink) => (
          <div className='purchase-link' key={`${purchaseLink.url}-${purchaseLink.image.src}`}>
            {purchaseLink.url ? (
              // tslint:disable-next-line:react-a11y-anchors
              <a href={purchaseLink.url} target='_blank'>
                <Img alt={purchaseLink.image.alt} src={purchaseLink.image.src} />
              </a>
            ) : (
              [
                <div className='overlay' key={`overlay-${purchaseLink.image.src}-${purchaseLink.image.alt}`} />,
                <Img
                  alt={purchaseLink.image.alt}
                  key={`${purchaseLink.image.src}-${purchaseLink.image.alt}`}
                  src={purchaseLink.image.src}
                />,
              ]
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const HomeEntertainment = ({
  watchCategories,
  streamLinks,
  digitalLinks,
  isMobileGameOrApp,
  overrideStreamTitle,
  overrideDigitalTitle,
  overridePhysicalTitle,
  physicalLinks,
  type,
}: HomeEntertainmentProps) => {
  const { t } = useTranslations(translations);

  useDebug('app:cmp:HomeEntertainment', {
    props: {
      watchCategories,
      streamLinks,
      digitalLinks,
      isMobileGameOrApp,
      overrideStreamTitle,
      overrideDigitalTitle,
      overridePhysicalTitle,
      physicalLinks,
      type,
    },
  });

  if (![...(streamLinks || []), ...(digitalLinks || []), ...(physicalLinks || [])].length) {
    return null;
  }

  const commonOpts = {
    type,
    isMobileGameOrApp,
    t,
    defaultValue: '',
  };

  const getPurchaseLinkCategoryComp = () => {
    return watchCategories?.map((cat: string, index: number) =>
      cat === 'stream' ? (
        <PurchaseLinkCategoryComp
          key={`${cat}-${index}`}
          purchaseLinks={streamLinks}
          title={overrideStreamTitle || getTextByType({ ...commonOpts, prefix: 'stream' }).trim()}
        />
      ) : cat === 'digital' ? (
        <PurchaseLinkCategoryComp
          key={`${cat}-${index}`}
          purchaseLinks={digitalLinks}
          title={overrideDigitalTitle || getTextByType({ ...commonOpts, prefix: 'digital' }).trim()}
        />
      ) : cat === 'physical' ? (
        <PurchaseLinkCategoryComp
          key={`${cat}-${index}`}
          purchaseLinks={physicalLinks}
          title={overridePhysicalTitle || getTextByType({ ...commonOpts, prefix: 'physical' }).trim()}
        />
      ) : null,
    );
  };

  return <StyledHomeEntertainment as='section'>{getPurchaseLinkCategoryComp()}</StyledHomeEntertainment>;
};
