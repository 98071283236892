import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { black, white } from '../../common/colors';

export const StyledTicketingWidget = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${black};
  color: ${white};

  & > :nth-child(2) {
    margin-top: ${rem(32)};
  }
`;

export const StyledTicketingFormWrapper = styled.div`
  background-color: ${black};

  ${mediaQueryWidth({ min: 'tablet' })`
    display: flex;
  `}
`;

export const StyledLoadingImg = styled.img`
  width: ${rem(80)};
  height: ${rem(80)};
`;
