import { rem } from 'polished';
import styled from 'styled-components';
import { black, blue } from '../../common/colors';

export const StyledNotice = styled.section`
  background: #ffe56c;
  color: ${black};
  font-size: ${rem(16)};
  font-weight: ${rem(300)};
  letter-spacing: 0;
  line-height: ${rem(22)};
  padding: ${rem(10)} ${rem(20)};

  p {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  ul li {
    list-style: disc;
    margin-left: ${rem(32)};
  }
  ol {
    padding-inline-start: ${rem(30)};
  }
  ol li {
    padding-left: ${rem(4)};
  }
  a {
    color: ${blue} !important;
  }
  .rtecenter {
    text-align: center;
  }
`;
