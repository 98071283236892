import * as he from 'he';
import * as React from 'react';
import { RowHeaderProps } from './RowHeader.props';
import { StyledHeading, StyledRowHeader } from './RowHeader.styles';

export const RowHeader = ({
  background = '',
  className = '',
  color = '',
  headingTag = 'h2',
  title,
}: RowHeaderProps) => (
  <StyledRowHeader className={className} background={background} color={color}>
    <StyledHeading as={headingTag} color={color} className='displayTitle'>
      {typeof title === 'string' ? he.decode(title) : title}
    </StyledHeading>
  </StyledRowHeader>
);
