import * as React from 'react';
import { SubnavLinksProps } from './SubnavLinks.props';

export const SubnavLinks = ({ data }: SubnavLinksProps) => (
  <ul className='SubnavVertical'>
    {data &&
      data.map(
        (item) =>
          item.display && (
            <li key={item.anchor}>
              <a href={item.anchor}>{item.title}</a>
            </li>
          ),
      )}
  </ul>
);
