import axios from 'axios';
import * as qs from 'query-string';
import {
  browserName,
  browserVersion,
  fullBrowserVersion,
  getUA,
  isMobile,
  isMobileOnly,
  isTablet,
  osName,
  osVersion,
} from 'react-device-detect';
import { IS_SERVER } from '../common/constants';
import { getCountryOrMarketCode } from '../common/helpers/countrySelection';
import { hasSessionStorage } from '../common/helpers/window';
import { getPreviousPathname } from '../common/router';
import { City, cityName } from '../components/ticketing-widget/hooks/utils';
import { TicketingMovie } from '../components/ticketing-widget/TicketingWidget.props';
import { TICKETING_SERVICE_BASEURL } from '../config/browser';

interface TicketingAnalyticsEvent {
  analyticValue: string;
  browserInfo: string;
  campaignTag: string | undefined;
  countryCode: string | undefined;
  device: string;
  geoInfo: string;
  movieId: string;
  movieTitle: string;
  osInfo: string;
  referral: string | undefined;
  sessionId: string;
  type: string;

  theaterName?: string;
  viewingDate?: string;
}

type TicketingAnalyticsParams = Pick<TicketingAnalyticsEvent, 'analyticValue' | 'type'> &
  Partial<TicketingAnalyticsEvent>;

declare const window: any;

const sessionIdKey = 'sessionId';
const createSessionKey = 'createSession';

export class TicketingAnalyticsService {
  private static hasCreatedSession: boolean = false;
  private static sessionId: string = Math.random()
    .toString(10)
    .substring(2);

  private static getSessionId() {
    if (hasSessionStorage()) {
      const savedSessionId = sessionStorage.getItem(sessionIdKey);
      if (savedSessionId) {
        return savedSessionId;
      }

      sessionStorage.setItem(sessionIdKey, TicketingAnalyticsService.sessionId);
    }

    return TicketingAnalyticsService.sessionId;
  }

  // See: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
  private static getCookiesEnabled() {
    try {
      document.cookie = 'cookietest=1';
      const cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
      return cookiesEnabled;
    } catch (e) {
      return false;
    }
  }

  private static getBrowserInfo() {
    if (!IS_SERVER) {
      const fullOs = `${osName} ${osVersion}`;
      const fullBrowserName = `${browserName} ${browserVersion} ${fullBrowserVersion}`;
      const cookiesEnabled = TicketingAnalyticsService.getCookiesEnabled();
      const screenResolution = `${screen.width || ''} x ${screen.height || ''}`;
      const userAgent = getUA;

      return [browserName, fullOs, fullBrowserName, isMobile, cookiesEnabled, screenResolution, userAgent].join(' | ');
    }

    return '';
  }

  private static getCampaign() {
    if (!IS_SERVER) {
      return String(qs.parse(window.location.search).campaign || '');
    }
  }

  private static getDevice() {
    return isMobileOnly ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop';
  }

  private static getReferrer() {
    if (!IS_SERVER) {
      const prevPathname = getPreviousPathname();
      const pathnameReferrer = prevPathname && `${window.location.origin}${prevPathname}`;
      return pathnameReferrer || document.referrer;
    }
  }

  private static sendToDataLayer(params: TicketingAnalyticsEvent) {
    if (!IS_SERVER) {
      window.dataLayer = window.dataLayer || [];
      const event = params.type || 'UNTRACKED';
      window.dataLayer.push({ event: `WB-TICKETING-${event.toUpperCase()}`, attributes: params });
    }
  }

  constructor(private movie: TicketingMovie, private city?: City) {}

  public sendDirectionsButtonClick(theaterId: string) {
    this.sendEvent({ analyticValue: theaterId, type: 'go-to-map' });
  }

  public sendTimeChange(times: string) {
    this.sendEvent({ analyticValue: times, type: 'timing' });
  }

  public sendPageView() {
    this.sendEvent({ analyticValue: window.location.pathname, type: 'page-view' });
  }

  public sendSelectedDateChange(date: string) {
    this.sendEvent({ analyticValue: date, type: 'date-box' });
  }

  public sendFormatChange(formats: string) {
    this.sendEvent({ analyticValue: formats, type: 'formats' });
  }

  public sendLocationAutoComplete(city: City) {
    this.sendEvent({ analyticValue: cityName(city), type: 'autocomplete-text' });
  }

  public sendMapMarkerClick(theaterId: string) {
    this.sendEvent({ analyticValue: theaterId, type: 'google-marker-click' });
  }

  public sendTheaterSelection(theaterId: string) {
    this.sendEvent({ analyticValue: theaterId, type: 'theater-panel' });
  }

  public sendShowTime(id: string) {
    this.sendEvent({ analyticValue: id, type: 'show-time' });
  }

  public sendVendor(url: string, theaterName: string, viewingDate: string) {
    this.sendEvent({ theaterName, viewingDate, analyticValue: url, type: 'booking-url-vendor' });
  }

  private sendEvent(params: TicketingAnalyticsParams) {
    const data: TicketingAnalyticsEvent = this.buildEvent(params);
    TicketingAnalyticsService.sendToDataLayer(data);
    axios.post(`${TICKETING_SERVICE_BASEURL}/analytics`, { data });
  }

  private buildEvent(params: TicketingAnalyticsParams): TicketingAnalyticsEvent {
    const extraParams = {};
    const createSessionProperty = 'createSession';

    if (hasSessionStorage()) {
      if (!sessionStorage.getItem(createSessionKey)) {
        extraParams[createSessionProperty] = true;
        sessionStorage.setItem(createSessionKey, 'true');
      }
    } else if (!TicketingAnalyticsService.hasCreatedSession) {
      extraParams[createSessionProperty] = true;
      TicketingAnalyticsService.hasCreatedSession = true;
    }

    return { ...this.defaultParams, ...params, ...extraParams };
  }

  private get geoInfo(): string {
    return this.city ? cityName(this.city) : 'N/A';
  }

  private get movieId(): string {
    return this.movie?.sycamoreId || '';
  }

  private get movieTitle(): string {
    return this.movie?.title || '';
  }

  private get defaultParams() {
    return {
      browserInfo: TicketingAnalyticsService.getBrowserInfo(),
      campaignTag: TicketingAnalyticsService.getCampaign(),
      countryCode: getCountryOrMarketCode(),
      device: TicketingAnalyticsService.getDevice(),
      geoInfo: this.geoInfo,
      movieId: this.movieId,
      movieTitle: this.movieTitle,
      osInfo: osName,
      referral: TicketingAnalyticsService.getReferrer(),
      sessionId: TicketingAnalyticsService.getSessionId(),
    };
  }
}
