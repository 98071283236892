import { safeBGImageUrl } from './strings';
import { UiScreenType } from './window';

export const blurredBackgroundImageCss = (image: Image, add3dTransform: boolean = false) => {
  const safeImageSrc = safeBGImageUrl(image.src);
  const transformCss = add3dTransform ? 'transform: translate3d(0, 0, 0);' : '';

  return `
    background-image: ${safeImageSrc};
    filter: blur(60px);
    ${transformCss}

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background-image: none;
      filter: none;
    }

    @-moz-document url-prefix() {
      background-image: none;
      filter: none;
    }
  `;
};

export const determinePreset = (imageWithPresets: ImageWithPresets, uiScreenType: UiScreenType): Image => {
  switch (uiScreenType) {
    case UiScreenType.Mobile:
      return imageWithPresets.mobileImage;
    case UiScreenType.Tablet:
      return imageWithPresets.tabletImage;
    default:
      return imageWithPresets.image;
  }
};
