import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { lightGrey7, white } from '../../common/colors';
import { StyledPaddingContainer } from '../../common/padding.styles';

export const StyledHomeEntertainment = styled(StyledPaddingContainer)`
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding-bottom: 2.75rem;
  background-color: ${white};

  .category {
    padding: 1.5rem 0 0.5rem 0;

    .category-title {
      font-size: ${rem(20)};
      font-weight: 800;
      text-transform: capitalize;
      margin: 0;
      padding: 0.5rem 0 0.5rem 0;
    }

    .category-content {
      .purchase-link {
        position: relative;
        float: left;
        margin: 0.25rem 0.5rem 0.25rem 0;

        ${mediaQueryWidth({ min: 'tablet' })`
          margin: 0.5rem 1rem 0.5rem 0;
        `}

        a {
          display: block;
          line-height: 0;

          img:hover {
            border: 1px solid #919191;
            box-shadow: 0 6px 6px 0 rgba(216, 216, 216, 0.5);
          }
        }

        img {
          height: 40px;
          border: 1px solid ${lightGrey7};

          ${mediaQueryWidth({ min: 'tablet' })`
            height: 80px;
          `}
        }

        & > .overlay {
          background-color: #000;
          height: 100%;
          opacity: 0.05;
          left: 0;
          top: 0;
          position: absolute;
          width: 100%;
        }

        & > img {
          display: block;
          opacity: 0.5;
          filter: grayscale(100);
          border: 1px solid rgb(234, 234, 234);
        }
      }
    }
  }
`;
