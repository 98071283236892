import Slider, { ResponsiveObject, Settings } from 'react-slick';
import { isMultiCountry } from '../countries';
import { lazyLoadClassName } from '../loading';
import { DEFAULT_LANGUAGE, localeToLanguage } from '../translation';

export const buttonGuideValue = 'button-guide';

export const defaultSliderSettings: Settings = {
  accessibility: true,
  autoplay: false,
  centerMode: false,
  draggable: true,
  edgeFriction: 0.1,
  infinite: false,
  swipe: true,
  tapSwipe: true,
  touchMove: true,
  touchThreshold: 5,
};

export const adjustButtonPosition = (carouselEl: HTMLElement) => {
  const buttonContainer = carouselEl.querySelector(`[data-carousel="${buttonGuideValue}"]`);
  const guideHeight = buttonContainer?.clientHeight || 0;
  const buttons: HTMLElement[] = Array.from(carouselEl.querySelectorAll('.slick-slider > button'));
  buttons.forEach((button) => {
    button.style.top = `${guideHeight / 2}px`;
  });
};

/*
 * React Slick automatically sets both 'tabindex' and 'aria-hidden' attributes on all slide elements.
 * For user accessibility, we want to allow the user to tab through the slide items.
 * This conflicts with the accessibility requirements that aria-hidden elements not have any
 * focusable elements (See: https://act-rules.github.io/rules/6cfa84).
 * Thus, we need to manually remove the 'aria-hidden' attribute regardless of 'tabindex' value.
 */
export const removeAriaHidden = (carouselEl: HTMLElement) => {
  const slides: HTMLElement[] = Array.from(carouselEl.querySelectorAll('.slick-slide'));
  slides.forEach((slide) => {
    slide.removeAttribute('aria-hidden');
  });
};

export const removeEventListeners = (eventName: string, elements: HTMLElement[], listeners: any[]) => {
  elements.forEach((el, index) => {
    el.removeEventListener(eventName, listeners[index]);
  });
};

export const buildQueueName = (queueName: string, locale: string | undefined) => {
  let queueNameSuffix;
  if (isMultiCountry()) {
    queueNameSuffix = locale;
  } else {
    const language = localeToLanguage(locale);
    queueNameSuffix = language === DEFAULT_LANGUAGE ? '' : language;
  }

  return queueNameSuffix ? `${queueName}__${queueNameSuffix}` : queueName;
};

export const getSlidesToShow = (slider: Slider, responsive: ResponsiveObject[], maxSlidesToShow: number = 0) => {
  const matchingConfig = responsive.find((config) => config.breakpoint === slider.state.breakpoint);
  return (matchingConfig?.settings as Settings)?.slidesToShow || maxSlidesToShow;
};

export const adjustSlideAttributes = (
  carouselEl: HTMLElement,
  slidesToShow: number,
  selector: string,
  preloadNextSlides?: boolean,
) => {
  const slides: HTMLElement[] = Array.from(carouselEl.querySelectorAll('.slick-slide'));
  let visibleCount = 0;
  for (let i = slides.length - 1; i >= 0; i--) {
    const slide = slides[i];

    /*
     * Prevent hidden slides from containing focusable elements.
     * Since the number of visible slides can be greater than the number of active slides,
     * determine the number of visible slides by counting from the last active slide.
     */
    const isSlideActive = slide.classList.contains('slick-active');
    const isSlideVisible = isSlideActive || (visibleCount > 0 && visibleCount < slidesToShow);
    const tabIndexValue = isSlideVisible ? '0' : '-1';
    const tabbableElements: HTMLElement[] = Array.from(slide.querySelectorAll(selector));
    tabbableElements.forEach((el) => el.setAttribute('tabindex', tabIndexValue));

    const ariaHiddenValue = isSlideVisible ? 'false' : 'true';
    slide.setAttribute('aria-hidden', ariaHiddenValue);

    if (isSlideVisible) {
      visibleCount += 1;
    }
  }

  // Preload background images for all slides in the next scroll view
  if (preloadNextSlides) {
    let lastActiveIndex = -1;
    for (let j = slides.length - 1; j >= 0; j--) {
      if (slides[j].classList.contains('slick-active')) {
        lastActiveIndex = j;
        break;
      }
    }

    const startLoadIndex = lastActiveIndex + 1;
    for (let j = startLoadIndex; j < startLoadIndex + slidesToShow && j < slides.length; j++) {
      const lazyImages: HTMLElement[] = Array.from(slides[j].querySelectorAll(`.${lazyLoadClassName}`));
      lazyImages.forEach((img) => img.classList.remove(lazyLoadClassName));
    }
  }
};
