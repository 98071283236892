import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { black, blue, white } from '../../common/colors';

export const StyledPurchaseLinkMessage = styled.div`
  background: ${white};
  color: ${black};
  font-size: ${rem(16)};
  font-weight: 300;
  letter-spacing: 0;
  line-height: ${rem(22)};
  padding: ${rem(0)} ${rem(42)} ${rem(10)};

  ${mediaQueryWidth({ min: 'tablet' })`
    padding: ${rem(0)} ${rem(66)} ${rem(10)};
  `}

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul {
    li {
      list-style: disc;
      margin-left: ${rem(32)};
    }
  }

  ol {
    padding-inline-start: ${rem(30)};

    li {
      padding-left: ${rem(2)};
    }
  }

  a {
    color: ${blue} !important;
  }

  .rtecenter {
    text-align: center;
  }
`;
