import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { mediaQueryWidth } from './breakpoints';

export const sidePaddingStyles = css`
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};

  ${mediaQueryWidth({ min: 'tablet' })`
   padding-left: ${rem(44)};
   padding-right: ${rem(44)};
 `}
`;

export const StyledPaddingContainer = styled.div`
  ${sidePaddingStyles}
`;
