import * as React from 'react';
import styled from 'styled-components';
import { Img } from '../img/Img';

export const StyledImg = styled(
  React.forwardRef(({ loaded, ...rest }: any, ref: any) => {
    return <Img ref={ref} {...rest} />;
  }),
)<{ loaded: boolean }>`
  opacity: ${({ loaded }) => (loaded ? '1' : '0')};
`;
