import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';
import { adjustButtonPosition, adjustSlideAttributes, defaultSliderSettings } from '../../common/helpers/carousel';
import { useDebug } from '../../common/hooks/useDebug';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { imageInViewOptions } from '../../common/loading';
import { useTranslations } from '../../common/translation';
import { youtubeEmbedStore } from '../../stores/YoutubeEmbedStore';
import { routerButtonValue } from '../button/Button';
import { CarouselArrow } from '../carousel-arrow/CarouselArrow';
import { FeaturedBanner } from '../featured-banner/FeaturedBanner';
import { SliderButton } from '../slider-button/SliderButton';
import { BannerCarouselItem, BannerCarouselProps } from './BannerCarousel.props';
import { StyledBannerCarousel } from './BannerCarousel.styles';
import translations from './translations';

export const BannerCarousel = ({ addTopMargin, headingTag, items, lazyLoad }: BannerCarouselProps) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const indexRef = useRef<number>(0);
  const { t } = useTranslations(translations);
  const { windowWidth } = useWindowResize();
  const [inViewRef, inView] = useInView(imageInViewOptions);

  const setRefs = useCallback(
    (node) => {
      carouselRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  useEffect(() => {
    if (carouselRef.current) {
      adjustButtonPosition(carouselRef.current);
    }
  }, [windowWidth]);

  useDebug('app:cmp:BannerCarousel', { props: { addTopMargin, items } });

  if (!(items || []).length) {
    return null;
  }

  const sliderSettings = {
    ...defaultSliderSettings,
    arrows: true,
    cssEase: 'ease',
    dots: true,
    focusOnSelect: true,
    nextArrow: <CarouselArrow ariaPrefix={t('ariaBannerButton', 'Banner')} direction='right' />,
    prevArrow: <CarouselArrow ariaPrefix={t('ariaBannerButton', 'Banner')} direction='left' />,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    swipeToSlide: true,
    beforeChange: (_: number, newIndex: number) => {
      indexRef.current = newIndex;
      youtubeEmbedStore.pause();
    },
    customPaging: (buttonIndex: number) => {
      const ariaLabel = `${t('ariaBannerButton', 'Banner')} ${buttonIndex + 1}`;
      return <SliderButton activeIndex={indexRef.current} ariaLabel={ariaLabel} buttonIndex={buttonIndex} />;
    },
    onReInit: () => {
      if (carouselRef.current) {
        adjustSlideAttributes(
          carouselRef.current,
          1,
          `a, button:not([data-type="${routerButtonValue}"]), [role="button"]`,
          !lazyLoad || inView,
        );
      }
    },
  };

  return (
    <StyledBannerCarousel addTopMargin={addTopMargin} ref={setRefs}>
      <Slider {...sliderSettings}>
        {items.map((item: BannerCarouselItem, index: number) => (
          <FeaturedBanner
            headingTag={headingTag}
            imageWithPresets={item.imageWithPresets}
            key={`${item.titleProps.title}-${index}`}
            lazyLoad={lazyLoad || index > 0}
            titleProps={item.titleProps}
            video={item.video}
          />
        ))}
      </Slider>
    </StyledBannerCarousel>
  );
};
