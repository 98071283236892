import * as React from 'react';

export const encodeURIWithHash = (src: string | undefined): string => {
  const encoded = encodeURI(src || '');
  const hashesBeforeExtRegex = /#(?=.*\.\w+)/g;
  return encoded.replace(hashesBeforeExtRegex, '%25');
};

export const Img = React.forwardRef(
  ({ alt, src, ...rest }: React.ImgHTMLAttributes<HTMLImageElement>, ref: React.RefObject<HTMLImageElement>) => (
    <img alt={alt || ''} ref={ref} src={encodeURIWithHash(src)} {...rest} />
  ),
);
