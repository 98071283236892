import * as React from 'react';
import { useDateFormatTranslations, useDateTranslations } from '../../../../common/helpers/date';
import { MovieDateTabProps } from './MovieDateTab.props';
import { StyledMDDate, StyledMDDayName, StyledMDDayOfMonth } from './MovieDateTab.styles';

export const movieDateTabDataValue = 'movie-date-tab';

export const MovieDateTab = ({
  movieDate: { current, date, dateString, enabled },
  onClick,
  theme,
}: MovieDateTabProps) => {
  const { t: dateTranslation } = useDateTranslations();
  const { t: dateFormatTranslation } = useDateFormatTranslations();
  const mappedDay = date.day() || 7;
  const dayOfWeekShort = dateTranslation(`day${mappedDay}ShortText`).toUpperCase();
  const monthShort = dateTranslation(`month${date.month() + 1}ShortText`).toUpperCase();
  const monthAndDay = dateFormatTranslation('movieDateFormat', '%(month)s %(day)s', {
    day: date.date(),
    month: monthShort,
  });

  return (
    <StyledMDDate
      data-component={movieDateTabDataValue}
      backgroundColor={theme.backgroundColor}
      current={current}
      data-key={dateString}
      enabled={enabled}
      highlightColor={theme.highlightColor}
      onClick={onClick}
    >
      <StyledMDDayName>{dayOfWeekShort}</StyledMDDayName>
      <StyledMDDayOfMonth>{monthAndDay}</StyledMDDayOfMonth>
    </StyledMDDate>
  );
};
