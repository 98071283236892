import * as React from 'react';
import { ScrollAnchorProps } from './ScrollAnchor.props';
import { StyledRelativeContainer, StyledScrollAnchor } from './ScrollAnchor.styles';

export const ScrollAnchor = ({ relativeToAncestor, ...props }: ScrollAnchorProps) =>
  relativeToAncestor ? (
    <StyledScrollAnchor {...props} />
  ) : (
    <StyledRelativeContainer>
      <StyledScrollAnchor {...props} />
    </StyledRelativeContainer>
  );
