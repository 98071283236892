import { useEffect, useMemo, useState } from 'react';
import { TicketingAnalyticsService } from '../../../services/TicketingAnalytics.service';
import { CheckboxListDropdownItemInterface } from '../../checkbox-list-dropdown/CheckboxListDropdown.props';
import { getCurrentPositionCoords, useTicketingReducer } from '../contexts';
import { TicketingMovie } from '../TicketingWidget.props';
import { useNearestCity } from './useNearestCity';
import { useShowTimes } from './useShowTimes';
import { City } from './utils';

interface UseTicketingProps {
  movie: TicketingMovie;
  useFixture?: boolean;
}

const selectedFilterLabels = (items: CheckboxListDropdownItemInterface[]): string[] =>
  items
    .filter((item: CheckboxListDropdownItemInterface) => item.checked)
    .map((item: CheckboxListDropdownItemInterface) => item.label);

export const useTicketing = ({ movie, useFixture }: UseTicketingProps) => {
  const { state, dispatch } = useTicketingReducer();
  const { formatItems, selectedDate, timeItems } = state;
  const [initialLoading, setInitialLoading] = useState<boolean>(!useFixture);

  const { data: nearestCity, error: nearestCityError } = useNearestCity(useFixture);
  const { data, error, loading: showTimesLoading } = useShowTimes(movie, getCurrentPositionCoords(state), useFixture);

  const analyticsDeps: [TicketingMovie, City] = [movie, nearestCity];
  const analytics: TicketingAnalyticsService = useMemo(
    () => new TicketingAnalyticsService(...analyticsDeps),
    analyticsDeps,
  );

  const loading = initialLoading || showTimesLoading;

  useEffect(() => {
    if (initialLoading && (nearestCityError || showTimesLoading)) {
      setInitialLoading(false);
    }
  }, [showTimesLoading, nearestCityError]);

  useEffect(() => {
    if (nearestCity) {
      dispatch({ type: 'setNearestCity', payload: nearestCity });
      analytics.sendPageView();
    }
  }, [nearestCity]);

  useEffect(() => {
    if (data) {
      dispatch({ type: 'populate', payload: data });
    }
  }, [data]);

  useEffect(() => {
    const selectedFormatLabels = selectedFilterLabels(formatItems);

    if (selectedFormatLabels.length > 0) {
      analytics.sendFormatChange(selectedFormatLabels.join(', '));
    }
  }, [formatItems]);

  useEffect(() => {
    const selectedTimeLabels = selectedFilterLabels(timeItems);

    if (selectedTimeLabels.length > 0) {
      analytics.sendTimeChange(selectedTimeLabels.join(', '));
    }
  }, [timeItems]);

  useEffect(() => {
    if (selectedDate) {
      analytics.sendSelectedDateChange(selectedDate);
    }
  }, [selectedDate]);

  return {
    analytics,
    error,
    loading,
    state,
  };
};
