import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Link } from '@reach/router';
import classnames from 'classnames';
import * as Debug from 'debug';
import * as _ from 'lodash';
import * as React from 'react';
import DownArrowIcon from '../../../assets/images/icons/svg/down-arrow.svg';
import RightArrowIcon from '../../../assets/images/icons/svg/right-arrow.svg';
import { SubnavOptionsProps } from './SubnavOptions.props';

const debug = Debug('app:cmp:SubnavOptions');

export class SubnavOptions extends React.Component<SubnavOptionsProps, any> {
  public childLength: number;

  constructor(props: SubnavOptionsProps) {
    super(props);
    this.state = {
      open: false,
    };
    this.childLength = this.props.data ? this.props.data.length : 0;
  }

  public handleClick = (e: any) => {
    e.preventDefault();
    this.setState((state: any) => ({ open: !state.open }));
  };

  public handleClickAway = (e: any) => {
    const { open } = this.state;
    if (
      open &&
      e.target &&
      e.target.parentNode &&
      e.target.parentNode.className.match(/(\bsubnavText|\bsubnavOptionDiv|\bsubnavOptionLi)/g)
    ) {
      this.setState({ open: false });
    }
  };

  public handleLink = (hash: string) => {
    if (hash) {
      const elem = document.getElementById(hash);
      if (elem) {
        setTimeout(() => {
          elem.scrollIntoView();
        }, 50);
      }
    }
    if (this.props.handlePopper) {
      this.props.handlePopper(this);
    }
  };

  public renderChildren = (children: object[]) => {
    const { anchor, param, currentPage } = this.props;
    const { open } = this.state;
    const newPage = anchor ? `/${anchor}` : '';
    const childs =
      children &&
      children.map((child: any) => {
        const nestedOptionClass = classnames({
          subnavNestedText: true,
          selected: open && param === child.anchor,
        });
        return (
          <ListItem className='subnavNestedOption' key={child.anchor}>
            <Link
              className='subnavOptionLink'
              onClick={this.handleLink.bind(this, child.anchor)}
              to={`${currentPage}${newPage}#${child.anchor}`}
              state={{ fromSubnav: true }}
            >
              <ListItemText inset={true} className={nestedOptionClass} primary={child.title} />
            </Link>
          </ListItem>
        );
      });

    return (
      <Collapse in={open}>
        <List>{childs}</List>
      </Collapse>
    );
  };

  public renderOption = () => {
    const { currentTitle, title, currentPage, anchor, data, mountedDropdown } = this.props;
    const { open } = this.state;
    const optionClass = classnames({ subnavText: true, selected: currentTitle === title });
    const svgClass = classnames({ selected: currentTitle === title });

    if (this.childLength <= 1 && !mountedDropdown) {
      const childAnchor = _.get(data, '[0].anchor', anchor);
      const pathName = this.childLength === 0 ? currentPage : `${currentPage}/${anchor}`;
      debug(pathName);
      const childTitle = data?.[0]?.title || title;

      return (
        <ListItem button={true} className='subnavOptionLi'>
          <Link
            className='subnavOptionLink'
            onClick={this.handleLink.bind(this, childAnchor)}
            to={`${pathName}#${childAnchor}`}
            state={{ fromSubnav: true }}
          >
            <ListItemText className={optionClass} inset={true} primary={childTitle} />
          </Link>
        </ListItem>
      );
    }

    return (
      <ListItem button={true} onClick={this.handleClick} className='subnavOptionLi'>
        <ListItemText className={optionClass} inset={true} primary={title} />
        {this.childLength > 1 &&
          (open ? <DownArrowIcon className={svgClass} /> : <RightArrowIcon className={svgClass} />)}
      </ListItem>
    );
  };

  public render() {
    const { data } = this.props;
    const { open } = this.state;

    return (
      <div className='subnavOption'>
        <ClickAwayListener onClickAway={this.handleClickAway} touchEvent={false}>
          <div className='subnavOptionDiv'>
            {this.renderOption()}
            {data && open && this.renderChildren(data)}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}
