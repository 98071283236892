import * as React from 'react';
import { useState } from 'react';
import { useDebug } from '../../common/hooks/useDebug';
import { useTranslations } from '../../common/translation';
import {
  getCurrentPositionCoords,
  TicketingAnalyticsProvider,
  TicketingStateProvider,
  TicketingThemeProvider,
} from './contexts';
import { Geolocation } from './geolocation/Geolocation';
import { useTicketing } from './hooks/useTicketing';
import { LocationForm } from './location-form/LocationForm';
import { MovieDates } from './movie-dates/MovieDates';
import { TheaterResults } from './theater-results/TheaterResults';
import { StyledTRMessage } from './theater-results/TheaterResults.styles';
import { TicketingFilters } from './ticketing-filters/TicketingFilters';
import { TicketingWidgetProps } from './TicketingWidget.props';
import { StyledLoadingImg, StyledTicketingFormWrapper, StyledTicketingWidget } from './TicketingWidget.styles';
import { defaultTicketingTheme } from './TicketingWidget.theme';
import translations from './translations';

const errorMessageBoxJSX = (nodes: React.ReactNode[]) => (
  <StyledTRMessage>
    <p>
      {nodes.map((node, index) => (
        <React.Fragment key={index}>
          {node}
          {index < nodes.length - 1 && <br />}
        </React.Fragment>
      ))}
    </p>
  </StyledTRMessage>
);

const TicketingWidgetInner = ({ movie, useFixture }: TicketingWidgetProps) => {
  useDebug('app:cmp:TicketingWidget', { props: { movie, useFixture } });

  const { analytics, error, loading, state } = useTicketing({
    movie,
    useFixture,
  });
  const { allowGeolocation, allShows, filteredShows } = state;
  const { t } = useTranslations(translations);

  if (error) {
    return errorMessageBoxJSX([
      t('unableToConnectText', 'Unable to connect to the theatrical ticketing service.'),
      t('errorTryAgainText', 'Please try again later.'),
    ]);
  }

  const renderLoading = () => {
    const loadingText = t('loadingText', 'Loading...');
    return errorMessageBoxJSX([
      <StyledLoadingImg alt={loadingText} key='gif' src='/assets/images/ticketing/animated-ticket-rotating.gif' />,
      loadingText,
    ]);
  };

  const renderResults = () => {
    if (filteredShows.length > 0) {
      return (
        <>
          <MovieDates />
          <TheaterResults movie={movie} />
        </>
      );
    }

    if (allShows.length > 0) {
      return errorMessageBoxJSX([
        t('noShowTimesAvailableText', 'No showtimes available.'),
        t('errorDifferentSearchText', 'Please try another search.'),
      ]);
    }

    if (!getCurrentPositionCoords(state)) {
      return errorMessageBoxJSX([
        t('noShowTimesFoundText', 'No showtimes found.'),
        t('errorEnterLocationText', 'Please enter a location.'),
      ]);
    }

    return errorMessageBoxJSX([
      t('noShowTimesFoundText', 'No showtimes found.'),
      t('errorDifferentLocationText', 'Please try a different location.'),
    ]);
  };

  return (
    <TicketingAnalyticsProvider service={analytics}>
      {allowGeolocation && <Geolocation />}
      <StyledTicketingWidget>
        <StyledTicketingFormWrapper>
          <LocationForm />
          <TicketingFilters />
        </StyledTicketingFormWrapper>
        {loading ? renderLoading() : renderResults()}
      </StyledTicketingWidget>
    </TicketingAnalyticsProvider>
  );
};

export const TicketingWidget = ({ theme, ...props }: TicketingWidgetProps) => {
  /*
   * Store in state to prevent re-render when assigning as value to context provider, e.g.
   * https://reactjs.org/docs/context.html#caveats
   */
  const [activeTheme] = useState({ ...defaultTicketingTheme, ...theme });

  return (
    <TicketingThemeProvider theme={activeTheme}>
      <TicketingStateProvider>
        <TicketingWidgetInner {...props} />
      </TicketingStateProvider>
    </TicketingThemeProvider>
  );
};
